import React, { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon } from 'storybook-directual';
import { useRouteMatch } from 'react-router-dom';

import Button from 'components/Button';
import SendForApproval from 'components/SendForApproval';
import FeedbackListDevPlan from 'components/FeedbackListDevPlan';
import ManagerAssessment from 'components/ManagerAssessment';

import { sidebarStore, modalStore, userStore } from 'mobx/stores';
import { IProfileData } from 'mobx/stores/user/types';

import { IPropsModal as IBusinessProps } from 'components/ModalRoot/Modals/AddBusinessObjective/types';
import { IPropsModal as IDevelopmentProps } from 'components/ModalRoot/Modals/AddDevelopmentsAreas/types';

import { useEmpId, useDirectualApi } from 'shared/hooks';
import { useEmployee, useBusinessObjective } from 'shared/hooks/directual';
import { IResponseGet } from 'shared/types/directual';

import SelfAssesment from 'components/SelfAssesment';
import SendForReview from 'components/SendForReview';

import { IPropsModal as IFeedbackProps } from '../../components/ModalRoot/Modals/FeedbackRequest/types';

import GoalList from './GoalList';
import CompetencieList from './CompetencieList';
import GuidebookLink from './GuidebookLink';
import ApproveOrReturn from './ApproveOrReturn';

import { IRespComp } from './CompetencieList/types';

import styles from './DevelopmentPlanPage.module.scss';

const DevelopmentPlanPage: React.FC = observer(() => {
  const [wasSuccessAction, setWasSuccessAction] = useState(false);
  const [typePage, setTypePage] = useState<'business' | 'development'>('business');
  const match = useRouteMatch();

  const {
    user,
    periods,
    setProfileData,
  } = userStore;

  const empId = useEmpId();

  const {
    employeeData,
    getEmployeeData,
    detailsEmpData,
    getDetailsInPeriod,
  } = useEmployee();
  const { businessObjectives, getBusinessObjective } = useBusinessObjective();

  useEffect(() => {
    if (match.path.includes('businessObjectives')) {
      setTypePage('business');
    } else {
      setTypePage('development');
    }
  }, [match]);

  // useEffect(() => {
  //   const { response } = employeeData;
  //   if (empId && response && !(user.isHr || response.supervisor_id.id === user.profileData.id)) {
  //     history.push('/');
  //   }
  // }, [empId, employeeData]);

  const { setData, setSidebarMenu } = sidebarStore;
  const { showModal, modal } = modalStore;

  // TODO: пока пришлось вынести эти хуки наверх
  const [devAreaByEmployee, getDevAreaByEmployee] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespComp>) => res.payload, []),
    configurator: { structure: 'developmentArea', endpoint: 'getDAByEmployee' },
  });

  useEffect(() => {
    if (detailsEmpData.response) {
      const data: IProfileData = detailsEmpData.response;
      if (!empId) {
        setProfileData(data);
      }
    }
  }, [detailsEmpData.isLoading]);

  useEffect(() => {
    if (empId) {
      getEmployeeData({ id: empId });
    }

    getDetailsInPeriod({ id: empId || user.profileData.id });
  }, [empId]);

  useEffect(() => {
    setSidebarMenu(false, empId);
  }, []);

  useEffect(() => {
    if (employeeData.response) {
      setData({
        title: employeeData.response.fullName,
        info: `Start date ${employeeData.response.startWorkDateText}`,
        backUrl: '/employees',
      });
    } else {
      setData({ title: user.profileData.fullName, info: `Start date ${user.profileData.startWorkDateText}`, backUrl: '/' });
    }
  }, [employeeData.response]);

  useEffect(() => {
    if (wasSuccessAction) {
      setWasSuccessAction(false);
      getDetailsInPeriod({ id: empId || user.profileData.id });
    }
  }, [wasSuccessAction, getDetailsInPeriod, empId, user.profileData]);

  useEffect(() => {
    if (modal.type === 'noModal') {
      getBusinessObjective({ employee_id: empId || user.profileData.id });
      getDevAreaByEmployee({ employee_id: empId || user.profileData.id });
    }
  }, [modal.type, empId]);

  const noEditMode = periods.isNoEdit || user.profileData.profileStatus === 'approved' || user.profileData.profileStatus === 'inProcess';

  const isNotRegularEmployee = (!empId || (empId && employeeData.response
    && (user.isHr || employeeData.response.supervisor_id.id === user.profileData.id)));

  return (
    <div className={styles.wrapper}>
      {(!empId && !periods.isEndYearActive && !periods.isNoEdit) && (
        <SendForApproval wasSuccessAction={wasSuccessAction} />
      )}
      {(!empId && periods.isEndYearActive) && (
        <SendForReview wasSuccessAction={wasSuccessAction} />
      )}
      <ApproveOrReturn
        empData={employeeData.response}
        setWasSuccessAction={() => setWasSuccessAction(true)}
      />
      <div className={styles.buttonLine}>
        {(!empId) && (
          <div className={styles.buttonWrapper}>
            <GuidebookLink typePage={typePage} />
            <Button
              className={styles.action}
              label={typePage === 'business' ? 'Business objectives' : 'Developments areas'}
              icon={<Icon type="plus" />}
              disabled={noEditMode || !devAreaByEmployee.response}
              onClick={() => showModal<IBusinessProps | IDevelopmentProps>(
                typePage === 'business' ? 'addBusinessObjective' : 'addDevelopmentsAreas',
                {
                  typeAction: 'create', setWasSuccessAction: () => setWasSuccessAction(true), empId: empId!, devAreas: devAreaByEmployee.response,
                },
              )}
            />
          </div>
        )}
        {(!periods.isStartYearActive && !empId) && (
          <div className={styles.buttonWrapper}>
            <GuidebookLink typePage="feedback" />
            <Button
              className={styles.action}
              label="Feedback request"
              disabled={noEditMode}
              onClick={() => showModal<IFeedbackProps>('feedbackRequest', { feedbackType: typePage })}
            />
          </div>
        )}
      </div>

      {isNotRegularEmployee && (
        <>
          {typePage === 'business'
            ? (
              <GoalList
                businessObjectives={businessObjectives.response}
                isLoading={businessObjectives.isLoading}
                setWasSuccessAction={() => setWasSuccessAction(true)}
              />
            )
            : (
              <CompetencieList
                devArea={devAreaByEmployee.response}
                isLoading={devAreaByEmployee.isLoading}
                setWasSuccessAction={() => setWasSuccessAction(true)}
              />
            )}
        </>
      )}
      <ManagerAssessment
        empData={employeeData.response}
        setWasSuccessAction={() => setWasSuccessAction(true)}
      />
      {(isNotRegularEmployee && typePage === 'business') && (
        <SelfAssesment
          setWasSuccessAction={() => setWasSuccessAction(true)}
          detailsEmpData={detailsEmpData.response}
        />
      )}
      <FeedbackListDevPlan empData={employeeData.response} type={typePage} />
    </div>
  );
});

export default DevelopmentPlanPage;
