import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { notify } from 'storybook-directual';

import CustomTextArea from 'components/CustomTextArea';

import useDirectualApi from 'shared/hooks/useDirectualApi';
import { IResponseSend } from 'shared/types/directual';

import { IFields, IProps } from './types';

import styles from './FeedbackTextarea.module.scss';

const validate = (data: IFields) => {
  const errors: Partial<IFields> = {};
  if (!data.frontText) {
    errors.frontText = 'Input text';
  }
  return errors;
};

const FeedbackTextarea: React.FC<IProps> = ({ feedbackId, setIsSend, value }) => {
  const [resultSave, saveFeedback] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: {
      structure: 'feedbackAnswer',
      endpoint: 'saveFeedbackAnswer',
    },
  });

  const onSubmit = (values: IFields) => {
    saveFeedback({ ...values, id: feedbackId, sent: true });
  };

  const saveFeedbackLocal = (values: IFields) => {
    saveFeedback({ ...values, id: feedbackId });
  };

  useEffect(() => {
    setIsSend(false);
    const { response } = resultSave;
    if (response && response.result[0] && response.result[0].id) {
      notify({ type: 'success', header: 'Info', message: 'Request saved successfully' });
      setIsSend(true);
    } else if (response && !response.result[0].id) {
      notify({ type: 'error', header: 'Error', message: 'Unknown error' });
    }
  }, [resultSave.isLoading]);

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{ frontText: value }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="frontText"
              placeholder="Achievements, development progress and areas for improvements"
              rows={3}
              component={CustomTextArea}
            />
            <button className={styles.submit} type="button" onClick={() => saveFeedbackLocal(form.getState().values)}>Save</button>
            <button className={styles.submit} type="submit">Send</button>
          </form>
        )}
      />
    </div>
  );
};

export default FeedbackTextarea;
