import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { userStore, modalStore } from 'mobx/stores';
import { IProfileData } from 'mobx/stores/user/types';
import { useAssessment } from 'shared/hooks/directual';

import styles from './CommentList.module.scss';

export interface IProps {
  empData?: IProfileData | null;
}

const CommentList: React.FC = observer(() => {
  const { user: { profileData } } = userStore;
  const { modal: { props: { empId } } } = modalStore;

  const { commentList, getCommentList } = useAssessment();

  useEffect(() => {
    getCommentList({ employee_id: empId || profileData.id });
  }, []);

  if (!commentList.isLoading && !commentList.response) {
    return null;
  }

  const { response } = commentList;

  return (
    <div className={styles.wrapper}>
      {(response && response.continueList && response.continueList.length >= 1) && (
        <>
          <div className={styles.title}>Continue to do</div>
          {response.continueList.map(item => (
            <div className={styles.item} key={item.id}>{item.text}</div>
          ))}
        </>
      )}
      {(response && response.startList && response.startList.length >= 1) && (
        <>
          <div className={styles.title}>Start doing</div>
          {response.startList.map(item => (
            <div className={styles.item} key={item.id}>{item.text}</div>
          ))}
        </>
      )}
      {(response && response.stopList && response.stopList.length >= 1) && (
        <>
          <div className={styles.title}>Stop doing</div>
          {response.stopList.map(item => (
            <div className={styles.item} key={item.id}>{item.text}</div>
          ))}
        </>
      )}
    </div>
  );
});

export default CommentList;
