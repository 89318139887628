import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { notify } from 'storybook-directual';

import { userStore } from 'mobx/stores';
import { useBusinessObjective } from 'shared/hooks/directual';
import Notify from 'components/Notify';

import { IProps } from './types';
import styles from './RemoveBusinessObjective.module.scss';

const RemoveBusinessObjective: React.FC<IProps> = observer(({
  businessObjectiveId,
  setWasSuccessAction,
  setWasDeletedIds,
  createdPeriod,
}) => {
  const [isOpenedNotify, setIsOpenedNotify] = useState(false);

  const { periods, user: { profileData } } = userStore;

  const { businessObj: resultRemoveBO, saveBusinessObj: removeBO } = useBusinessObjective();

  const canDelete = useMemo(() => {
    if ((createdPeriod === 'new' && periods.isStartYearActive)
    || (createdPeriod === 'mid' && periods.isMidYearActive)
    || (createdPeriod === 'end' && periods.isEndYearActive)) {
      return true;
    }
    return false;
  }, [createdPeriod]);

  if (!canDelete) {
    return null;
  }

  const onClickOpened = () => setIsOpenedNotify(true);
  const onNotifyClosed = () => setIsOpenedNotify(false);

  const onSubmit = () => {
    removeBO({
      action: 'delete',
      employee_id: profileData.id,
      businessObjective_id: businessObjectiveId,
    });
  };

  useEffect(() => {
    const { response, isLoading } = resultRemoveBO;
    if (!isLoading && response && response.result[0].isValidationOk) {
      notify({ type: 'success', header: 'Info', message: 'Business objective successfully deleted' });
      onNotifyClosed();
      setWasSuccessAction();
      setWasDeletedIds(ids => ([...ids, businessObjectiveId]));
    }
    if (response && !response.result[0].isValidationOk) {
      notify({ type: 'error', header: 'Error', message: 'Unknown error' });
    }
  }, [resultRemoveBO.response, resultRemoveBO.isLoading]);

  return (
    <>
      <div className={styles.link} role="link" tabIndex={0} onKeyDown={onClickOpened} onClick={onClickOpened}>Delete</div>
      {isOpenedNotify && (
        <Notify
          headerText="Warning"
          messageText="Do you want to delete this?"
          onSubmit={onSubmit}
          onCancel={onNotifyClosed}
        />
      )}
    </>
  );
});

export default RemoveBusinessObjective;
