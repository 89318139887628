import React from 'react';
import classnames from 'classnames';

import { IProps } from './types';

import styles from './Badge.module.scss';

const Badge: React.FC<IProps> = ({ text, className = '' }) => (
  <div className={classnames(styles.badge, className)}>{text}</div>
);

export default Badge;
