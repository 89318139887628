import React from 'react';
import { Spinner } from 'storybook-directual';

import { IProps } from './types';

const Loader: React.FC<IProps> = ({ className = '' }) => (
  <div className={className}><Spinner size="big" /></div>
);

export default Loader;
