import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

import Loader from 'components/Loader';

import { userStore } from 'mobx/stores';

import { useEmpId } from 'shared/hooks';
import { useEmployee } from 'shared/hooks/directual';

import defaultAvatarImg from 'assets/img/defaultAvatar.png';

import styles from './ProfileInfo.module.scss';

const ProfileInfo: React.FC = observer(() => {
  const [avatarUrl, setAvatarUrl] = useState(defaultAvatarImg);
  const empId = useEmpId();

  const { user: { profileData } } = userStore;

  const { employeeData, getEmployeeData } = useEmployee();

  useEffect(() => {
    if (empId) {
      getEmployeeData({ id: empId });
    }
  }, [empId]);

  useEffect(() => {
    const userData = empId ? employeeData.response : profileData;
    if (userData && userData.photo_id && userData.photo_id.urlLink) {
      setAvatarUrl(userData.photo_id.urlLink);
    }
  }, [employeeData, profileData, empId]);

  useEffect(() => {
    if (!avatarUrl) {
      setAvatarUrl(defaultAvatarImg);
    }
  }, [avatarUrl]);

  const data = empId ? employeeData.response : profileData;

  return (
    <div className={
      classnames({
        [styles.wrapper]: true,
        [styles.opacity]: employeeData.isLoading,
      })}
    >
      {(employeeData.isLoading) && (
        <Loader className={styles.loader} />
      )}
      <div className={styles.avatar}>
        <img src={empId && employeeData.isLoading ? defaultAvatarImg : avatarUrl} alt="avatar" onError={() => { setAvatarUrl(''); }} />
      </div>
      {!employeeData.isLoading && (
        <div className={styles.info}>
          <span className={styles.title}>{data ? data.fullName : '[fullName]'}</span>
          <span className={classnames(styles.label, styles.role)}>
            {data ? data.position.name : '[position]'}
          </span>
          <span className={styles.value}>
            Start date
            {data ? ` ${data.startWorkDateText}` : ' [Start date]'}
          </span>
          <span className={styles.label}>Hiring board</span>
          <span className={styles.value}>{data ? data.hiringBoard : '[Hiring board]'}</span>
          <span className={styles.label}>Buddy</span>
          <span className={styles.value}>{data ? data.buddy : '[Buddy]'}</span>
        </div>
      )}
    </div>
  );
});

export default ProfileInfo;
