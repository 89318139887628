import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'storybook-directual';

import { IProps } from './types';

import styles from './GuideCard.module.scss';

const GuideCard: React.FC<IProps> = ({ title, created, to }) => {
  const [showIcon, setShowIcon] = useState(false);

  return (
    <Link
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      className={styles.wrapper}
      to={to}
    >
      <div className={styles.detail}>
        <span className={styles.title}>{title}</span>
        {showIcon && (
          <div className={styles.icon}>
            <Icon type="details" />
          </div>
        )}
      </div>
      <span className={styles.value}>{created}</span>
    </Link>
  );
};

export default GuideCard;
