import React, { useEffect, useState } from 'react';

import { TextArea } from 'storybook-directual';
import styles from './CustomTextArea.module.scss';

import { IProps } from './types';

const CustomTextArea: React.FC<IProps> = ({
  label, input, description, ...rest
}) => {
  const { meta: { error, touched } } = rest;

  const [value, setValue] = useState<string>('');

  const handleChange = (val: string) => {
    setValue(val);
    input.onChange(val);
  };

  useEffect(() => {
    handleChange(input.value);
  }, [input.value]);

  return (
    <>
      <div className={styles.customControl}>
        <div className={styles.title}>
          {label && <div className={styles.label}>{label}</div>}
          {(error && touched) && <span className={styles.error}>{error}</span>}
        </div>
        <TextArea
          {...input}
          {...rest}
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value)}
          value={value}
        />
        {description && <div className={styles.description}>{description}</div>}
      </div>
    </>
  );
};

export default CustomTextArea;
