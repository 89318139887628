import React from 'react';
import { Link } from 'react-router-dom';

import { IProps } from './types';

import styles from './InfoBlock.module.scss';

const InfoBlock: React.FC<IProps> = ({
  items,
  emptyLabel = '',
  isEmptyData = false,
  isLoading = false,
  link,
}) => (
  <div className={styles.wrapper}>
    {(!items.length && emptyLabel && isEmptyData && !isLoading) && (
      <Link className={styles.title} to={link}>{emptyLabel}</Link>
    )}
    {!isLoading && items.map(item => (
      <React.Fragment key={item.id}>
        <div className={styles.header}>{item.header}</div>
        <div className={styles.text}>{item.text}</div>
      </React.Fragment>
    ))}
  </div>
);

export default InfoBlock;
