import React, { useEffect, useState } from 'react';

import { userStore } from 'mobx/stores';
import { observer } from 'mobx-react-lite';
import { useEmpId } from 'shared/hooks';
import styles from './SelfAssesment.module.scss';
import actionTypes, { IProps } from './types';
import Assesment from './Assesment';

const SelfAssesment: React.FC<IProps> = observer(({ setWasSuccessAction, detailsEmpData }) => {
  const [typeAction, setTypeAction] = useState<actionTypes>(actionTypes.empty);
  const [statusData, setStatusData] = useState(false);
  const {
    user: { profileData },
    periods: { isEndYearActive, isMidYearActive, isNoEdit },
  } = userStore;

  const empId = useEmpId();

  const refreshData = (value: boolean) => {
    setWasSuccessAction();
    setStatusData(value);
  };

  useEffect(() => {
    if (statusData) {
      setStatusData(false);
    }
  }, [statusData]);

  useEffect(() => {
    if (isMidYearActive) {
      setTypeAction(actionTypes.BOmidYearAssesment);
    } else {
      setTypeAction(actionTypes.BOendYearAssesment);
    }
  }, []);

  return (
    <>
      <div className={styles.assesments}>
        <Assesment
          typeAction={typeAction}
          isMidYearActive={isMidYearActive}
          isEndYearActive={isEndYearActive}
          midReview={empId ? ((detailsEmpData && detailsEmpData.BOmidYearAssesment) || '') : profileData.BOmidYearAssesment}
          endReview={empId ? ((detailsEmpData && detailsEmpData.BOendYearAssesment) || '') : profileData.BOendYearAssesment}
          handlerSubmit={refreshData}
          isNoEdit={isNoEdit || !!empId}
        />
      </div>
    </>
  );
});

export default SelfAssesment;
