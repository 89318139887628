import { IProfileData } from 'mobx/stores/user/types';

export interface IAssesments {
  BOendYearAssesment?: string;
  BOmidYearAssesment?: string;
  DAendYearAssesment?: string;
  DAmidYearAssesment?: string;
  profileStatus: string;
  position: { name: string; };
}

export interface IProps {
  setWasSuccessAction(): void;
  detailsEmpData?: IProfileData | null;
}

enum actionTypes {
  DAmidYearAssesment = 'DAmidYearAssesment',
  BOmidYearAssesment = 'BOmidYearAssesment',
  DAendYearAssesment = 'DAendYearAssesment',
  BOendYearAssesment = 'BOendYearAssesment',
  empty = '',
}

export default actionTypes;
