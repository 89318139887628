import { decorate, observable, action } from 'mobx';

import { IModal, Type } from './types';

const initialModal: IModal = {
  type: 'noModal',
  props: {},
};

export class ModalStore {
  public modal: IModal = initialModal;

  public showModal = <T>(type: Type, props?: T) => {
    this.modal = { type, props };
  };

  public closeModal = () => {
    this.modal = initialModal;
  };
}

decorate(ModalStore, {
  modal: observable,
  showModal: action,
  closeModal: action,
});

export default new ModalStore();
