import { IHeaderMenu } from './types';

export const menuItemsSidebar = [
  {
    label: 'Business objectives',
    link: '/businessObjectives',
  },
  {
    label: 'Developments areas',
    link: '/developmentsAreas',
  },
];

export const initSidebarLayout = {
  title: 'title',
  info: 'info',
  backTitle: 'Main page',
  backUrl: '/',
  menuItems: [{ link: '/', label: '' }],
};

export const initAsEmployee: IHeaderMenu[] = [
  {
    label: 'Main page',
    link: '/',
  },
  {
    label: 'Profile',
    link: '/profile',
  },
  {
    label: 'Development Plan',
    link: '/businessObjectives',
  },
  {
    label: '360 assessment',
    link: '/assessment',
    modal: 'assessment360',
  },
];

export const initHeaderEmpLayout: IHeaderMenu[] = [
  {
    label: 'Main page',
    link: '/',
  },
  {
    label: 'Profile',
    link: '/profile',
  },
  {
    label: 'Personal Development Plan',
    link: '/businessObjectives',
  },
  {
    label: '360 assessment',
    link: '/assessment',
    modal: 'assessment360',
  },
  {
    label: 'Employees',
    link: '/employees',
  },
  {
    label: 'Guidebook',
    link: '/guidebook',
  },
];

export const initHeaderHrLayout: IHeaderMenu[] = [
  {
    label: 'Employees',
    link: '/employees',
  },
  {
    label: 'Requests',
    link: '/requests',
  },
  {
    label: 'Guidebook',
    link: '/guidebook',
    divider: true,
  },
  {
    label: 'Main page',
    link: '/',
  },
  {
    label: 'Personal development plan',
    link: '/businessObjectives',
  },
  {
    label: '360 assessment',
    link: '/assessment',
    modal: 'assessment360',
  },
  {
    label: 'Profile',
    link: '/profile',
  },
];
