import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import YearCard from 'components/YearCard';
import InfoBlock from 'components/InfoBlock';
import Loader from 'components/Loader';

import { useEmpId, useDirectualApi } from 'shared/hooks';
import { useBusinessObjective } from 'shared/hooks/directual';
import { IResponseGet } from 'shared/types/directual';

import { userStore } from 'mobx/stores';

import { IRespComp } from './types';

import styles from './ProbationDevelopmentsAreas.module.scss';

const ProbationDevelopmentsAreas: React.FC = observer(() => {
  const empId = useEmpId();

  const { user: { profileData }, periods } = userStore;

  const { businessObjectives, getBusinessObjective } = useBusinessObjective();

  const [isEmptyData, setIsEmptyData] = useState(false);

  const wasApproved = profileData.profileStatus === 'approved' || profileData.profileStatus === 'inProcess';

  const getDAText = (item: IRespComp) => {
    const additionalInfo = item.competencyIndicator_ids
      .map(i => (
        <div key={i.id} className={styles.info}>
          <div className={styles.infoLabel}>{i.name}</div>
          <div className={styles.infoValue}>{i.comment}</div>
        </div>
      ));
    return additionalInfo;
  };

  const converterDA = (data: IRespComp[]) => data.map(item => ({
    id: item.id,
    header: item.competency_id.name,
    text: getDAText(item),
  }));

  const [devAreas, getDevAreas] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespComp>) => converterDA(res.payload), []),
    configurator: { structure: 'developmentArea', endpoint: 'getDAByEmployee' },
  });

  useEffect(() => {
    const id = empId || profileData.id;
    getDevAreas({ employee_id: id });
    getBusinessObjective({ employee_id: id });
  }, [empId]);

  useEffect(() => {
    if (!devAreas.isLoading && (!devAreas.response
      || (devAreas.response && !devAreas.response.length))) {
      setIsEmptyData(true);
    }
  }, [devAreas.isLoading, devAreas.response]);

  const getAdvText = () => {
    let advText = '';
    if (periods.isMidYearActive) {
      advText = 'Mid-year review';
    }
    if (periods.isEndYearActive) {
      advText = 'End-year review';
    }

    const { response: respBO } = businessObjectives;
    const { response: respDA } = devAreas;

    if (periods.isNoEdit || wasApproved) {
      advText = 'Details';
    } else if (periods.isStartYearActive && !empId
      && ((respBO && respBO.length) || (respDA && respDA.length))) {
      advText = 'Edit areas';
    }

    if (periods.isNoEdit) {
      advText = '';
    }

    return advText;
  };

  return (
    <YearCard
      headerClassName={styles.header}
      title="Developments areas"
      isBig
      advText={getAdvText()}
      withArrow={!getAdvText()}
      onLinkAdvText={(!periods.isNoEdit || !wasApproved)
        && empId ? `/employee/developmentsAreas/${empId}` : '/developmentsAreas'}
    >
      {devAreas.isLoading && <Loader />}
      <InfoBlock
        items={devAreas.response || []}
        emptyLabel={!(periods.isNoEdit || wasApproved) && !empId ? 'Set up areas' : null}
        isLoading={devAreas.isLoading}
        isEmptyData={isEmptyData}
        link={empId ? `/employee/developmentsAreas/${empId}` : '/developmentsAreas'}
      />
    </YearCard>
  );
});

export default ProbationDevelopmentsAreas;
