import React, { useState } from 'react';
import { Icon } from 'storybook-directual';
import classnames from 'classnames';

import { IProps } from './types';

import styles from './CollapsePanel.module.scss';

const CollapsePanel: React.FC<IProps> = ({
  title,
  children,
  rightContent = '',
  marginLeftContent = '38px',
}) => {
  const [isCollapse, setIsCollapse] = useState<boolean>(true);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header} role="presentation" onClick={() => setIsCollapse(!isCollapse)}>
        <div className={styles.title}>
          <Icon type={isCollapse ? 'forward' : 'down'} />
          <span>{title}</span>
        </div>
        {rightContent && <div className={styles.rightContent}>{rightContent}</div>}
      </div>
      <div
        className={classnames({ [styles.content]: true, [styles.hide]: isCollapse })}
        style={{ marginLeft: marginLeftContent }}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsePanel;
