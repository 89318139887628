import React, { useState } from 'react';
import { Input } from 'storybook-directual';
import InputMask from 'react-input-mask';

import { IProps } from './types';

import styles from './CustomInput.module.scss';

const CustomInput: React.FC<IProps> = ({
  label, input, mask, description, disabled, onlyNumbers, ...rest
}) => {
  const { meta: { error, touched } } = rest;
  const [value, setValue] = useState<string>(input.value);
  const handleChange = (val: string) => {
    const convertValue = val.replace(/[+()\s-]+/gm, '');
    if ((onlyNumbers && Number(convertValue)) || !onlyNumbers || convertValue === '') {
      setValue(val);
      input.onChange(val);
    }
  };

  return (
    <>
      <div className={styles.customControl}>
        <div className={styles.title}>
          {label && <div className={styles.label}>{label}</div>}
          {(error && touched) && <span className={styles.error}>{error}</span>}
        </div>
        {(!mask || (mask && disabled)) && (
          <Input
            {...input}
            {...rest}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value)}
            value={value}
            onClear={() => handleChange('')}
            disabled={disabled}
            type={(mask && disabled) ? 'text' : input.type}
          />
        )}
        {(mask && !disabled) && (
          <InputMask
            disabled={false}
            {...input}
            {...rest}
            mask={mask}
            value={value}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value)}
          >
            {() => (<Input placeholder="+7 (999) 999-99-99" onClear={() => handleChange('')} />)}
          </InputMask>
        )}
        {description && <div className={styles.description}>{description}</div>}
      </div>
    </>
  );
};

export default CustomInput;
