import { decorate, observable, action } from 'mobx';

import userStore from '../user';

import {
  ISidebarLayoutStore,
  IInfo,
  ISidebarMenu,
  IHeaderMenu,
} from './types';
import {
  initSidebarLayout,
  initHeaderEmpLayout,
  initHeaderHrLayout,
  initAsEmployee,
  menuItemsSidebar,
} from './initData';

export class SidebarLayoutStore {
  constructor() {
    this.initHeaderMenu();
  }

  public sidebar: ISidebarLayoutStore = initSidebarLayout;

  public headerMenu: IHeaderMenu[] = initHeaderEmpLayout;

  public setData = (info: Partial<IInfo>) => {
    this.sidebar = { ...this.sidebar, ...info };
  };

  public initHeaderMenu = () => {
    const { user } = userStore;
    this.headerMenu = user.isEmployee ? initHeaderEmpLayout : initHeaderHrLayout;
  };

  public setSidebarMenu = (menuItems: ISidebarMenu[] | false, employeeId?: string | null) => {
    const employeeSidebarMenu = menuItemsSidebar.map(item => ({
      ...item,
      link: item.link === '/' ? `/employee${item.link}${employeeId}` : `/employee${item.link}/${employeeId}`,
    }));

    const menu = menuItems || menuItemsSidebar;

    this.sidebar = { ...this.sidebar, menuItems: employeeId ? employeeSidebarMenu : menu };
  };

  public setHeaderMenu = (employeeId: string | null = null) => {
    if (employeeId) {
      this.headerMenu = initAsEmployee.map(item => ({
        ...item,
        link: item.link === '/' ? `/employee${item.link}${employeeId}` : `/employee${item.link}/${employeeId}`,
      }));
    } else {
      const { user } = userStore;
      this.headerMenu = user.isEmployee ? initHeaderEmpLayout : initHeaderHrLayout;
    }
  };
}

decorate(SidebarLayoutStore, {
  sidebar: observable,
  headerMenu: observable,
  setData: action,
  setSidebarMenu: action,
  setHeaderMenu: action,
  initHeaderMenu: action,
});

export default new SidebarLayoutStore();
