import React, { useState, useEffect } from 'react';
import { DatePicker } from 'storybook-directual';
import moment, { Moment as IMoment } from 'moment';

import { IProps } from './types';

import styles from './CustomDatePicker.module.scss';

const CustomDatePicker: React.FC<IProps> = ({
  label, input, description, ...rest
}) => {
  const { meta: { error, touched } } = rest;
  const [date, setDate] = useState<IMoment>();

  const handleChange = (val: IMoment) => {
    if (val) {
      setDate(val);
      input.onChange(val.format('X').toString());
    }
  };

  useEffect(() => {
    if (input.value) {
      handleChange(moment(input.value));
    }
  }, []);

  return (
    <div className={styles.customControl}>
      <div className={styles.title}>
        {label && <div className={styles.label}>{label}</div>}
        {(error && touched) && <span className={styles.error}>{error}</span>}
      </div>
      <DatePicker
        {...input}
        {...rest}
        value={date}
        onChange={handleChange}
      />
    </div>
  );
};

export default CustomDatePicker;
