import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import YearCard from 'components/YearCard';
import Loader from 'components/Loader';

import { useEmpId, useDirectualApi } from 'shared/hooks';
import { useBusinessObjective } from 'shared/hooks/directual';
import { IResponseGet } from 'shared/types/directual';

import { userStore } from 'mobx/stores';

import ListStatus from './ListStatus';

import { IRespComp } from '../ProbationDevelopmentsAreas/types';

import styles from './ProbationBusinessObjectives.module.scss';

const ProbationBusinessObjectives: React.FC = observer(() => {
  const empId = useEmpId();

  const { businessObjectives, getBusinessObjective } = useBusinessObjective();

  const { user: { profileData }, periods } = userStore;

  const [isEmptyData, setIsEmptyData] = useState(false);

  const wasApproved = profileData.profileStatus === 'approved' || profileData.profileStatus === 'inProcess';

  const converterDA = (data: IRespComp[]) => data.map(item => ({
    id: item.id,
    header: item.competency_id.name,
    text: item.competencyIndicatorText,
  }));

  const [devAreas, getDevAreas] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespComp>) => converterDA(res.payload), []),
    configurator: { structure: 'developmentArea', endpoint: 'getDAByEmployee' },
  });

  useEffect(() => {
    const id = empId || profileData.id;
    getBusinessObjective({ employee_id: id });
    getDevAreas({ employee_id: id });
  }, [empId]);

  useEffect(() => {
    if (!businessObjectives.isLoading && (!businessObjectives.response
      || (businessObjectives.response && !businessObjectives.response.length))) {
      setIsEmptyData(true);
    }
  }, [businessObjectives]);

  const getAdvText = () => {
    let advText = '';
    if (periods.isMidYearActive) {
      advText = 'Mid-year review';
    }
    if (periods.isEndYearActive) {
      advText = 'End-year review';
    }

    const { response: respBO } = businessObjectives;
    const { response: respDA } = devAreas;

    if (wasApproved) {
      advText = 'Details';
    } else if (periods.isStartYearActive && !empId
      && ((respBO && respBO.length) || (respDA && respDA.length))) {
      advText = 'Edit tasks';
    }

    if (periods.isNoEdit) {
      advText = '';
    }

    return advText;
  };

  return (
    <YearCard
      headerClassName={styles.header}
      title="Business objectives"
      isBig
      advText={getAdvText()}
      withArrow={!getAdvText()}
      onLinkAdvText={(!periods.isNoEdit || !wasApproved)
        && empId ? `/employee/businessObjectives/${empId}` : '/businessObjectives'}
    >
      {businessObjectives.isLoading && <Loader />}
      <ListStatus
        items={businessObjectives.response || []}
        emptyLabel={!(periods.isNoEdit || wasApproved) && !empId ? 'Set up tasks' : null}
        isLoading={businessObjectives.isLoading}
        isEmptyData={isEmptyData}
        link={empId ? `/employee/businessObjectives/${empId}` : '/businessObjectives'}
      />
    </YearCard>
  );
});

export default ProbationBusinessObjectives;
