import React, { useState, useEffect, useRef } from 'react';
import { Icon } from 'storybook-directual';
import classnames from 'classnames';

import Button from 'components/Button';

import { IOption, IProps } from './type';

import styles from './Dropdown.module.scss';

const Dropdown: React.FC<IProps> = ({
  onChange,
  options,
  activeItem = '',
  className = '',
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const listRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);

  const onSelect = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  const getLabel = () => {
    const option = options.find((item: IOption) => item.value === activeItem);
    return option ? option.label : 'Select filter';
  };

  const handleScroll = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleClick = (e: MouseEvent) => {
    if (btnRef.current && listRef.current && e.target instanceof Element) {
      if (!btnRef.current.contains(e.target) && !listRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  });

  return (
    <div className={classnames(className, styles.wrapper)}>
      <div ref={btnRef}>
        <Button
          label={getLabel()}
          onClick={() => setIsOpen(!isOpen)}
          icon={<Icon type={activeItem ? 'filter-fill' : 'filter'} />}
          type={activeItem ? 'choosen' : 'default'}
        />
      </div>
      <div ref={listRef} className={classnames({ [styles.list]: true, [styles.show]: isOpen })}>
        {options.map((item: IOption) => (
          <div
            key={item.value}
            role="presentation"
            className={styles.listItem}
            onClick={() => onSelect(item.value)}
            onKeyDown={() => onSelect(item.value)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
