import React from 'react';
import { Icon } from 'storybook-directual';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import Button from 'components/Button';

import { modalStore } from 'mobx/stores';

import { IProps } from './types';

import styles from './ModalLayout.module.scss';

const ModalLayout: React.FC<IProps> = observer(({
  title,
  acceptAction,
  children,
  acceptLabel = 'Save',
  cancelLabel = 'Cancel',
  link = undefined,
  acceptDisabled = false,
  showSubmitButtons = true,
}) => {
  const { closeModal } = modalStore;

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <h1 className={styles.title}>{title}</h1>
          <Icon className={styles.closeButton} type="close" onClick={closeModal} />
        </div>
        {link && (
          <Link to={link.url} onClick={() => { closeModal(); }} className={styles.link}>
            {link.label}
          </Link>
        )}
        <div className={styles.separator} />
        <div className={styles.body}>
          {children}
        </div>
        {showSubmitButtons && (
          <div className={styles.footer}>
            <Button
              className={styles.rightIndent}
              label={acceptLabel}
              type="action"
              onClick={acceptAction}
              disabled={acceptDisabled}
            />
            <Button label={cancelLabel} type="default" onClick={closeModal} />
          </div>
        )}
      </div>
    </div>
  );
});

export default ModalLayout;
