import React, { useEffect, useCallback } from 'react';
import { Form, Field } from 'react-final-form';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { notify } from 'storybook-directual';

import CustomInput from 'components/CustomInput';
import Button from 'components/Button';

import useDirectualApi from 'shared/hooks/useDirectualApi';

import { convertResponse } from 'shared/utils/directual';

import { IResponseSend, IResConvert } from 'shared/types/directual';
import { IFields, IMatchParams } from './types';

import styles from './RecoveryPasswordPage.module.scss';

const validate = (values: IFields) => {
  const { newPassword, newDoublePassword } = values;
  const errors: Partial<IFields> = {};
  if (!newPassword) {
    errors.newPassword = 'Enter a new password';
  }
  if (!newDoublePassword) {
    errors.newDoublePassword = 'Enter password to confirm';
  }
  if ((newPassword && newDoublePassword) && newPassword !== newDoublePassword) {
    errors.newDoublePassword = 'Password mismatched';
  }

  return errors;
};

const RecoveryPasswordPage: React.FC = () => {
  const match = useRouteMatch<IMatchParams>();
  const history = useHistory();

  const [recovery, setNewPassword] = useDirectualApi<IResConvert, null>({
    type: 'sendData',
    converter: useCallback((res: IResponseSend) => convertResponse(res), []),
    configurator: {
      structure: 'passwordRecoveryPage',
      endpoint: 'passwordRecovery',
    },
  });

  const onSubmit = (values: IFields) => {
    const { params: { hash } } = match;
    setNewPassword({ id: hash, ...values });
  };

  useEffect(() => {
    const { response } = recovery;

    if (response && response.status) {
      notify({ type: 'success', header: 'Info', message: 'Password changed successfully' });
      history.push('/login');
    } else if (response && !response.status && response.msg) {
      notify({ type: 'error', header: 'Error', message: response.msg });
    }
  }, [recovery.isLoading]);

  return (
    <div className={styles.container}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <h2>Recovery password</h2>
            <div className={styles.input}>
              <Field
                name="newPassword"
                label="New password"
                component={CustomInput}
                type="password"
              />
            </div>
            <div className={styles.input}>
              <Field
                name="newDoublePassword"
                label="Confirm password"
                component={CustomInput}
                type="password"
              />
            </div>
            <div className={styles.btnBlock}>
              <Button label="Confirm" btnType="submit" type="action" />
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default RecoveryPasswordPage;
