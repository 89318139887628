import React from 'react';
import { Icon } from 'storybook-directual';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { sidebarStore } from 'mobx/stores';

import SidebarMenu from 'components/SidebarMenu';

import styles from './SidebarLayout.module.scss';

const SidebarLayout: React.FC = observer(({ children }) => {
  const { sidebar } = sidebarStore;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <Link to={sidebar.backUrl} className={styles.back}>
            <Icon type="arrow-left" />
            {sidebar.backTitle}
          </Link>
          <div className={styles.menu}>
            <SidebarMenu items={sidebar.menuItems} />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.infoBlock}>
            <h1>{sidebar.title}</h1>
            <span className={styles.created}>{sidebar.info}</span>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
});

export default SidebarLayout;
