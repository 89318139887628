import { useCallback } from 'react';

import { IResponseGet } from 'shared/types/directual';
import { IProfileData } from 'mobx/stores/user/types';

import useDirectualApi from '../../useDirectualApi';

import { converterEmpData, converterEmpList, converterFilterEmpList } from './converters';
import {
  IConvertSupervisors,
  IResponseSupervisors,
  ISearchData,
  IAuthId,
} from './types';

const useEmployee = () => {
  const [employeeData, getEmployeeData] = useDirectualApi({
    type: 'getData',
    converter: useCallback(
      (res: IResponseGet<IProfileData>) => converterEmpData(res.payload[0]), [],
    ),
    configurator: { structure: 'employee', endpoint: 'getEmployeeById' },
  });

  const [supervisors, getSupervisors] = useDirectualApi<IConvertSupervisors[], null>({
    type: 'getData',
    converter: useCallback(res => res.payload
      .map((item: IResponseSupervisors): IConvertSupervisors => ({
        ...item,
        value: item.fullName,
        key: item.id,
      })), []),
    configurator: { structure: 'employee', endpoint: 'getSupervisors' },
    getFullList: true,
  });

  const [searchEmployees, getSearchEmployees] = useDirectualApi({
    type: 'getData',
    converter: useCallback(((res: IResponseGet<ISearchData>) => converterEmpList(res.payload)), []),
    configurator: { structure: 'employee', endpoint: 'searchEmployee' },
    getFullList: true,
  });

  const [detailsEmpData, getDetailsInPeriod] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IProfileData>) => res.payload[0], []),
    configurator: { structure: 'employee', endpoint: 'getDetailsInPeriod' },
  });

  const [filteredEmployees, getFilteredEmployees] = useDirectualApi({
    type: 'getData',
    converter: useCallback(res => ({
      payload: converterFilterEmpList(res.payload), pageInfo: res.pageInfo,
    }), []),
    configurator: { structure: 'employee', endpoint: 'getFilteredEmployees' },
    getFullList: true,
  });

  const [employeeAuth, getEmployeeAuth] = useDirectualApi<IResponseGet<IAuthId>, null>({
    type: 'getData',
    configurator: {
      structure: 'employee',
      endpoint: 'getEmployeeAuth',
    },
  });

  /* eslint-disable object-property-newline */
  return {
    employeeData, getEmployeeData,
    supervisors, getSupervisors,
    searchEmployees, getSearchEmployees,
    detailsEmpData, getDetailsInPeriod,
    filteredEmployees, getFilteredEmployees,
    employeeAuth, getEmployeeAuth,
  };
  /* eslint-enable object-property-newline */
};

export default useEmployee;
