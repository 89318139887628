import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { notify } from 'storybook-directual';
import { observer } from 'mobx-react-lite';

import ModalLayout from 'layouts/ModalLayout';

import CustomTextArea from 'components/CustomTextArea';

import { IResponseSend } from 'shared/types/directual';
import useDirectualApi from 'shared/hooks/useDirectualApi';

import { modalStore, userStore } from 'mobx/stores';

import { IFields, IPropsModal } from './types';

const validate = (fields: IFields) => {
  const errors: Partial<IFields> = {};
  if (!fields.rejectComment) {
    errors.rejectComment = 'Enter the comment';
  }
  return errors;
};

const ReturnForRevision: React.FC = observer(() => {
  const [result, rejectReview] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'actionInput', endpoint: 'saveAction' },
  });

  const { user: { profileData } } = userStore;
  const { closeModal, modal } = modalStore;

  const modalProps: IPropsModal = modal.props;

  const onSubmit = (values: IFields) => {
    rejectReview({
      action: 'reject',
      rejectComment: values.rejectComment,
      employee_id: modalProps.employeeId,
      initiator_id: profileData.id,
    });
  };

  useEffect(() => {
    const { response } = result;
    if (response && response.result[0].id) {
      modalProps.setWasSuccess(true);
      notify({ type: 'success', header: 'Info', message: 'You returned the form for revision' });
      closeModal();
    } else if (response && !response.result[0].id) {
      notify({ type: 'error', header: 'Error', message: 'Unknown error' });
    }
  }, [result.isLoading]);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <ModalLayout
            title="Return for revision"
            acceptAction={handleSubmit}
            acceptLabel="Return"
          >
            <form onSubmit={handleSubmit}>
              <Field
                name="rejectComment"
                label="Comment"
                component={CustomTextArea}
                rows={3}
                placeholder="Indicate the reason for refusal"
              />
            </form>
          </ModalLayout>
        )}
      />
    </>
  );
});

export default ReturnForRevision;
