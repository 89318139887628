export const FILTER_LIST = [
  {
    label: 'Position',
    value: 'positions',
  },
  {
    label: 'Department',
    value: 'departments',
  },
  {
    label: 'Supervisor',
    value: 'supervisors',
  },
  {
    label: 'Company',
    value: 'companies',
  },
  {
    label: 'Location',
    value: 'locations',
  },
  {
    label: 'Working status',
    value: 'workingStatus',
    isHr: true,
  },
];

export const employeeList = [
  {
    id: 1,
    photo: '',
    fullname: 'Jesus Bañuelos',
    position: 'Financial analyst',
    workSince: '20 aug 2016',
    phone: '+7 (999) 999-99-99',
    email: 'J_Bañuelos@ufgwm.com',
    department: 'Financial administration',
    supervisor: 'Julian Gruber',
    company: 'UFG Wealth Management',
    location: 'Moscow, +7 (495) 664-70-70',
    probation: true,
  },
  {
    id: 2,
    photo: '',
    fullname: 'Paromita Haque',
    position: 'Financial analyst',
    workSince: '20 aug 2017',
    phone: '+7 (999) 999-99-99',
    email: 'J_Bañuelos@ufgwm.com',
    department: 'Financial administration',
    supervisor: 'Julian Gruber',
    company: 'UFG Wealth Management',
    location: 'Moscow, +7 (495) 664-70-70',
    probation: false,
  },
];

export const options = {
  position: [
    {
      id: 'init',
      value: 'All positions',
      key: 'init',
    },
    {
      id: 'first',
      value: 'Position 1',
      key: 'first',
    },
  ],
  department: [
    {
      id: 'init',
      value: 'All departments',
      key: 'init',
    },
    {
      id: 'first',
      value: 'Department 1',
      key: 'first',
    },
  ],
  supervisor: [
    {
      id: 'init',
      value: 'All employees',
      key: 'init',
    },
    {
      id: 'first',
      value: 'Supervisor 1',
      key: 'first',
    },
  ],
  company: [
    {
      id: 'init',
      value: 'All companies',
      key: 'init',
    },
    {
      id: 'first',
      value: 'Company 1',
      key: 'first',
    },
  ],
  location: [
    {
      id: 'init',
      value: 'All locations',
      key: 'init',
    },
    {
      id: 'first',
      value: 'Location 1',
      key: 'first',
    },
  ],
  probation: [
    {
      id: 'init',
      value: 'Yes',
      key: 'init',
    },
    {
      id: 'no',
      value: 'No',
      key: 'no',
    },
  ],
};
