import { useCallback } from 'react';

import { IResponseGet, IResponseSend } from 'shared/types/directual';

import useDirectualApi from '../../useDirectualApi';

import { IResStatus, IRespBO } from './types';
import { converterBO } from './converters';

const useBusinessObjective = () => {
  const [statuses, getStatuses] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IResStatus>) => res.payload, []),
    configurator: { structure: 'readyStatus', endpoint: 'getReadyStatus' },
  });

  const [businessObj, saveBusinessObj] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'businessObjectiveInput', endpoint: 'saveBOInput' },
  });

  const [businessObjectives, getBusinessObjective] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespBO>) => converterBO(res.payload), []),
    configurator: { structure: 'businessObjective', endpoint: 'getForEmployee' },
  });

  /* eslint-disable object-property-newline */
  return {
    statuses, getStatuses,
    businessObj, saveBusinessObj,
    businessObjectives, getBusinessObjective,
  };
  /* eslint-enable object-property-newline */
};

export default useBusinessObjective;
