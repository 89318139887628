import React, { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import FeedbackComment from 'components/FeedbackComment';
import FeedbackTextarea from 'components/FeedbackTextarea';
import Loader from 'components/Loader';

import { userStore } from 'mobx/stores';

import { useDirectualApi, useEmpId } from 'shared/hooks';
import { IResponseGet } from 'shared/types/directual';
import { getFormatDDMMYYYY } from 'shared/utils/time';

import { IResFeedback, IProps } from './types';

import styles from './FeedbackListDevPlan.module.scss';

const FeedbackListDevPlan: React.FC<IProps> = observer(({ type, empData }) => {
  const [isSend, setIsSend] = useState(false);

  const empId = useEmpId();

  const {
    user,
    user: { profileData },
    periods,
  } = userStore;

  const convertCallback = (item: IResFeedback) => ({
    id: item.id,
    text: item.text || '',
    initiatorId: item.initiator_id ? item.initiator_id.id : '',
    fullName: item.initiator_id ? item.initiator_id.fullName : '',
    dateCreated: item.dateCreated ? getFormatDDMMYYYY(item.dateCreated) : '',
    position: (item.initiator_id && item.initiator_id.position) ? item.initiator_id.position.name : '',
    avatar: (item.initiator_id && item.initiator_id.photo_id) ? item.initiator_id.photo_id.urlLink : '',
    comment: item.feedbackRequest_id ? item.feedbackRequest_id.comment : '',
  });

  const converterForSend = (data: IResFeedback[]) => data.map(convertCallback)[0];
  const convertForGet = (data: IResFeedback[]) => data
    .map(convertCallback)
    .filter(item => item.id !== user.profileData.id)
    .filter(item => item.text);

  const getEndpointTypeForGet = () => {
    let endpoint = '';
    let isHrMode = false;

    endpoint = type === 'business' ? 'getBusinessFeedbackAnswers' : 'getDevelopmentFeedbackAnswers';

    if (user.isHr) {
      endpoint = type === 'business' ? 'getBusinessFeedbackAnswersHR' : 'getDevelopmentFeedbackAnswersHR';
      isHrMode = true;
    }

    return { endpoint, isHrMode };
  };

  const [feedbackForSend, getFeedbackForSend] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IResFeedback>) => converterForSend(res.payload), []),
    configurator: {
      structure: 'feedbackAnswer',
      endpoint: type === 'business' ? 'getBusinessFeedbackToFill' : 'getDevelopmentFeedbackToFill',
    },
  });

  const [feedbacksForGet, getFeedbacksForGet] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IResFeedback>) => convertForGet(res.payload), []),
    configurator: {
      structure: 'feedbackAnswer',
      endpoint: type === 'business' ? 'getBusinessFeedbackAnswers' : 'getDevelopmentFeedbackAnswers',
    },
    getFullList: true,
  });

  const getFeedbacks = () => {
    if (empId && empData) {
      getFeedbackForSend({ aboutUser_id: empId, authUser_id: user.profileData.id });
      if (empData.supervisor_id.id !== profileData.id) {
        getFeedbacksForGet({
          authUser_id: user.profileData.id,
          aboutUser_id: empId || user.profileData.id,
        }, getEndpointTypeForGet().endpoint);
      } else {
        getFeedbacksForGet({
          aboutUser_id: empId || user.profileData.id,
        }, getEndpointTypeForGet().endpoint);
      }
    }
    if (!empId) {
      getFeedbacksForGet({
        aboutUser_id: empId || user.profileData.id,
      }, getEndpointTypeForGet().endpoint);
    }
  };

  useEffect(() => {
    getFeedbacks();
  }, [empData && empData.supervisor_id.id, type]);

  useEffect(() => {
    if (isSend) {
      getFeedbacks();
    }
  }, [isSend]);

  const { response: feedbackInput } = feedbackForSend;
  const { response: feedbacksOutput } = feedbacksForGet;

  return (
    <div className={styles.commentBlock}>
      <div className={styles.title}>Feedback</div>
      <div>
        {(feedbackForSend.isLoading || feedbacksForGet.isLoading)
        && <Loader className={styles.noData} />}
        {(!feedbackInput && ((feedbacksOutput && !feedbacksOutput.length)
        || (!feedbacksOutput && feedbacksForGet.response)) && (
          <div className={styles.noData}>No feedback yet</div>
        ))}
        {(feedbackInput && (periods.isMidYearActive || periods.isEndYearActive)) && (
          <div>
            <div className={styles.comment}>{feedbackInput.comment}</div>
            <FeedbackTextarea
              key={feedbackInput.id}
              feedbackId={feedbackInput.id}
              value={feedbackInput.text}
              setIsSend={setIsSend}
            />
          </div>
        )}
        {feedbacksOutput && feedbacksOutput.map(item => (
          <FeedbackComment
            key={item.id}
            fullName={item.fullName}
            dateCreated={item.dateCreated}
            position={item.position}
            text={item.text}
            avatar={item.avatar}
          />
        ))}
      </div>
    </div>
  );
});

export default FeedbackListDevPlan;
