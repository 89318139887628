import React, { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import _uniqBy from 'lodash/uniqBy';
import { Form, Field } from 'react-final-form';
import { notify } from 'storybook-directual';
import classnames from 'classnames';

import ModalLayout from 'layouts/ModalLayout';

import Loader from 'components/Loader';
import CustomTextArea from 'components/CustomTextArea';

import { useDirectualApi } from 'shared/hooks';
import { useEmployee, useAssessment } from 'shared/hooks/directual';
import { IResponseGet } from 'shared/types/directual';
import { IRespAvg } from 'shared/hooks/directual/useAssessment/types';

import { userStore, modalStore } from 'mobx/stores';

import { IPropsModal as IFeedbackProps } from '../FeedbackRequest/types';

import InputNumber from './InputNumber';
import CommentList from './CommentList';

import {
  IPropsModal,
  IRespGetBy360Id,
  IFields,
  IConvGetBy360Id,
} from './types';

import styles from './Assessment360.module.scss';

const Assessment360: React.FC = observer(() => {
  const [hasAllValues, setHasAllValues] = useState(false);
  const [valueList, setValueList] = useState<{ id: string; value: string; }[]>([]);

  const [fieldList, setFieldList] = useState<IConvGetBy360Id[]>([]);

  const { employeeData, getEmployeeData } = useEmployee();
  const {
    saveAssessmentRes,
    saveAssessmentReq,
    fullAvg,
    getFullAverage,
    employeeAvg,
    getEmployeeAverage,
    getToFill360,
    toFill360,
  } = useAssessment();

  const { modal, closeModal, showModal } = modalStore;
  const modalProps: IPropsModal = modal.props;

  const { user: { profileData } } = userStore;

  const { id: toFill360Id, empId, submitted: submitted360 } = modalProps;

  const userData = employeeData.response ? employeeData.response : profileData;

  useEffect(() => {
    if (empId) {
      getEmployeeData({ id: empId });
    }
  }, [modalProps]);

  const [submitted, setSubmitted] = useState(!!submitted360);

  const compareFields = (field: IRespGetBy360Id, nextField: IRespGetBy360Id) => {
    const { competency_id: { sort_num: index } } = field;
    const { competency_id: { sort_num: nextIndex } } = nextField;

    if (index > nextIndex) {
      return 1;
    }
    if (index === nextIndex) {
      return 0;
    }
    if (index < nextIndex) {
      return -1;
    }
    return 0;
  };

  const getStructureForForm = (data: IRespGetBy360Id[]) => {
    const sortedData = data.sort(compareFields);

    const onlyChilds = sortedData.map(item => ({
      id: item.competencyIndicator_id.id,
      parentId: item.competency_id.id,
      label: item.competencyIndicator_id.name,
      toFill360Id: item.id,
      value: item.assesment ? item.assesment.toString() : '',
      avgCompany: '',
      avgEmployee: '',
    }));

    const onlyParent = sortedData.map(item => ({
      id: item.competency_id.id,
      title: item.competency_id.name,
      fields: onlyChilds.filter(ch => ch.parentId === item.competency_id.id),
    }));

    const parentsArray = _uniqBy(onlyParent, 'id');

    setValueList(onlyChilds.map(ch => ({
      id: ch.toFill360Id,
      value: ch.value,
    })));

    setFieldList(parentsArray);

    return _uniqBy(onlyParent, 'id');
  };

  useEffect(() => {
    setHasAllValues(valueList.every(i => i.value));
  }, [valueList]);

  const onNewValue = (newVal: { id: string; value: string; }) => {
    setValueList(prevState => {
      const clonePrevState = [...prevState];

      const itemIndex = clonePrevState.findIndex(i => i.id === newVal.id);

      if (itemIndex !== -1) {
        clonePrevState.splice(itemIndex, 1, newVal);
      } else if (!itemIndex) {
        clonePrevState.push(newVal);
      }

      return clonePrevState;
    });
  };

  const [answer360, getBy360Id] = useDirectualApi({
    type: 'getData',
    converter: useCallback(
      (res: IResponseGet<IRespGetBy360Id>) => getStructureForForm(res.payload), [],
    ),
    configurator: { structure: '360answer', endpoint: 'getBy360Id' },
  });

  useEffect(() => {
    if (submitted && empId) {
      getToFill360({
        aboutEmployee_id: empId,
        authEmployee_id: empId,
      });
    }
  }, []);

  useEffect(() => {
    if (!toFill360.response && empId && submitted) {
      return;
    }
    getBy360Id({
      id: (submitted && empId && toFill360.response)
        ? toFill360.response.id : toFill360Id,
    });

    if (modalProps['5answersDone']) {
      getFullAverage();
      getEmployeeAverage({
        employee_id: employeeData.response ? employeeData.response.id : profileData.id,
      });
    }
  }, [toFill360.isLoading]);

  const onSubmit = (values: IFields) => {
    if (!empId) {
      showModal<IFeedbackProps>('feedbackRequest', {
        feedbackType: '360assesment',
        assessmentProps: {
          ...values,
          id: toFill360Id,
          submitted: true,
        },
      });
    } else {
      saveAssessmentReq({
        ...values,
        id: toFill360Id,
        submitted: true,
      });
    }
  };

  useEffect(() => {
    const { response } = saveAssessmentRes;
    if (response && response.result[0] && response.result[0].id) {
      notify({ type: 'success', header: 'Info', message: 'Request approved successfully' });
      setSubmitted(true);
      closeModal();
    } else if (response
      && (!response.result[0] || (response.result[0] && !response.result[0].id))) {
      notify({ type: 'error', header: 'Error', message: 'Unknown error' });
    }
  }, [saveAssessmentRes.isLoading]);

  useEffect(() => {
    const { response: respAnswer } = answer360;

    if (respAnswer) {
      const { response: respFull } = fullAvg;
      const { response: respEmpl } = employeeAvg;

      const insertAvg = (respAvg: IRespAvg[], type: 'avgCompany' | 'avgEmployee') => {
        setFieldList(prevState => {
          const clonePrevState = [...prevState];
          const newState = clonePrevState.map(fieldsArray => {
            const newFieldsArray = fieldsArray.fields.map(fieldListItem => {
              const avgFindItem = respAvg.find(f => f.competencyIndicator_id === fieldListItem.id);
              if (avgFindItem) {
                return {
                  ...fieldListItem,
                  [type]: avgFindItem.assesment___avg.toFixed(1).toString(),
                };
              }
              return fieldListItem;
            });
            return { ...fieldsArray, fields: [...newFieldsArray] };
          });
          return newState;
        });
      };

      if (respEmpl) {
        insertAvg(respEmpl, 'avgEmployee');
      }
      if (respFull) {
        insertAvg(respFull, 'avgCompany');
      }
    }
  }, [answer360.isLoading, fullAvg.isLoading, employeeAvg.isLoading]);

  const validate = (values: Partial<IFields>) => {
    const errors: Partial<IFields> = {};
    if (!values.continueText && empId) {
      errors.continueText = 'Enter the continue to do text';
    }
    if (!values.startText && empId) {
      errors.startText = 'Enter the start doing text';
    }
    if (!values.stopText && empId) {
      errors.stopText = 'Enter the stop doing text';
    }
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <ModalLayout
          title={`${userData.fullName} competency assessment`}
          acceptAction={handleSubmit}
          acceptLabel="Send answers"
          link={{ url: '/guidebook/360-assessment', label: 'Guidebook' }}
          acceptDisabled={!hasAllValues}
          showSubmitButtons={!submitted}
        >
          <div className={classnames({
            [styles.formWrapper]: true,
            [styles.opacity]: saveAssessmentRes.isLoading,
          })}
          >
            {saveAssessmentRes.isLoading && (<Loader className={styles.loader} />)}
            <div className={styles.header}>
              <div className={styles.title}>360 assessment steps</div>
              <div className={styles.steps}>
                <div className={styles.step}>
                  Step 1 – Make a self-assessment and submit your answers.
                </div>
                <div className={styles.step}>
                  Step 2 – Select assessors -  people who will provide feedback on your
                  competencies: subordinates (if  any), your line manager, peers (people you
                  work with). You must choose at least 5 people to get 360 assessment.
                </div>
                <div className={styles.step}>
                  Step 3 – Get your personal report which will be generated if you receive minimum
                  5 responses. You  can compare your self-evaluation with your assessors’ group
                  evaluation and the company average.
                </div>
                <div className={styles.desc}>
                  The process is completely confidential. The program is designed
                  to provide anonymity for each individual who completes a form.
                  You will get your report in aggregated format.
                </div>
              </div>
              <div className={styles.title}>Rating scale</div>
              <div className={styles.scales}>
                <div className={styles.scale}>
                  In order to evaluate employee’s competencies, please chose one on the ratings
                </div>
                <div className={styles.scale}>
                  0 – Not applicable
                </div>
                <div className={styles.scale}>
                  1 – This person never demonstrates this behavior
                </div>
                <div className={styles.scale}>
                  2 – This person rarely demonstrates this behavior
                </div>
                <div className={styles.scale}>
                  3 - This person sometimes  demonstrates this behavior
                </div>
                <div className={styles.scale}>
                  4 - This person usually demonstrates this behavior
                </div>
                <div className={styles.scale}>
                  5 ­- This person always demonstrates this behavior
                </div>
                <div className={styles.scale}>
                  6 - This person is a role model
                </div>
              </div>
            </div>
            {(answer360.isLoading || toFill360.isLoading) && <Loader />}
            {(!answer360.isLoading && !fullAvg.isLoading && !employeeAvg.isLoading)
            && fieldList.map((item, index) => (
              <div key={item.title}>
                <div className={styles.group}>
                  <div className={styles.title}>{item.title}</div>
                  {(index === 0 && submitted) && (
                  <div className={styles.scales}>
                    <div className={styles.name}>Self-assessment</div>
                    <div className={styles.name}>360 assessment</div>
                    <div className={styles.name}>Company average</div>
                  </div>
                  )}
                </div>
                {item.fields.map(field => (
                  <InputNumber
                    key={field.id}
                    label={field.label}
                    toFill360Id={field.toFill360Id}
                    initialValue={submitted ? (field.value || '-') : ''}
                    setNewValue={onNewValue}
                    disabled={submitted}
                    avgCompany={submitted ? (field.avgCompany || '-') : ''}
                    avgEmployee={submitted ? (field.avgEmployee || '-') : ''}
                  />
                ))}
              </div>
            ))}
            {(empId && !submitted) && (
              <div className={styles.comment}>
                <div className={styles.text}>
                  Please provide specific and constructive comments in response to the questions
                  below. Your observations will help to strengthen this person’s
                  skills and attitude/ behavior.
                </div>
                <div className={styles.text}>
                  In order to maximize his/her effectiveness, what should this person
                </div>
                <form className={styles.formTextarea} onSubmit={handleSubmit}>
                  <Field
                    name="continueText"
                    label="Continue to do"
                    placeholder="Share your observations of working with a collegue and give specific recommendations"
                    rows={5}
                    component={CustomTextArea}
                  />
                  <Field
                    name="startText"
                    label="Start doing"
                    placeholder="Share your observations of working with a collegue and give specific recommendations"
                    rows={5}
                    component={CustomTextArea}
                  />
                  <Field
                    name="stopText"
                    label="Stop doing"
                    placeholder="Share your observations of working with a collegue and give specific recommendations"
                    rows={5}
                    component={CustomTextArea}
                  />
                </form>
              </div>
            )}
            {submitted && <CommentList />}
          </div>
        </ModalLayout>
      )}
    />
  );
});

export default Assessment360;
