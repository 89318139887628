import React from 'react';
import classnames from 'classnames';

import { IProps } from './types';

import styles from './Button.module.scss';

const Button: React.FC<IProps> = ({
  label,
  onClick = () => {},
  type = 'default',
  icon = null,
  className = '',
  disabled = false,
  btnType = 'button',
  downloadLink = '',
  target = '_blank',
}) => {
  const wrapperClasses = classnames({
    [styles.wrapper]: true,
    [styles.default]: type === 'default',
    [styles.action]: type === 'action',
    [styles.choosen]: type === 'choosen',
    [styles.disabled]: disabled,
    [className]: true,
  });

  const btnClasses = classnames({
    [styles.btn]: true,
    [styles.btnWithIcon]: !!icon,
  });

  return (
    <div
      tabIndex={0}
      role="button"
      onKeyDown={onClick}
      onClick={disabled ? undefined : onClick}
      className={wrapperClasses}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {btnType === 'button' && <button disabled={disabled} className={btnClasses} type="button">{label}</button>}
      {btnType === 'submit' && <button disabled={disabled} className={btnClasses} type="submit">{label}</button>}
      {btnType === 'download'
      && <a href={downloadLink} target={target} download><button disabled={disabled} className={btnClasses} type="submit">{label}</button></a>}
    </div>
  );
};

export default Button;
