import React from 'react';

import { IProps } from './types';

import styles from './ColorCircle.module.scss';

const ColorCircle: React.FC<IProps> = ({ color }) => (
  <div className={styles.circle} style={{ backgroundColor: color }} />
);

export default ColorCircle;
