import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import ToApprove from 'components/ToApprove';
import Button from 'components/Button';

import { IPropsModal as IReturnProps } from 'components/ModalRoot/Modals/ReturnForRevision/types';
import { IPropsModal as IPerformanceProps } from 'components/ModalRoot/Modals/PerformanceEvaluation/types';

import { userStore, modalStore } from 'mobx/stores';

import { useEmpId } from 'shared/hooks';

import { IProps } from './types';

import styles from './ApproveOrReturn.module.scss';

const ApproveOrReturn: React.FC<IProps> = observer(({
  setWasSuccessAction: setWasSuccessPerforEval,
  empData,
}) => {
  const [wasSuccess, setWasSuccess] = useState(false);
  const [isCanApprove, setIsCanApprove] = useState(false);

  const {
    periods,
    user: { profileData },
  } = userStore;
  const { showModal } = modalStore;

  const empId = useEmpId();

  useEffect(() => {
    if (empData) {
      if (profileData.id === empData.supervisor_id.id) {
        setIsCanApprove(true);
      }
    }
  }, [empData]);

  if (!isCanApprove) {
    return null;
  }

  const noEditMode = empData && (periods.isNoEdit || empData.profileStatus === 'approved');

  return (
    <>
      {(empId && empData && empData.profileStatus) && (
        <div className={styles.buttonLine}>
          <ToApprove
            profileStatus={empData.profileStatus || ''}
            setWasSuccess={setWasSuccess}
            disabled={noEditMode || wasSuccess}
          />
          <Button
            className={styles.action}
            label="Return for revision"
            disabled={noEditMode || wasSuccess}
            onClick={() => showModal<IReturnProps>('returnForRevision', {
              employeeId: empId,
              setWasSuccess: (val: boolean) => setWasSuccess(val),
            })}
          />
        </div>
      )}
      {(empData && empData.profileStatus === 'approved' && empId && periods.isEndYearActive) && (
        <Button
          label="Performance evaluation"
          type="action"
          disabled={!!empData.endYearSupervisorAssesmentComment}
          onClick={() => showModal<IPerformanceProps>('performanceEvaluation', {
            typeAction: 'create',
            empId,
            empData: empId ? empData : null,
            setWasSuccess: () => setWasSuccessPerforEval && setWasSuccessPerforEval(),
          })}
        />
      )}
    </>
  );
});

export default ApproveOrReturn;
