import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import defaultAvatar from 'assets/img/defaultAvatar.png';

import { IProps } from './types';

import styles from './FeedbackList.module.scss';

const FeedbackList: React.FC<IProps> = ({
  items,
  emptyLabel = '',
  isEmptyData = false,
  isLoading = false,
  onClickEmpty,
}) => (
  <div className={styles.wrapper}>
    {(!items.length && emptyLabel && isEmptyData && !isLoading) && (
      <div role="presentation" className={styles.title} onClick={onClickEmpty}>{emptyLabel}</div>
    )}
    {!isLoading && items.map(item => {
      const LinkOrDiv = item.link ? Link : 'div';

      return (
        <>
          <LinkOrDiv
            data-tip={item.fullName}
            role="presentation"
            className={styles.avatar}
            key={item.id}
            to={item.link || ''}
          >
            <img src={item.photoLink || defaultAvatar} alt={item.fullName} />
            {item.isGreen && (
              <div className={classnames({
                [styles.status]: true,
                [styles.green]: item.isGreen,
              })}
              />
            )}
          </LinkOrDiv>
          <ReactTooltip />
        </>
      );
    })}
  </div>
);

export default FeedbackList;
