import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import Loader from 'components/Loader';
import YearCard from 'components/YearCard';

import { useEmployee, useAssessment } from 'shared/hooks/directual';
import { getFormatDDMMYYYY } from 'shared/utils/time';
import { IRespReviewDates } from 'shared/hooks/directual/useAssessment/types';

import { userStore } from 'mobx/stores';

import { IProfileData } from 'mobx/stores/user/types';
import { useEmpId } from 'shared/hooks';
import { IPeriods, IConvertedReviewDates } from './types';

import styles from './ReviewDates.module.scss';

const isNoEditMode = (periods: IPeriods) => {
  let result = false;

  if (!periods.isStartYearActive && !periods.isMidYearActive && !periods.isEndYearActive) {
    result = true;
  }

  return result;
};

const ReviewDates: React.FC = () => {
  const { reviewDates, getReviewDates } = useAssessment();
  const { detailsEmpData, getDetailsInPeriod } = useEmployee();

  const empId = useEmpId();
  const { user: { profileData }, setPeriod } = userStore;

  const [empReviewDates, setEmpReviewDates] = useState<IConvertedReviewDates>();

  const getPeriod = (payload: IRespReviewDates | IProfileData) => {
    const periods = {
      isStartYearActive: !!payload.isStartYearActive,
      isMidYearActive: !!payload.isMidYearActive,
      isEndYearActive: !!payload.isEndYearActive,
    };

    setPeriod({ ...periods, isNoEdit: isNoEditMode(periods) });
  };

  const converterRD = (payload: IRespReviewDates, periods? : IProfileData) => {
    getPeriod(periods || payload);
    return [
      {
        id: `${payload.newYear_start}_${payload.newYear_end}`,
        name: 'Objective settings',
        dateStart: getFormatDDMMYYYY(payload.newYear_start),
        dateEnd: getFormatDDMMYYYY(payload.newYear_end),
        isActive: periods ? periods.isStartYearActive : payload.isStartYearActive,
      },
      {
        id: `${payload.midYear_start}_${payload.midYear_end}`,
        name: 'Mid year review',
        dateStart: getFormatDDMMYYYY(payload.midYear_start),
        dateEnd: getFormatDDMMYYYY(payload.midYear_end),
        isActive: periods ? periods.isMidYearActive : payload.isMidYearActive,
      },
      {
        id: `${payload.endYear_start}_${payload.endYear_end}`,
        name: 'End year review',
        dateStart: getFormatDDMMYYYY(payload.endYear_start),
        dateEnd: getFormatDDMMYYYY(payload.endYear_end),
        isActive: periods ? periods.isEndYearActive : payload.isEndYearActive,
      },
    ];
  };

  useEffect(() => {
    getReviewDates();
    getDetailsInPeriod({ id: empId || profileData.id });
  }, []);

  useEffect(() => {
    if (detailsEmpData.response && reviewDates.response) {
      if (detailsEmpData.response.isStartYearActive
         || detailsEmpData.response.isMidYearActive || detailsEmpData.response.isEndYearActive) {
        setEmpReviewDates(converterRD(reviewDates.response, detailsEmpData.response));
      } else {
        setEmpReviewDates(converterRD(reviewDates.response));
      }
    }
  }, [detailsEmpData.response, reviewDates.response]);

  return (
    <YearCard title="Review dates" advText="PDP Guide" onLinkAdvText="/guidebook/dpd-guide">
      <div className={styles.wrapper}>
        {reviewDates.isLoading && <Loader />}
        {empReviewDates && empReviewDates.map(item => (
          <div
            key={item.id}
            className={classnames({
              [styles.item]: true,
              [styles.itemActive]: item.isActive,
            })}
          >
            <span className={styles.title}>{item.name}</span>
            <span className={styles.value}>{`${item.dateStart}—${item.dateEnd}`}</span>
          </div>
        ))}
      </div>
    </YearCard>
  );
};

export default ReviewDates;
