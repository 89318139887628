import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import YearCard from 'components/YearCard';
import FeedbackList from 'components/FeedbackList';
import Loader from 'components/Loader';

import { useEmpId, useDirectualApi } from 'shared/hooks';
import { IResponseGet } from 'shared/types/directual';

import { userStore } from 'mobx/stores';

import defaultAvatar from 'assets/img/defaultAvatar.png';

import { IRespFeedback } from './types';

const GiveFeedback: React.FC = observer(() => {
  const empId = useEmpId();

  const { user: { profileData } } = userStore;

  const converter = (data: IRespFeedback[]) => data.map(item => ({
    id: item.id,
    fullName: item.aboutEmployee_id.fullName,
    photoLink: item.aboutEmployee_id.photo_id
      ? item.aboutEmployee_id.photo_id.urlLink : defaultAvatar,
    isGreen: false,
    link: item.feedbackRequest_id.type === 'development'
      ? `/employee/developmentsAreas/${item.aboutEmployee_id.id}`
      : `/employee/businessObjectives/${item.aboutEmployee_id.id}`,
  }));

  const [feedbacks, getGiveFeedback] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespFeedback>) => converter(res.payload), []),
    configurator: { structure: 'feedbackAnswer', endpoint: 'getGiveFeedback' },
    getFullList: true,
  });

  useEffect(() => {
    const id = empId || profileData.id;
    getGiveFeedback({ authUser_id: id });
  }, [empId]);

  return (
    <YearCard title="Give feedback">
      {feedbacks.isLoading && <Loader />}
      <FeedbackList items={feedbacks.response || []} />
    </YearCard>
  );
});

export default GiveFeedback;
