/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { notify } from 'storybook-directual';

import CustomInput from 'components/CustomInput';
import CustomTextArea from 'components/CustomTextArea';
import CustomEditor from 'components/CustomEditor';
import CustomIconedSelect from 'components/CustomIconedSelect';
import ModalLayout from 'layouts/ModalLayout';
import { useBusinessObjective } from 'shared/hooks/directual';
import { IRespBO } from 'shared/hooks/directual/useBusinessObjective/types';
import { userStore, modalStore } from 'mobx/stores';
import { IFields, IPropsModal } from './types';
import styles from './AddBusinessObjective.module.scss';

const AddBusinessObjective: React.FC = observer(() => {
  const {
    user: { profileData },
    periods: {
      isEndYearActive, isMidYearActive, isStartYearActive,
    },
  } = userStore;

  const { modal, closeModal } = modalStore;
  const modalProps: IPropsModal = modal.props;

  const {
    statuses,
    getStatuses,
    businessObj,
    saveBusinessObj,
  } = useBusinessObjective();

  const [objective, setObjective] = useState<IRespBO>();
  const [titleLabel, setTitleLabel] = useState('Add business objective');

  useEffect(() => {
    getStatuses();
    if (modalProps && modalProps.data && modalProps.typeAction) {
      setObjective(modalProps.data);
      if (isMidYearActive) {
        setTitleLabel('Mid year review — business objectives');
      }
      if (isEndYearActive) {
        setTitleLabel('End-year review objective');
      }
    }
  }, []);

  const onSubmit = (values: Partial<IFields>) => {
    if (modalProps.typeAction === 'create') {
      saveBusinessObj({
        action: modalProps.typeAction,
        employee_id: profileData.id,
        kpi: values.kpi,
        readyStatus_id: values.readyStatus_id,
        businessObjective: values.businessObjective,
      });
    }
    if (modalProps.typeAction === 'edit' && isStartYearActive && objective) {
      saveBusinessObj({
        action: modalProps.typeAction,
        employee_id: modalProps.empId || profileData.id,
        readyStatus_id: values.readyStatus_id,
        businessObjective_id: objective!.id,
        kpi: values.kpi,
        businessObjective: values.businessObjective || objective!.businessObjective,
      });
    }
    if (modalProps.typeAction === 'edit' && isMidYearActive && objective) {
      saveBusinessObj({
        action: modalProps.typeAction,
        employee_id: modalProps.empId || profileData.id,
        readyStatus_id: values.readyStatus_id,
        businessObjective_id: objective!.id,
        kpi: values.kpi,
        businessObjective: values.businessObjective || objective!.businessObjective,
        midyearReview: values.review,
      });
    }
    if (modalProps.typeAction === 'edit' && isEndYearActive && objective) {
      saveBusinessObj({
        action: modalProps.typeAction,
        employee_id: modalProps.empId || profileData.id,
        readyStatus_id: values.readyStatus_id,
        businessObjective_id: objective!.id,
        kpi: values.kpi,
        businessObjective: values.businessObjective || objective!.businessObjective,
        endYearReview: values.review,
        midyearReview: objective.midyearReview,
      });
    }
  };

  const validate = (fields: Partial<IFields>) => {
    const errors: Partial<IFields> = {};

    if (modalProps.typeAction === 'create') {
      if (!fields.businessObjective) {
        errors.businessObjective = 'Enter the title';
      }
      if (!fields.kpi) {
        errors.kpi = 'Enter the KPIs / metrics';
      }
      if (!fields.readyStatus_id) {
        errors.readyStatus_id = 'Choose status';
      }
    }
    if (modalProps.typeAction === 'edit' && !isStartYearActive) {
      if (!fields.review) {
        errors.review = 'Enter the review';
      }
    }
    return errors;
  };

  useEffect(() => {
    const { response } = businessObj;
    if (response && response.result[0].isValidationOk) {
      modalProps.setWasSuccessAction();
      notify({ type: 'success', header: 'Info', message: `The business objective has been successfully ${objective ? 'edited' : 'created'}` });
      closeModal();
    } else if (response && !response.result[0].isValidationOk) {
      notify({ type: 'error', header: 'Error', message: 'Unknown error' });
    }
  }, [businessObj.isLoading]);

  const getReviewByPeriod = () => {
    if (objective) {
      if (isMidYearActive) {
        return objective.midyearReview;
      }
      if (isEndYearActive) {
        return objective.endYearReview;
      }
    }
    return '';
  };

  const getInitialKpi = () => {
    if (modalProps.data && modalProps.data.kpi_new && isStartYearActive) {
      return modalProps.data.kpi_new;
    }
    if (modalProps.data && modalProps.data.kpi_mid && isMidYearActive) {
      return modalProps.data.kpi_mid;
    }
    if (modalProps.data && modalProps.data.kpi_end && isEndYearActive) {
      return modalProps.data.kpi_end;
    }
    return '';
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{
        readyStatus_id: objective ? objective.readyStatus_id.id : undefined,
        review: getReviewByPeriod(),
        kpi: getInitialKpi(),
        businessObjective: modalProps.data && modalProps.data.businessObjective,
      }}
      render={({ handleSubmit }) => (
        <ModalLayout
          title={titleLabel}
          acceptAction={handleSubmit}
        >
          <form className={styles.form} onSubmit={handleSubmit}>
            {objective && modalProps.typeAction === 'edit' && (
              <div className={styles.busObjective}>
                <h2 className={styles.busObjectiveName}>
                  {objective.businessObjective}
                </h2>
                <div className={styles.busObjectiveDescr}>
                  <div dangerouslySetInnerHTML={{ __html: objective.kpi_new ? objective.kpi_new : '' }} />
                  <div dangerouslySetInnerHTML={{ __html: objective.kpi_mid ? objective.kpi_mid : '' }} />
                  <div dangerouslySetInnerHTML={{ __html: objective.kpi_end ? objective.kpi_end : '' }} />
                </div>
              </div>
            )}
            {(modalProps.typeAction === 'create' || (modalProps.data && modalProps.data.isNameEditable)) && (
              <Field
                name="businessObjective"
                label="Business objective"
                component={CustomInput}
                placeholder="Title..."
              />
            )}
            <Field
              name="kpi"
              label="Update KPIs / metrics if required"
              placeholder="Text..."
              component={CustomEditor}
              noToolbar
            />
            {modalProps.typeAction === 'edit' && !isStartYearActive && (
              <Field
                name="review"
                label={isMidYearActive ? 'Mid-year review (comments)' : 'End-year review (comments)'}
                component={CustomTextArea}
                rows={3}
                placeholder="Text..."
              />
            )}
            <Field
              name="readyStatus_id"
              label="Status"
              component={CustomIconedSelect}
              options={statuses.response || []}
              isLoading={statuses.isLoading}
            />
          </form>
        </ModalLayout>
      )}
    />
  );
});

export default AddBusinessObjective;
