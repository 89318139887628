import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { userStore } from 'mobx/stores';

import ModalRoot from 'components/ModalRoot';

// Page components
import LoginPage from '../pages/LoginPage';
import RecoveryEmailPage from '../pages/RecoveryEmailPage';
import RecoveryPasswordPage from '../pages/RecoveryPasswordPage';

import EmployeesPage from '../pages/EmployeesPage';
import RequestsPage from '../pages/RequestsPage';
import GuidebookPage from '../pages/GuidebookPage';
import GuidebookViewPage from '../pages/GuidebookViewPage';
import ProfilePage from '../pages/ProfilePage';
import MainPage from '../pages/MainPage';
import DevelopmentPlanPage from '../pages/DevelopmentPlanPage';

// Layout components
import HeaderLayout from '../layouts/HeaderLayout';
import SidebarLayout from '../layouts/SidebarLayout';

import { employeeId } from '../shared/utils/constants';

import { PrivateRoute, PublicRoute } from './routes';

const AppRouter: React.FC = observer(() => {
  const { user } = userStore;

  return (
    <Router>
      <Switch>
        <PublicRoute
          exact
          path="/login"
          component={LoginPage}
          isAuthenticated={user.isAuth}
        />

        <PublicRoute
          exact
          path="/recovery"
          component={RecoveryEmailPage}
          isAuthenticated={user.isAuth}
        />

        <PublicRoute
          exact
          path="/passwordRecovery/:hash"
          component={RecoveryPasswordPage}
          isAuthenticated={user.isAuth}
        />

        <PrivateRoute
          path="/"
          exact
          component={MainPage}
          layout={HeaderLayout}
          isAuthenticated={user.isAuth}
        />

        <PrivateRoute
          path={`/employee/:${employeeId}`}
          exact
          component={MainPage}
          layout={HeaderLayout}
          isAuthenticated={user.isAuth}
        />

        <PrivateRoute
          path="/employees"
          exact
          component={EmployeesPage}
          layout={HeaderLayout}
          isAuthenticated={user.isAuth}
        />

        <PrivateRoute
          path="/requests"
          exact
          component={RequestsPage}
          layout={HeaderLayout}
          isAuthenticated={user.isAuth && user.isHr}
        />

        <PrivateRoute
          path="/guidebook"
          exact
          component={GuidebookPage}
          layout={HeaderLayout}
          isAuthenticated={user.isAuth}
        />

        <PrivateRoute
          path="/guidebook/:id"
          exact
          component={GuidebookViewPage}
          layout={SidebarLayout}
          isAuthenticated={user.isAuth}
        />

        <PrivateRoute
          path="/profile"
          exact
          component={ProfilePage}
          layout={SidebarLayout}
          isAuthenticated={user.isAuth}
        />

        <PrivateRoute
          path={`/employee/profile/:${employeeId}`}
          exact
          component={ProfilePage}
          layout={SidebarLayout}
          isAuthenticated={user.isAuth}
        />

        <PrivateRoute
          path="/businessObjectives"
          exact
          component={DevelopmentPlanPage}
          layout={SidebarLayout}
          isAuthenticated={user.isAuth}
        />

        <PrivateRoute
          path={`/employee/businessObjectives/:${employeeId}`}
          exact
          component={DevelopmentPlanPage}
          layout={SidebarLayout}
          isAuthenticated={user.isAuth}
        />

        <PrivateRoute
          path="/developmentsAreas"
          exact
          component={DevelopmentPlanPage}
          layout={SidebarLayout}
          isAuthenticated={user.isAuth}
        />

        <PrivateRoute
          path={`/employee/developmentsAreas/:${employeeId}`}
          exact
          component={DevelopmentPlanPage}
          layout={SidebarLayout}
          isAuthenticated={user.isAuth}
        />

        <Route
          component={() => (<>Page not found</>)}
        />
      </Switch>
      <ModalRoot />
    </Router>
  );
});

export default AppRouter;
