import React from 'react';
import { Link } from 'react-router-dom';

import { IProps } from './types';

import styles from './GuidebookLink.module.scss';

const GuidebookLink: React.FC<IProps> = ({ typePage }) => {
  let link = '';
  let linkTitle = '';
  switch (typePage) {
    case 'business':
      link = '/guidebook/business-objectives';
      linkTitle = 'How to set SMART business objectives';
      break;
    case 'development':
      link = '/guidebook/development-areas';
      linkTitle = 'How to make a competency development plan';
      break;
    case 'feedback':
      link = '/guidebook/feedback-request';
      linkTitle = 'How to ask and provide feedback';
      break;
    case '360':
      link = '/guidebook/360-assessment';
      linkTitle = 'Guidebook';
      break;
    default:
      break;
  }

  return (
    <Link className={styles.link} to={link}>{linkTitle}</Link>
  );
};

export default GuidebookLink;
