import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import ChangePeriod from 'components/ChangePeriod';
import Loader from 'components/Loader';

import { observer } from 'mobx-react-lite';
import { sidebarStore, userStore } from 'mobx/stores';
import { useEmpId } from 'shared/hooks';
import { useEmployee } from 'shared/hooks/directual';

import ReviewDates from './ReviewDates';
import ProbationBusinessObjectives from './ProbationBusinessObjectives';
import ProbationDevelopmentsAreas from './ProbationDevelopmentsAreas';
import ReceiveFeedback from './ReceiveFeedback';
import GiveFeedback from './GiveFeedback';

import styles from './MainPage.module.scss';

const MainPage: React.FC = observer(() => {
  const { setData } = sidebarStore;
  const { user } = userStore;
  const empId = useEmpId();
  const { employeeData, getEmployeeData } = useEmployee();

  const [showPeriodBtn, setShowPeriodBtn] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (empId) {
      getEmployeeData({ id: empId });
    }
  }, [empId, getEmployeeData]);

  useEffect(() => {
    const { response } = employeeData;
    if (empId && response && !(user.isHr || response.supervisor_id.id === user.profileData.id)) {
      history.push('/');
    }
  }, [empId, employeeData]);

  useEffect(() => {
    const { search } = location;
    setShowPeriodBtn(search.includes('setPeriod'));
    setData({ backUrl: empId ? `/employee/${empId}` : '/' });
  }, [location, empId]);

  if (employeeData.isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.line}>
        <ReviewDates />
        <ReceiveFeedback />
        <GiveFeedback />
      </div>
      <div className={styles.line}>
        <ProbationBusinessObjectives />
        <ProbationDevelopmentsAreas />
      </div>
      {showPeriodBtn && <ChangePeriod />}
    </div>
  );
});

export default MainPage;
