import { IResponseSend } from 'shared/types/directual';

// eslint-disable-next-line import/prefer-default-export
export const convertResponse = (response: IResponseSend | null) => {
  let result = {
    status: false,
    msg: '',
  };

  if (response && response.status) {
    if (response.result[0].isValidationOk) {
      result.status = true;
    } else if (response.result[0].error_ids && !response.result[0].isValidationOk) {
      result = { status: false, msg: response.result[0].error_ids[0].description };
    }
  } else if (response && !response.status) {
    result.msg = response.msg || '';
  }

  return result;
};
