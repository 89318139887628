import { IProfileData, IUser } from './types';

// eslint-disable-next-line import/prefer-default-export
export const initProfileData: IProfileData = {
  id: '',
  company_id: { name: '', id: '' },
  photo_id: { uuid: '', urlLink: '' },
  email: '',
  fullName: '',
  supervisor_id: { fullName: '', id: '' },
  department_id: { name: '', id: '' },
  startWorkDate: 0,
  endWorkDate: 0,
  location_id: { name: '', id: '' },
  hobby: '',
  languages: '',
  buddy: '',
  hiringBoard: '',
  phone: '',
  workingHoursStart: '',
  workingHoursEnd: '',
  position: { name: '', id: '' },
  workingStatus_id: { name: 'probation', id: '' },
  profileStatus: 'default',
  BOmidYearAssesment: '',
  BOendYearAssesment: '',
  DAmidYearAssesment: '',
  DAendYearAssesment: '',
};

export const initUserData: IUser = {
  isAuth: false,
  token: '',
  username: '',
  role: 'employee',
  error: '',
  profileData: initProfileData,
  isEmployee: false,
  isHr: false,
};
