import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Field, Form } from 'react-final-form';
import { notify } from 'storybook-directual';

import ModalLayout from 'layouts/ModalLayout';

import CustomSelect from 'components/CustomSelect';
import CustomTextArea from 'components/CustomTextArea';

import { useDirectualApi } from 'shared/hooks';
import { IResponseGet, IResponseSend } from 'shared/types/directual';

import { userStore, modalStore } from 'mobx/stores';

import {
  IResponseData,
  IDataSelect,
  IPropsModal,
  IFields,
} from './types';

const PerformanceEvaluation: React.FC = observer(() => {
  const modalProps: IPropsModal = modalStore.modal.props;
  const { user: { profileData } } = userStore;
  const { closeModal } = modalStore;
  const { empData } = modalProps;

  const converterAssesment = (data: IResponseData[]): IDataSelect[] => data
    .map((item: IResponseData): IDataSelect => ({
      ...item,
      value: item.name,
      key: item.id,
    }));

  const [endYearAssesmentDict, getEndYearAssesmentDict] = useDirectualApi({
    type: 'getData',
    converter: useCallback(
      (res: IResponseGet<IResponseData>) => converterAssesment(res.payload), [],
    ),
    configurator: { structure: 'endYearAssesmentDict', endpoint: 'getEndYearAssesmentDict' },
  });

  const [resultSave, saveAssesment] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'actionInput', endpoint: 'saveAction' },
  });

  useEffect(() => {
    getEndYearAssesmentDict();
  }, []);

  useEffect(() => {
    const { response } = resultSave;
    const isSuccessSetData = response && response.result[0];

    if (isSuccessSetData) {
      if (modalProps.setWasSuccess) {
        modalProps.setWasSuccess();
      }
      notify({ type: 'success', header: 'Info', message: 'Success' });
      closeModal();
    } else if (response && response.msg) {
      notify({ type: 'error', header: 'Error', message: response.msg });
    }
  }, [resultSave.isLoading]);

  const onSubmit = (values: Partial<IFields>) => {
    saveAssesment({
      ...values,
      action: 'endYearAssesment',
      employee_id: modalProps.empId,
      initiator_id: profileData.id,
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        endYearAssesment_id: (empData && empData.endYearSupervisorAssesment_id && modalProps.typeAction === 'edit')
          ? empData.endYearSupervisorAssesment_id.id : '',
        assesment: (empData && modalProps.typeAction === 'edit') ? empData.endYearSupervisorAssesmentComment : '',
      }}
      render={({ handleSubmit }) => (
        <ModalLayout
          title="Employee's performance evaluation"
          acceptAction={handleSubmit}
          acceptLabel="Validate"
        >
          <form onSubmit={handleSubmit}>
            <Field
              name="endYearAssesment_id"
              label="Assessment"
              component={CustomSelect}
              options={endYearAssesmentDict.response || []}
              isLoading={endYearAssesmentDict.isLoading}
            />
            <Field
              name="assesment"
              label="Comment"
              placeholder="Сomment on the assessment"
              component={CustomTextArea}
            />
          </form>
        </ModalLayout>
      )}
    />
  );
});

export default PerformanceEvaluation;
