import { ICommentPayload } from './types';

// eslint-disable-next-line import/prefer-default-export
export const converterCommentList = (payload: ICommentPayload[]) => {
  const getList = (type: keyof ICommentPayload) => payload
    .filter(item => item[type])
    .map(item => item[type])
    .map((item, index) => ({
      id: `${item}${index}`,
      text: item,
    }));

  const continueList = getList('continueText');
  const startList = getList('startText');
  const stopList = getList('stopText');

  return { continueList, startList, stopList };
};
