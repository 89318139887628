import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { Field, Form } from 'react-final-form';
import useDirectualApi from 'shared/hooks/useDirectualApi';
import ModalLayout from 'layouts/ModalLayout';
import CustomSelect from 'components/CustomSelect';
import IndicatorCompetency from 'components/IndicatorCompetency';
import Loader from 'components/Loader';
import { notify } from 'storybook-directual';
import { IResponseSend } from 'shared/types/directual';
import { modalStore, userStore } from 'mobx/stores';
import { IRespComp } from 'pages/DevelopmentPlanPage/CompetencieList/types';
import CustomTextArea from 'components/CustomTextArea';
import { IResponseData, IOption } from 'pages/EmployeesPage/types';
import { observer } from 'mobx-react-lite';
import {
  FieldType, IFields, IIndicator, IIndicatorField, IPropsModal,
} from './types';
import styles from './AddDevelopmentsAreas.module.scss';

const AddDevelopmentsAreas: React.FC = observer(() => {
  const [activeCompetency, setActiveCompetency] = useState('');
  const [competencySelect, setCompetencySelect] = useState(0);
  const [filteredCompetencies, setFilteredCompetencies] = useState<IOption[]>();
  const [data, setData] = useState<IRespComp>();
  const [titleLabel, setTitleLabel] = useState('Add developments areas');
  const { modal, closeModal } = modalStore;
  const modalProps: IPropsModal = modal.props;

  const {
    user: { profileData },
    periods: {
      isStartYearActive, isMidYearActive, isEndYearActive,
    },
  } = userStore;

  const [indicators, getIndicator] = useDirectualApi<IIndicator[], null>({
    type: 'getData',
    converter: useCallback(res => res.payload, []),
    configurator: {
      structure: 'competencyIndicator',
      endpoint: 'getIndicatorByCompetency',
    },
  });


  const [allIndicators, getIndicators] = useDirectualApi<IIndicator[], null>({
    type: 'getData',
    converter: useCallback(res => res.payload, []),
    configurator: {
      structure: 'competencyIndicator',
      endpoint: 'getIndicators',
    },
  });

  const [competencies, getCompetency] = useDirectualApi<IOption[], null>({
    type: 'getData',
    converter: useCallback(res => res.payload.map((item: IResponseData): IOption => ({
      ...item,
      value: item.name,
      key: item.id,
    })), []),
    configurator: {
      structure: 'competency',
      endpoint: 'getCompetencyList',
    },
  });

  const getClassesForContainer = classnames({
    [styles.containerIndicators]: true,
    [styles.containerLoading]: indicators.isLoading,
  });

  const [request, saveDA] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: {
      structure: 'developmentAreaInput',
      endpoint: 'saveDAInput',
    },
  });

  useEffect(() => {
    getIndicators();
    getCompetency();
    if (modalProps && modalProps.data) {
      setData(modalProps.data);
      setActiveCompetency(modalProps.data.competency_id.id);
      if (isMidYearActive) {
        setTitleLabel('Mid-year review objective');
      }
      if (isEndYearActive) {
        setTitleLabel('End-year review objective');
      }
    }
  }, [modalProps]);

  useEffect(() => {
    if (allIndicators.response) {
      const indicatorsSet: any = new Set();
      modalProps.devAreas.forEach((indicator: any) => {
        indicatorsSet.add(indicator.competency_id.id);
      });
      if (competencies.response && modalProps && modalProps.data) {
        competencies.response.forEach((item, i) => {
          if (item.id === activeCompetency) {
            setCompetencySelect(i + 1);
          }
        });
        setFilteredCompetencies(competencies.response);
      }

      if (competencies.response && modalProps && !modalProps.data) {
        const finalCompetencies = competencies.response;
        const indicatorsArr = [...indicatorsSet];
        const filtered = finalCompetencies.filter(f => !indicatorsArr.includes(f.id));
        setFilteredCompetencies(filtered);
      }
    }
  }, [allIndicators.response, competencies.response]);

  const onSubmit = (values: IFields) => {
    if (indicators.response && competencies.response) {
      // Из всего списка индикаторов компетенции находим те, которые отметил пользователь
      const selectedIndicators: (FieldType)[] = Object.values(values)
        .filter((item: IIndicatorField | string | undefined) => {
          if (typeof (item) !== 'string' && item && item.status) {
            return item.status;
          }
          return null;
        });

      // Из всего списка компетенций, находим выбранную пользователем
      const findedItem: IOption | undefined = competencies.response
        .find((item: IOption) => (item.id === activeCompetency));

      const selectIndicatorForRequest = selectedIndicators
        .map((item: FieldType): string | null => {
          if (typeof (item) !== 'string' && item && item.indicatorId) {
            return item.indicatorId;
          }
          return null;
        });
      // Конвертируем выбранные индикаторы компетенции для запроса на сервер
      const selectIndicatorCommentsForRequest = {
        ...selectedIndicators.map((item: FieldType) => {
          if (typeof (item) !== 'string' && item && item.indicatorId) {
            return {
              [item.indicatorId]: item.text,
            };
          }
          return null;
        }),
      };

      const reviewForRequest = selectedIndicators.filter(item => item).reduce(
        (obj, item: any) => Object.assign(obj, { [item.indicatorId]: values[item.indicatorId] }),
        { review: values.review },
      );

      if (findedItem) {
        const selectedCompetency = findedItem.id;

        if (data && isStartYearActive) {
          saveDA({
            action: modalProps.typeAction,
            competency_id: selectedCompetency,
            competencyIndicator_ids: selectIndicatorForRequest,
            comments: selectIndicatorCommentsForRequest,
            employee_id: modalProps.empId || profileData.id,
            developmentArea_id: data.id,
          });
        }

        if (data && isMidYearActive) {
          saveDA({
            action: modalProps.typeAction,
            competency_id: selectedCompetency,
            competencyIndicator_ids: selectIndicatorForRequest,
            comments: selectIndicatorCommentsForRequest,
            employee_id: modalProps.empId || profileData.id,
            midYearAssesment: reviewForRequest,
            developmentArea_id: data.id,
          });
        }
        if (data && isEndYearActive) {
          saveDA({
            action: modalProps.typeAction,
            competency_id: selectedCompetency,
            competencyIndicator_ids: selectIndicatorForRequest,
            comments: selectIndicatorCommentsForRequest,
            employee_id: modalProps.empId || profileData.id,
            endYearAssesment: reviewForRequest,
            midYearAssesment: data.midYearAssesment,
            developmentArea_id: data.id,
          });
        }

        if (modalProps.typeAction === 'create' && !data) {
          saveDA({
            action: modalProps.typeAction,
            competency_id: selectedCompetency,
            competencyIndicator_ids: selectIndicatorForRequest,
            comments: selectIndicatorCommentsForRequest,
            employee_id: profileData.id,
          });
        }
      }
    }
  };

  const validate = (fields: Partial<IFields>) => {
    const errors: Partial<IFields> = {};

    if (modalProps.typeAction === 'create') {
      if (!fields.competency) {
        errors.competency = 'Enter the competency';
      }
    }
    if (modalProps.typeAction === 'edit' && !isStartYearActive) {
      if (!fields.review) {
        errors.review = 'Enter the review';
      }
    }
    return errors;
  };

  const handleChange = (value: string) => {
    setActiveCompetency(value);
    if (!data && activeCompetency === value) {
      return;
    }
    getIndicator({ competency_id: data ? activeCompetency : value });
  };

  useEffect(() => {
    const { response } = request;

    const isSuccessSetData = request.response
      && request.response.status && request.response.result[0]
      && request.response.result[0].isValidationOk;

    if (isSuccessSetData) {
      modalProps.setWasSuccessAction();
      notify({ type: 'success', header: 'Info', message: `Development areas successfully ${data ? 'edited' : 'added'}` });
      closeModal();
    } else if (response && response.msg) {
      notify({ type: 'error', header: 'Error', message: response.msg });
    }
  }, [request.isLoading]);

  const getFieldComment = (findItem?: { id: string; name: string }) => {
    let comment = '';
    if (data && findItem) {
      try {
        const parseComments = data.comments ? JSON.parse(data.comments) : {};
        Object.values(parseComments).forEach((c: any) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(c)) {
            if (findItem.id === key) {
              comment = value as string;
            }
          }
          return c;
        });
      } catch (e) {
        window.console.log(e);
      }
    }
    return comment;
  };

  const getFieldReview = (findItem?: { id: string; name: string }) => {
    if (data && findItem) {
      if (isMidYearActive) {
        const parseComments = data.midYearAssesment ? JSON.parse(data.midYearAssesment) : {};
        return parseComments[findItem.id];
      }
      const parseComments = data.endYearAssesment ? JSON.parse(data.endYearAssesment) : {};
      return parseComments[findItem.id];
    }
    return null;
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          competency: data ? data.competency_id.id : undefined,
          review: getFieldReview({ id: 'review', name: 'review' }),
        }}
        render={({ handleSubmit }) => (
          <ModalLayout
            title={titleLabel}
            acceptAction={handleSubmit}
          >
            <form onSubmit={handleSubmit}>
              <div className={styles.input}>
                <Field
                  name="competency"
                  label="Competency"
                  component={CustomSelect}
                  placeholder="Please choose from the list"
                  options={filteredCompetencies || []}
                  isLoading={competencies.isLoading}
                  inputOnChange={handleChange}
                  disabled={data}
                  initValue={competencySelect}
                />
                <div className={getClassesForContainer}>
                  {indicators.isLoading && <Loader className={styles.spinner} />}
                  {indicators.response && indicators.response.map(indicator => {
                    let findItem;
                    let comment = '';
                    let review = '';
                    if (data) {
                      findItem = data.competencyIndicator_ids
                        .find(item => item.id === indicator.id);
                      // сделать правку за 60 секунд
                      // TODO: рефакторить
                      comment = getFieldComment(findItem);
                      review = getFieldReview(findItem);
                    }
                    return (
                      <>
                        <Field
                          key={indicator.id}
                          name={indicator.name}
                          label={indicator.name}
                          indicatorId={indicator.id}
                          component={IndicatorCompetency}
                          initValue={(findItem && findItem.id) ? findItem.id : ''}
                          comment={comment}
                        />
                        {findItem && findItem.id && !isStartYearActive && (
                          <Field
                            name={findItem.id}
                            label={titleLabel}
                            component={CustomTextArea}
                            indicatorId={indicator.id}
                            rows={3}
                            placeholder="Text..."
                            initialValue={review}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
                {modalProps.typeAction === 'edit' && !isStartYearActive && (
                  <Field
                    name="review"
                    indicatorId="review"
                    label={titleLabel}
                    component={CustomTextArea}
                    rows={3}
                    placeholder="Text..."
                  />
                )}
              </div>
            </form>
          </ModalLayout>
        )}
      />
    </>
  );
});

export default AddDevelopmentsAreas;
