import { useCallback } from 'react';

import { IResponseGet, IResponseSend } from 'shared/types/directual';

import { convertToOption } from './converters';
import { IRespOption } from './types';

import useDirectualApi from '../../useDirectualApi';

const useCommon = () => {
  const [workingStatus, getWorkingStatus] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespOption>) => convertToOption(res.payload), []),
    configurator: { structure: 'workingStatus', endpoint: 'getWorkingStatus' },
  });

  const [positions, getPositions] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespOption>) => convertToOption(res.payload), []),
    configurator: { structure: 'position', endpoint: 'getPositions' },
    getFullList: true,
  });

  const [departments, getDepartments] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespOption>) => convertToOption(res.payload), []),
    configurator: { structure: 'department', endpoint: 'getDepartments' },
    getFullList: true,
  });

  const [companies, getCompanies] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespOption>) => convertToOption(res.payload), []),
    configurator: { structure: 'company', endpoint: 'getCompanies' },
    getFullList: true,
  });

  const [locations, getLocations] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespOption>) => convertToOption(res.payload), []),
    configurator: { structure: 'location', endpoint: 'getLocations' },
    getFullList: true,
  });

  const [changeStatus, setChangeStatus] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'changeStatus', endpoint: 'saveChangeStatus' },
  });

  /* eslint-disable object-property-newline */
  return {
    workingStatus, getWorkingStatus,
    positions, getPositions,
    departments, getDepartments,
    changeStatus, setChangeStatus,
    companies, getCompanies,
    locations, getLocations,
  };
  /* eslint-enable object-property-newline */
};

export default useCommon;
