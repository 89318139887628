import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { notify } from 'storybook-directual';
import { observer } from 'mobx-react-lite';

import ModalLayout from 'layouts/ModalLayout';

import CustomTextArea from 'components/CustomTextArea';
import CustomSelectCheckable from 'components/CustomSelectCheckable';

import { useDirectualApi } from 'shared/hooks';
import { useEmployee, useAssessment } from 'shared/hooks/directual';
import { IResponseSend } from 'shared/types/directual';

import { modalStore, userStore } from 'mobx/stores';

import { IFields, IFieldsError, IPropsModal } from './types';

import styles from './FeedbackRequest.module.scss';

const PLACEHOLDER_COMMON = 'Professional training course on development of management in professional services firms';

const FeedbackRequest: React.FC = observer(() => {
  const { closeModal, modal } = modalStore;
  const { user: { profileData } } = userStore;

  const modalProps: IPropsModal = modal.props;

  const { searchEmployees, getSearchEmployees } = useEmployee();
  const { saveAssessmentRes, saveAssessmentReq } = useAssessment();

  const validate = (values: IFields) => {
    const errors: Partial<IFieldsError> = {};
    if (!values.toEmployee_ids || (values.toEmployee_ids && values.toEmployee_ids.length <= 0)) {
      errors.toEmployee_ids = 'Select employees';
    }
    if (values.toEmployee_ids && modalProps.feedbackType === '360assesment' && values.toEmployee_ids.length <= 4) {
      errors.toEmployee_ids = 'Must be at least 5 employees';
    }
    if (modalProps.feedbackType !== '360assesment' && !values.comment) {
      errors.comment = 'Enter the comment';
    }
    return errors;
  };

  const [request, setRequest] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'feedbackRequest', endpoint: 'saveRequest' },
  });

  const onSubmit = (values: IFields) => {
    setRequest({
      ...values,
      lastEditor_id: profileData.id,
      aboutEmployee_id: profileData.id,
      action: 'create',
      type: modalProps.feedbackType,
    });
  };

  useEffect(() => {
    getSearchEmployees({ ignore_id: profileData.id });
  }, []);

  useEffect(() => {
    const { response } = request;
    if (response && response.status && response.result[0]) {
      if (modalProps.assessmentProps) {
        saveAssessmentReq(modalProps.assessmentProps);
      }
      if (!modalProps.assessmentProps && modalProps.setWasSuccessAction) {
        notify({ type: 'success', header: 'Info', message: 'Feedback request created successfully' });
        modalProps.setWasSuccessAction();
      }
      closeModal();
    } else if (response && response.msg) {
      notify({ type: 'error', header: 'Error', message: response.msg });
    }
  }, [request.isLoading]);

  useEffect(() => {
    const { response } = saveAssessmentRes;
    if (response && response.result[0] && response.result[0].id) {
      if (modalProps.setWasSuccessAction) {
        notify({ type: 'success', header: 'Info', message: 'Request approved successfully' });
        modalProps.setWasSuccessAction();
      }
    } else if (response
      && (!response.result[0] || (response.result[0] && !response.result[0].id))) {
      notify({ type: 'error', header: 'Error', message: 'Unknown error' });
    }
  }, [saveAssessmentRes]);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <ModalLayout
          title={modalProps.feedbackType === '360assesment' ? '360 Assessment request' : 'Feedback request'}
          acceptAction={handleSubmit}
          acceptLabel="Send request"
        >
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.input}>
              <Field
                name="toEmployee_ids"
                label="Feedback request"
                component={CustomSelectCheckable}
                options={searchEmployees ? searchEmployees.response : []}
              />
            </div>
            {modalProps.feedbackType !== '360assesment' && (
              <div className={styles.input}>
                <Field
                  name="comment"
                  label="Comment"
                  placeholder={PLACEHOLDER_COMMON}
                  component={CustomTextArea}
                />
              </div>
            )}
          </form>
        </ModalLayout>
      )}
    />
  );
});

export default FeedbackRequest;
