import { IRespOption } from './types';

// eslint-disable-next-line import/prefer-default-export
export const convertToOption = (data: IRespOption[]) => [...data].map(item => ({
  id: item.id,
  value: item.name || item.fullName,
  key: item.id,
  fullName: item.fullName,
  name: item.name,
}));
