import React, { useState, useEffect } from 'react';
import { Select } from 'storybook-directual';
import classnames from 'classnames';

import { IProps } from './types';

import styles from './CustomSelect.module.scss';

const CustomSelect: React.FC<IProps> = ({
  label, inputOnChange, input, description, options, disabled, initValue, ...rest
}) => {
  const { meta: { error, touched } } = rest;
  const [activeSelect, setActiveSelect] = useState<string>(input.value);

  const handleChange = (val: string) => {
    setActiveSelect(val);
    input.onChange(val);
    if (inputOnChange) {
      inputOnChange(val);
    }
  };

  useEffect(() => {
    if (input.value) {
      handleChange(input.value);
    }
  }, []);

  useEffect(() => {
    if (options.length && activeSelect === '' && initValue) {
      handleChange(options[initValue - 1].id);
    }
  }, [options]);

  return (
    <div className={classnames({
      [styles.customControl]: true,
      [styles.disabled]: disabled,
    })}
    >
      <div className={styles.title}>
        {label && <div className={styles.label}>{label}</div>}
        {(error && touched) && <span className={styles.error}>{error}</span>}
      </div>
      <Select
        {...input}
        {...rest}
        disabled={disabled}
        selectedOptionId={activeSelect}
        options={options}
        onChange={(value: string) => handleChange(value)}
      />
    </div>
  );
};

export default CustomSelect;
