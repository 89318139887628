import React from 'react';
import { observer } from 'mobx-react-lite';

import Header from 'components/Header';
import ProfileInfo from 'components/ProfileInfo';
import SidebarMenu from 'components/SidebarMenu';

import { sidebarStore } from 'mobx/stores';

import styles from './HeaderLayout.module.scss';

const HeaderLayout: React.FC = observer(({ children }) => {
  const { headerMenu } = sidebarStore;

  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <ProfileInfo />
          <SidebarMenu items={headerMenu} />
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
});

export default HeaderLayout;
