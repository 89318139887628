import React, { useState, useEffect } from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import { Moment } from 'moment';
import { observer } from 'mobx-react-lite';
import { notify } from 'storybook-directual';

import ModalLayout from 'layouts/ModalLayout';

import CustomSelect from 'components/CustomSelect';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput';

import { useEmployee, useCommon } from 'shared/hooks/directual';

import { userStore, modalStore } from 'mobx/stores';

import {
  Status,
  IFields,
  IErrors,
  IPropsModal,
} from './types';

import styles from './HREditProfileStatus.module.scss';

const validate = (values: IFields) => {
  const checkDifferent = (start: Moment | undefined, end: Moment | undefined) => (
    (start && end && (start > end)) ? 'End date is less than start date' : null
  );

  const errors: Partial<IErrors> = {};
  if (values.status_id) {
    if (values.status_id === 'probation') {
      if (!values.probationDateStart) {
        errors.probationDateStart = 'Enter start date';
      }
      if (!values.probationDateEnd) {
        errors.probationDateEnd = 'Enter end date';
      }
      const result = checkDifferent(values.probationDateStart, values.probationDateEnd);
      if (result) { errors.probationDateStart = result; }
    }

    if (values.status_id === 'staffing') {
      if (!values.employmentContractNumber) {
        errors.employmentContractNumber = 'Enter employment contract number';
      }
      if (!values.employmentDate) {
        errors.employmentDate = 'Enter date of employment';
      }
    }

    if (values.status_id === 'dismissal') {
      if (!values.dismissalCause) {
        errors.dismissalCause = 'Enter the reason for dismissal';
      }
      if (!values.dismissalDate) {
        errors.dismissalDate = 'Enter date';
      }
      if (!values.dismissalOrderNumber) {
        errors.dismissalDate = 'Enter the dismissal order number';
      }
    }
  } else {
    errors.status_id = 'Not selected status';
  }

  if (!values.position_id) {
    errors.position_id = 'Not selected position';
  }
  if (!values.department_id) {
    errors.department_id = 'Not selected department';
  }
  if (!values.supervisor_id) {
    errors.supervisor_id = 'Not selected supervisor';
  }

  return errors;
};

const HREditProfileStatus: React.FC = observer(() => {
  const { user: { profileData } } = userStore;
  const { modal, closeModal } = modalStore;
  const { employeeId, setWasSuccess } : IPropsModal = modal.props;

  const [status, setStatus] = useState<Status>('probation');

  const { supervisors, getSupervisors } = useEmployee();
  const {
    workingStatus,
    getWorkingStatus,
    positions,
    getPositions,
    departments,
    getDepartments,
    changeStatus,
    setChangeStatus,
  } = useCommon();

  useEffect(() => {
    getWorkingStatus();
    getPositions();
    getDepartments();
    getSupervisors();
  }, []);

  const onSubmit = (values: Partial<IFields>) => {
    setChangeStatus({
      ...values,
      employee_id: employeeId || profileData.id,
      initiator_id: profileData.id,
    });
  };

  useEffect(() => {
    const { response } = changeStatus;
    if (response && response.status) {
      if (response.result[0].isValidationOk === 'true') {
        setWasSuccess();
        closeModal();
        notify({ type: 'success', header: 'Info', message: 'Status has been successfully changed.' });
      } else if (response.result[0].error_ids) {
        notify({ type: 'error', header: 'Error', message: response.result[0].error_ids[0].description });
      }
    }
  }, [changeStatus.isLoading]);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <ModalLayout
          title="Change status"
          acceptAction={handleSubmit}
        >
          <form onSubmit={handleSubmit}>
            <div className={styles.input}>
              <Field
                name="status_id"
                label="Status"
                component={CustomSelect}
                options={workingStatus.response || []}
                isLoading={workingStatus.isLoading}
              />
            </div>
            {status === 'probation' && (
              <div className={styles.dateBlock}>
                <div className={styles.date}>
                  <Field
                    name="probationDateStart"
                    label="Date"
                    component={CustomDatePicker}
                  />
                </div>
                <div className={styles.date}>
                  <Field
                    name="probationDateEnd"
                    component={CustomDatePicker}
                  />
                </div>
              </div>
            )}
            {status === 'staffing' && (
              <div className={styles.dateInputBlock}>
                <div className={styles.input}>
                  <Field
                    name="employmentContractNumber"
                    label="Employment contract number"
                    component={CustomInput}
                  />
                </div>
                <div className={styles.date}>
                  <Field
                    name="employmentDate"
                    label="Date"
                    component={CustomDatePicker}
                  />
                </div>
              </div>
            )}
            {status === 'dismissal' && (
              <>
                <div className={styles.input}>
                  <Field
                    name="dismissalCause"
                    label="Cause"
                    component={CustomInput}
                  />
                </div>
                <div className={styles.dateInputBlock}>
                  <div className={styles.input}>
                    <Field
                      name="dismissalOrderNumber"
                      label="Order number"
                      component={CustomInput}
                    />
                  </div>
                  <div className={styles.date}>
                    <Field
                      name="dismissalDate"
                      label="Date"
                      component={CustomDatePicker}
                    />
                  </div>
                </div>
              </>
            )}
            {status === 'transfer' && (
              <div className={styles.dateInputBlock}>
                <div className={styles.input}>
                  <Field
                    name="orderNumber"
                    label="Order number"
                    component={CustomInput}
                  />
                </div>
                <div className={styles.date}>
                  <Field
                    name="orderDate"
                    label="Date"
                    component={CustomDatePicker}
                  />
                </div>
              </div>
            )}
            <div className={styles.input}>
              <Field
                name="position_id"
                label="Position"
                component={CustomSelect}
                options={positions.response || []}
                isLoading={positions.isLoading}
              />
            </div>
            <div className={styles.input}>
              <Field
                name="department_id"
                label="Department"
                component={CustomSelect}
                options={departments.response || []}
                isLoading={departments.isLoading}
              />
            </div>
            <div className={styles.input}>
              <Field
                name="supervisor_id"
                label="Supervisor"
                component={CustomSelect}
                options={supervisors.response || []}
                isLoading={supervisors.isLoading}
              />
            </div>
            <FormSpy
              subscription={{ values: true, valid: true }}
              onChange={(state: { valid: boolean, values: Partial<IFields> }) => {
                if (state.values && state.values.status_id) {
                  setStatus(state.values.status_id);
                }
              }}
            />
          </form>
        </ModalLayout>
      )}
    />
  );
});

export default HREditProfileStatus;
