export const SESSION_ID = 'sessionid';

export const STATUS = {
  ERROR: 'ERROR',
  OK: 'OK',
};

export const LStorage = {
  token: 'token',
  userId: 'userId',
  userRole: 'userRole',
  profileData: 'employeeProfileData',
  empData: 'empData',
  currentPeriod: 'currentPeriod',
};

export const employeeId = 'employeeId';
