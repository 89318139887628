import React, { useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

import { useAssessment } from 'shared/hooks/directual';

import { userStore } from 'mobx/stores';

import { IProps } from './types';

import styles from './InputNumber.module.scss';

const InputNumber: React.FC<IProps> = observer(({
  toFill360Id,
  min = 0,
  max = 6,
  label = '',
  avgEmployee = '',
  avgCompany = '',
  initialValue = '',
  setNewValue,
  disabled,
}) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');

  const { save360answerReq } = useAssessment();

  const { user: { profileData } } = userStore;

  const onChange = (val: string) => {
    const valNumber = Number(val);
    if (!val.length || ((valNumber || valNumber === 0) && val.length < 3)) {
      setValue(val);

      if (val === '') {
        setNewValue({ id: toFill360Id, value: '' });
      }

      if ((valNumber >= min) && (valNumber <= max)) {
        setError('');
        setNewValue({ id: toFill360Id, value: val });
      } else {
        setError('Incorrect number');
        setNewValue({ id: toFill360Id, value: '' });
      }
    }
  };

  const onBlur = () => {
    const valNumber = Number(value);

    if ((valNumber || valNumber === 0) && !error && value !== '') {
      setNewValue({ id: toFill360Id, value });
      save360answerReq({
        id: toFill360Id,
        initiator_id: profileData.id,
        assesment: value,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.inputBlock}>
        <div className={styles.input}>
          <input
            className={classnames({ [styles.error]: error, [styles.disabled]: disabled })}
            type="text"
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            placeholder="0–6"
            onBlur={() => onBlur()}
            disabled={disabled}
          />
        </div>
        {avgEmployee && (
          <div className={styles.input}>
            <input
              className={styles.disabled}
              type="text"
              value={avgEmployee}
              disabled
            />
          </div>
        )}
        {avgCompany && (
          <div className={styles.input}>
            <input
              className={styles.disabled}
              type="text"
              value={avgCompany}
              disabled
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default InputNumber;
