import React from 'react';
import classnames from 'classnames';

import { IProps } from './types';

import styles from './StatusInfo.module.scss';

const StatusInfo: React.FC<IProps> = ({ text, color = '', status = '' }) => (
  <div className={styles.wrapper}>
    <div
      style={color ? { backgroundColor: color } : {}}
      className={classnames({
        [styles.status]: true,
        [styles.blue]: status === 'blue',
        [styles.green]: status === 'green',
      })}
    />
    <span>{text}</span>
  </div>
);

export default StatusInfo;
