import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import Loader from 'components/Loader';
import FeedbackRequestCard from 'components/FeedbackRequestCard';
import { IRequestList } from 'components/FeedbackRequestCard/types';

import useDirectualApi from 'shared/hooks/useDirectualApi';
import { useEmployee } from 'shared/hooks/directual';

import { userStore } from 'mobx/stores';

import styles from './RequestsPage.module.scss';

const RequestsPage: React.FC = observer(() => {
  const { user: { profileData } } = userStore;

  const { searchEmployees, getSearchEmployees } = useEmployee();

  const [requests, getRequests] = useDirectualApi({
    type: 'getData',
    converter: useCallback(res => res.payload.reverse(), []),
    configurator: { structure: 'feedbackRequest', endpoint: 'getFeedbackRequests' },
  });

  useEffect(() => {
    getRequests();
    getSearchEmployees({ ignore_id: profileData.id });
  }, []);

  return (
    <>
      <div className={styles.title}>Feedback request</div>
      <div className={styles.list}>
        {requests.response && requests.response.map((req: IRequestList) => (
          <FeedbackRequestCard
            key={req.id}
            requestId={req.id}
            employee={req.aboutEmployee_id}
            childs={req.toEmployee_ids}
            employeeList={searchEmployees ? searchEmployees.response : []}
          />
        ))}
        {requests.isLoading && <Loader />}
        {(requests.response && requests.response.length <= 0) && <div>No requests</div>}
      </div>
    </>
  );
});

export default RequestsPage;
