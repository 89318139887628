import React, {
  Fragment, useEffect, useState, useMemo,
} from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';

import { useEmpId } from 'shared/hooks';
import { useAssessment } from 'shared/hooks/directual';

import { modalStore, userStore } from 'mobx/stores';

import { IPropsModal as IAssement360Props } from 'components/ModalRoot/Modals/Assessment360/types';

import { IProps, ILocation } from './types';

import styles from './SidebarMenu.module.scss';

const SidebarMenu: React.FC<IProps> = observer(({ items }) => {
  const [catch360Id, setCatch360Id] = useState(false);

  const routerMatch = useRouteMatch();
  const empId = useEmpId();
  const {
    toFill360, getToFill360, reviewDates, getReviewDates,
  } = useAssessment();

  const { showModal, modal } = modalStore;
  const { user, periods } = userStore;

  const checkActive = (location: ILocation, currentNavLink: string) => {
    if (!location) return false;
    const { pathname } = location;
    const { url } = routerMatch;
    const employeeLink = `/employee${currentNavLink}/${empId}`;

    return (currentNavLink === pathname) || (url === employeeLink);
  };

  useEffect(() => {
    getReviewDates();
  }, [getReviewDates]);

  const is360Active = useMemo(
    () => reviewDates.response && reviewDates.response.is360Active, [reviewDates.response],
  );

  const can360Filled = useMemo(
    () => catch360Id && (periods.isEndYearActive || is360Active),
    [catch360Id, is360Active, periods.isEndYearActive],
  );

  useEffect(() => {
    if (empId && user.profileData.supervisor_id.id !== empId && modal.type !== 'noModal') {
      return;
    }
    getToFill360({
      aboutEmployee_id: empId || user.profileData.id,
      authEmployee_id: user.profileData.id,
    });
  }, [empId, modal.type]);

  useEffect(() => {
    const { response } = toFill360;
    if (response && response.id) {
      setCatch360Id(true);
    } else if (!response || (response && !response.id)) {
      setCatch360Id(false);
    }
  }, [toFill360.isLoading]);

  const on360Click = () => {
    const { response } = toFill360;

    if (catch360Id && response && response.id) {
      showModal<IAssement360Props>('assessment360', { empId, ...response });
    }
  };

  return (
    <div>
      {items.map(item => (
        <Fragment key={`${item.link}_${item.label}`}>
          {(item.modal === 'assessment360') && (
          <div
            role="presentation"
            className={classnames({
              [styles.menuItem]: true,
              [styles.disabled]: !can360Filled,
            })}
            onClick={() => can360Filled && on360Click()}
          >
            {item.label}
          </div>
          )}
          {!item.modal && (
          <>
            <NavLink
              className={styles.link}
              isActive={(match, location) => checkActive(location, item.link)}
              activeClassName={styles.linkActive}
              to={item.link}
            >
              <div className={styles.menuItem}>
                {item.label}
              </div>
            </NavLink>
            {item.divider && <div className={styles.divider} />}
          </>
          )}
        </Fragment>
      ))}
    </div>
  );
});

export default SidebarMenu;
