import CompetencyGuideEdit from './CompetencyGuideEdit';
import HREditProfileStatus from './HREditProfileStatus';
import FeedbackRequest from './FeedbackRequest';
import EmployeeForm from './EmployeeForm';
import AddBusinessObjective from './AddBusinessObjective';
import AddDevelopmentsAreas from './AddDevelopmentsAreas';
import ReturnForRevision from './ReturnForRevision';
import Assessment360 from './Assessment360';
import PerformanceEvaluation from './PerformanceEvaluation';
import NoModal from './NoModal';

const modalTypes = {
  EmployeeForm,
  CompetencyGuideEdit,
  HREditProfileStatus,
  FeedbackRequest,
  AddBusinessObjective,
  AddDevelopmentsAreas,
  ReturnForRevision,
  Assessment360,
  PerformanceEvaluation,
  noModal: NoModal,
};

export default modalTypes;
