import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { notify } from 'storybook-directual';
import { observer } from 'mobx-react-lite';
import { useEmpId } from 'shared/hooks';

import CustomTextArea from 'components/CustomTextArea';

import { IResponseSend } from 'shared/types/directual';
import useDirectualApi from 'shared/hooks/useDirectualApi';

import { userStore } from 'mobx/stores';

import actionTypes from '../types';
import { IProps } from './types';

import styles from './Assesment.module.scss';

const Assesment: React.FC<IProps> = observer(({
  isMidYearActive,
  isEndYearActive,
  midReview,
  endReview,
  typeAction,
  isNoEdit,
  handlerSubmit,
}) => {
  const [editMidYear, changeEditMidYear] = useState(false);
  const [editEndYear, changeEditEndYear] = useState(false);

  const empId = useEmpId();

  const {
    setReview: setReviewMobx,
    user: { profileData: { id, profileStatus } },
  } = userStore;

  const [result, saveAssesment] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'actionInput', endpoint: 'saveAction' },
  });

  useEffect(() => {
    if (isMidYearActive && !midReview) {
      changeEditMidYear(true);
    }
    if (isEndYearActive && !endReview) {
      changeEditEndYear(true);
    }
  });

  const changeStatus = (value: boolean) => {
    changeEditMidYear(value);
    changeEditEndYear(value);
  };

  const onSubmit = (values: any) => {
    setReviewMobx(typeAction, values.assesment);

    saveAssesment({
      assesment: values.assesment,
      action: typeAction,
      employee_id: id,
      initiator_id: id,
    });
    changeStatus(false);
  };

  useEffect(() => {
    const { response } = result;

    const isSuccessSetData = result.response && result.response.result[0];

    if (isSuccessSetData) {
      notify({ type: 'success', header: 'Info', message: `${isMidYearActive ? 'Mid' : 'End'} review objective successfully added` });
      handlerSubmit(true);
    } else if (response && response.msg) {
      notify({ type: 'error', header: 'Error', message: response.msg });
    }
  }, [result.isLoading]);

  const getContent = () => {
    switch (typeAction) {
      case actionTypes.DAmidYearAssesment: return midReview;
      case actionTypes.BOmidYearAssesment: return midReview;
      case actionTypes.DAendYearAssesment: return endReview;
      case actionTypes.BOendYearAssesment: return endReview;
      default: return '';
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          assesment: getContent(),
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.containerAssesments}>
              {(isMidYearActive || isEndYearActive || isNoEdit)
              && midReview && (!editMidYear || !editEndYear)
              && (
              <div>
                <h3>Mid-year employee’s self-assessment</h3>
                <p>{midReview}</p>
                {isMidYearActive && !isNoEdit && (profileStatus === 'default' || profileStatus === 'rejected')
                && (
                <button
                  className={styles.submit}
                  type="button"
                  onClick={() => changeStatus(true)}
                >
                  Edit
                </button>
                )}
              </div>
              )}
              {(isEndYearActive || isNoEdit) && endReview && !editEndYear && (
                <div>
                  <h3>End-year employee’s self-assessment</h3>
                  <p>{endReview}</p>
                  {isEndYearActive && !isNoEdit && (profileStatus === 'default' || profileStatus === 'rejected') && (
                    <button
                      className={styles.submit}
                      type="button"
                      onClick={() => changeEditEndYear(true)}
                    >
                      Edit
                    </button>
                  )}
                </div>
              )}
              {((editMidYear || editEndYear) && (profileStatus === 'default' || profileStatus === 'rejected')) && !empId && (
              <div>
                <Field
                  name="assesment"
                  label={`${isMidYearActive ? 'Mid' : 'End'}-year employee’s self-assessment`}
                  placeholder="Make a summary of your business achievements and personal development progress"
                  rows={3}
                  component={CustomTextArea}
                />
                <button
                  className={styles.submit}
                  type="submit"
                >
                  Save
                </button>
              </div>
              )}
            </div>
          </form>
        )}
      />
    </>
  );
});

export default Assesment;
