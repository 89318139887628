import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import YearCard from 'components/YearCard';
import FeedbackList from 'components/FeedbackList';
import Loader from 'components/Loader';

import { useEmpId, useDirectualApi } from 'shared/hooks';
import { IResponseGet } from 'shared/types/directual';

import { userStore } from 'mobx/stores';

import defaultAvatar from 'assets/img/defaultAvatar.png';

import { IResFeedback } from './types';

const ReceiveFeedback: React.FC = observer(() => {
  const empId = useEmpId();

  const { user: { profileData } } = userStore;

  const converter = (data: IResFeedback[]) => data.map(item => ({
    id: item.id,
    fullName: item.initiator_id.fullName,
    photoLink: item.initiator_id.photo_id ? item.initiator_id.photo_id.urlLink : defaultAvatar,
    isGreen: !!item.sent,
  }));

  const [feedbacks, getExpectedFeedback] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IResFeedback>) => converter(res.payload), []),
    configurator: { structure: 'feedbackAnswer', endpoint: 'getExpectedFeedback' },
    getFullList: true,
  });

  useEffect(() => {
    getExpectedFeedback({ authUser_id: empId || profileData.id });
  }, [empId]);

  return (
    <YearCard title="Receive Feedback">
      {feedbacks.isLoading && <Loader />}
      <FeedbackList
        items={feedbacks.response || []}
        isLoading={feedbacks.isLoading}
      />
    </YearCard>
  );
});

export default ReceiveFeedback;
