import React, { useState, useEffect, useRef } from 'react';
import { Icon } from 'storybook-directual';
import classnames from 'classnames';

import ColorCircle from 'components/ColorCircle';
import Loader from 'components/Loader';

import { IProps } from './types';

import styles from './CustomIconedSelect.module.scss';

const CustomIconedSelect: React.FC<IProps> = ({
  label, input, description, options, isLoading, ...rest
}) => {
  const { meta: { error, touched } } = rest;
  const [activeSelect, setActiveSelect] = useState('');
  const [isCollapse, setIsCollapse] = useState(true);

  const listRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);

  const handleChange = (val: string) => {
    setActiveSelect(val);
    input.onChange(val);
    setIsCollapse(true);
  };

  useEffect(() => {
    setActiveSelect(input.value);
  }, [input.value]);

  const handleClick = (e: MouseEvent) => {
    if (btnRef.current && listRef.current && e.target instanceof Element) {
      if (!btnRef.current.contains(e.target) && !listRef.current.contains(e.target)) {
        setIsCollapse(!isCollapse);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  });

  const activeItem = options.find(item => item.id === activeSelect);

  return (
    <div className={styles.customControl}>
      <div className={styles.title}>
        {label && <div className={styles.label}>{label}</div>}
        {(error && touched) && <span className={styles.error}>{error}</span>}
      </div>

      <div
        ref={btnRef}
        className={classnames({ [styles.select]: true, [styles.selectActive]: !isCollapse })}
        role="presentation"
        onClick={() => setIsCollapse(!isCollapse)}
      >
        <div className={styles.selectLabel}>
          <span className={styles.circle}>
            {(activeItem && activeItem.color) && <ColorCircle color={activeItem.color} />}
          </span>
          <span>{activeItem && activeItem.name}</span>
        </div>
        <div className={styles.collapseIcon}>
          <Icon type={isCollapse ? 'down' : 'up'} />
        </div>
      </div>

      {!isCollapse && (
        <div ref={listRef} className={styles.list}>
          {isLoading && <Loader />}
          {options.map(item => (
            <div
              key={item.id}
              className={classnames({
                [styles.listItem]: true,
                [styles.listItemActive]: activeItem && activeItem.id === item.id,
              })}
              role="presentation"
              onClick={() => { handleChange(item.id); }}
            >
              <span className={styles.circle}>
                {item.color && <ColorCircle color={item.color} /> }
              </span>
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomIconedSelect;
