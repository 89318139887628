import React from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';

import { useEmpId } from 'shared/hooks';
import { getFormatDDMMYYYY } from 'shared/utils/time';

import { userStore, modalStore } from 'mobx/stores';

import { IPropsModal as IPerformanceProps } from 'components/ModalRoot/Modals/PerformanceEvaluation/types';

import defaultAvatar from 'assets/img/defaultAvatar.png';

import { IProps } from './types';

import styles from './ManagerAssessment.module.scss';

const ManagerAssessment: React.FC<IProps> = observer(({ empData, setWasSuccessAction }) => {
  const {
    user,
    user: { profileData },
    periods,
  } = userStore;
  const { showModal } = modalStore;

  const empId = useEmpId();

  const userData = empData || profileData;

  if (empId && user.isEmployee && userData.supervisor_id.id !== profileData.id) {
    return null;
  }

  if (!empId && userData.id !== profileData.id) {
    return null;
  }

  if (!userData.endYearSupervisorAssesmentComment) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{'Manager\'s assessment'}</div>
      <div className={styles.content}>
        <div>
          <img
            className={styles.avatar}
            src={userData.supervisor_id && userData.supervisor_id.photo_id
              ? userData.supervisor_id.photo_id.urlLink : defaultAvatar}
            alt="ava"
          />
        </div>
        <div className={styles.innerContent}>
          <div className={styles.date}>
            {userData.endYearSupervisorAssesmentDate
              ? getFormatDDMMYYYY(userData.endYearSupervisorAssesmentDate) : null}
          </div>
          <div className={styles.labelBlock}>
            <div className={styles.textGray}>{userData.supervisor_id.fullName}</div>
            <div className={styles.textBlack}>
              {userData.supervisor_id.position && userData.supervisor_id.position.name}
            </div>
          </div>
          <div className={styles.text}>{userData.endYearSupervisorAssesmentComment}</div>
          <div className={classnames(styles.labelBlock, styles.assessmentBlock)}>
            <div className={styles.textBlack}>Performance evaluation:</div>
            <div className={styles.textGray}>
              {userData.endYearSupervisorAssesment_id
                && userData.endYearSupervisorAssesment_id.name}
            </div>
          </div>
          {(empId && periods.isEndYearActive && (user.isHr
          || (userData.supervisor_id.id === profileData.id))) && (
            <div
              className={styles.link}
              role="presentation"
              onClick={() => showModal<IPerformanceProps>('performanceEvaluation', {
                typeAction: 'edit',
                empId: empId!,
                empData: empId ? empData : null,
                setWasSuccess: () => setWasSuccessAction && setWasSuccessAction(),
              })}
            >
              Edit
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default ManagerAssessment;
