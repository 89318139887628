import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { notify, Icon } from 'storybook-directual';
import classnames from 'classnames';

import Button from 'components/Button';
import Loader from 'components/Loader';

import useDirectualApi from 'shared/hooks/useDirectualApi';
import { IResponseSend } from 'shared/types/directual';

import { userStore } from 'mobx/stores';

import { IProps } from './types';

import styles from './SendForApproval.module.scss';

const SendForApproval: React.FC<IProps> = observer(({ employeeId }) => {
  const { user: { profileData }, setProfileStatus } = userStore;

  const [approve, sendToApprove] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'actionInput', endpoint: 'saveAction' },
  });

  const onClick = () => {
    if (profileData.canSendForApprovalNewYear) {
      sendToApprove({
        employee_id: employeeId || profileData.id,
        initiator_id: profileData.id,
        action: 'sendToApprove',
      });
    }
  };

  useEffect(() => {
    const { response } = approve;

    if (response && response.result[0].id) {
      notify({
        type: 'success',
        header: 'Info',
        message: 'Request sent successfully',
      });
      setProfileStatus('inProcess');
    } else if (response && (!response.result || !response.result[0].id)) {
      notify({
        type: 'error',
        header: 'Error',
        message: 'Unknown error',
      });
    }
  }, [approve.isLoading]);

  const disabledButton = profileData.canSendForApprovalNewYear
  && ((employeeId && profileData.profileStatus === 'inProcess')
  || (!employeeId && profileData.profileStatus && (profileData.profileStatus === 'default' || profileData.profileStatus === 'rejected')));

  let btnText = 'Send for approval';
  let icon = null;
  if (profileData.profileStatus === 'approved') {
    btnText = 'Approved';
    icon = <Icon type="done" />;
  } else if (profileData.profileStatus === 'inProcess') {
    btnText = 'On approval';
    icon = <Icon type="clocks" />;
  }

  return (
    <Button
      className={classnames({
        [styles.action]: true,
        [styles.opactity]: profileData.profileStatus === 'approved' || profileData.profileStatus === 'inProcess',
      })}
      label={btnText}
      type="action"
      disabled={!disabledButton}
      onClick={() => onClick()}
      icon={approve.isLoading ? <Loader /> : icon}
    />
  );
});

export default SendForApproval;
