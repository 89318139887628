import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { notify } from 'storybook-directual';

import CustomInput from 'components/CustomInput';
import Button from 'components/Button';

import useDirectualApi from 'shared/hooks/useDirectualApi';

import { IResponseSend } from 'shared/types/directual';
import { IFields } from './types';

import styles from './RecoveryEmailPage.module.scss';

const validate = (values: IFields) => {
  const errors: Partial<IFields> = {};
  if (!values.email) {
    errors.email = 'Enter the email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

const RecoveryEmailPage: React.FC = () => {
  const [email, setEmail] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: {
      structure: 'loginInput',
      endpoint: 'saveLoginInput',
    },
  });

  const onSubmit = async (data: IFields) => {
    setEmail({ userEmail: data.email });
  };

  useEffect(() => {
    const { response } = email;

    if (response && response.result[0].isValidationOk) {
      notify({ type: 'success', header: 'Info', message: 'The email was sent successfully' });
    } else if (response && !response.result[0].isValidationOk) {
      notify({ type: 'error', header: 'Error', message: 'Something is wrong' });
    }
  }, [email.isLoading]);

  return (
    <div className={styles.container}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <h2>Recovery password</h2>
            <div className={styles.input}>
              <Field
                name="email"
                label="Email"
                component={CustomInput}
              />
            </div>
            <div className={styles.btnBlock}>
              <Button label="Send" btnType="submit" type="action" />
              <Link to="/login">
                <Button label="Back" type="default" />
              </Link>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default RecoveryEmailPage;
