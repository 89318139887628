import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { notify } from 'storybook-directual';

import ModalLayout from 'layouts/ModalLayout';

import CustomInput from 'components/CustomInput';
import CustomEditor from 'components/CustomEditor';

import useDirectualApi from 'shared/hooks/useDirectualApi';
import { IResponseSend } from 'shared/types/directual';

import { modalStore } from 'mobx/stores';

import { IFileds } from './types';

import styles from './CompetencyGuideEdit.module.scss';

const validate = (values: IFileds) => {
  const errors: Partial<IFileds> = {};
  if (!values.name) {
    errors.name = 'Enter the title';
  }
  if (!values.content) {
    errors.content = 'Enter the text';
  }

  return errors;
};

const CompetencyGuideEdit: React.FC = observer(() => {
  const { modal: { props }, closeModal } = modalStore;

  const [result, setGuidebook] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: {
      structure: 'guidebook',
      endpoint: 'saveGuidebook',
    },
  });

  const onSubmit = (values: IFileds) => {
    setGuidebook({
      id: props && props.guidebook.id,
      isDeleted: false,
      ...values,
    });
  };

  useEffect(() => {
    const { response } = result;
    if (response && response.status && !response.msg) {
      notify({ type: 'success', header: 'Info', message: 'Data changed successfully' });
      closeModal();
    } else if (response && response.msg) {
      notify({ type: 'error', header: 'Error', message: response.msg });
    }
  }, [result.isLoading]);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{
        name: props ? props.guidebook.name : '',
        content: props ? props.guidebook.content : '',
      }}
      render={({ handleSubmit }) => (
        <ModalLayout
          title="Edit"
          acceptAction={handleSubmit}
        >
          <form onSubmit={handleSubmit}>
            <div className={styles.input}>
              <Field
                name="name"
                label="Title"
                type="text"
                component={CustomInput}
              />
            </div>
            <div className={styles.input}>
              <Field
                name="content"
                label="Text"
                component={CustomEditor}
              />
            </div>
          </form>
        </ModalLayout>
      )}
    />
  );
});

export default CompetencyGuideEdit;
