import React, { useState, useCallback, useEffect } from 'react';
import { api } from 'directual';
import classnames from 'classnames';
import { FileUpload } from 'storybook-directual';
import { File } from 'storybook-directual/dist/modules/upload/types.d';

import { IUploadedImage, IProps } from './types';

import styles from './CustomFileUpload.module.scss';

const CustomFileUpload: React.FC<IProps> = ({
  label, input, description, disabled, ...rest
}) => {
  const { meta: { error, touched } } = rest;
  const [currentFiles, setFiles] = useState<File[]>(input.value || []);

  const handlerDropping = (uploaded: any) => {
    setFiles(prevState => [
      ...prevState.slice(0, currentFiles.length),
      { id: uploaded.name, status: 'loading', name: uploaded.name },
      ...prevState.slice(currentFiles.length),
    ]);

    const imageFile = ((api.fileUpload<IUploadedImage>(uploaded)) as any);
    imageFile.then((res: IUploadedImage) => {
      setFiles(prevState => prevState.map((item, index) => {
        if (index !== prevState.length - 1) {
          return item;
        }
        return { id: res.result.id, name: res.result.finalFileName, status: 'loaded' };
      }));
    });
  };

  const handlerCancel = useCallback((index: string) => {
    setFiles(prevState => [
      ...prevState.filter(item => item.id !== index),
    ]);
  }, []);

  useEffect(() => {
    input.onChange(currentFiles);
  }, [currentFiles]);

  const onDisabled = (e: React.SyntheticEvent<HTMLDivElement>) => {
    if (disabled) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <>
      <div className={styles.customControl}>
        <div className={styles.title}>
          {label && <div className={styles.label}>{label}</div>}
          {(error && touched) && <span className={styles.error}>{error}</span>}
        </div>
        <div className={classnames({ [styles.disabled]: disabled })} role="presentation" onClick={onDisabled}>
          <FileUpload
            {...input}
            {...rest}
            multiple
            accept={['.jpg', '.jpeg', '.png', '.webp']}
            onFilesPick={(files: FileList) => handlerDropping(files[0])}
            files={currentFiles}
            onCancel={(arg: string) => handlerCancel(arg)}
          />
        </div>
      </div>
    </>
  );
};

export default CustomFileUpload;
