/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import CollapsePanel from 'components/CollapsePanel';
import Loader from 'components/Loader';
import StatusInfo from 'components/StatusInfo';
import RemoveBusinessObjective from 'components/RemoveBusinessObjective';
import { userStore, modalStore } from 'mobx/stores';
import { IPropsModal } from 'components/ModalRoot/Modals/AddBusinessObjective/types';
import { useEmpId } from 'shared/hooks';
import { IProps } from './types';
import styles from './GoalList.module.scss';

const GoalList: React.FC<IProps> = observer(({
  setWasSuccessAction,
  businessObjectives,
  isLoading,
}) => {
  const [wasDeletedIds, setWasDeletedIds] = useState<string[]>([]);

  const {
    user: { profileData: { profileStatus } },
    periods: {
      isEndYearActive, isMidYearActive, isNoEdit, isStartYearActive,
    },
  } = userStore;

  const { showModal } = modalStore;

  const empId = useEmpId();

  return (
    <div className={styles.items}>
      {isLoading && <Loader />}
      {(!isLoading && businessObjectives && (businessObjectives.length <= 0)) && (
        <div>No data on business objectives</div>
      )}
      {businessObjectives && businessObjectives.map(item => {
        if (wasDeletedIds.find(id => id === item.id)) {
          return null;
        }

        return (
          <CollapsePanel
            key={item.id}
            title={item.businessObjective}
            rightContent={
              <StatusInfo color={item.readyStatus_id.color} text={item.readyStatus_id.name} />
            }
          >
            <div dangerouslySetInnerHTML={{ __html: item.kpi_new ? item.kpi_new : '' }} />
            <div dangerouslySetInnerHTML={{ __html: item.kpi_mid ? item.kpi_mid : '' }} />
            <div dangerouslySetInnerHTML={{ __html: item.kpi_end ? item.kpi_end : '' }} />
            {((isEndYearActive || isMidYearActive || isNoEdit) && item.midyearReview) && (
            <div className={styles.review}>
              <h2 className={styles.reviewName}>
                Mid-year review objective
              </h2>
              <p className={styles.reviewDescr}>
                {item.midyearReview}
              </p>
            </div>
            )}
            {(isEndYearActive && item.endYearReview) && (
            <div className={styles.review}>
              <h2 className={styles.reviewName}>
                End-year review objective
              </h2>
              <p className={styles.reviewDescr}>
                {item.endYearReview}
              </p>
            </div>
            )}
            {((profileStatus === 'default' || profileStatus === 'rejected') && !isNoEdit) && (
              <div className={styles.linkLine}>
                <div className={styles.link}>
                  <span
                    onKeyDown={() => showModal<IPropsModal>('addBusinessObjective',
                      {
                        typeAction: 'edit', data: item, setWasSuccessAction: () => setWasSuccessAction(), empId,
                      })}
                    onClick={() => showModal<IPropsModal>('addBusinessObjective',
                      {
                        typeAction: 'edit', data: item, setWasSuccessAction: () => setWasSuccessAction(), empId,
                      })}
                    role="link"
                    tabIndex={0}
                    className={styles.link}
                  >
                    {(!empId && (isStartYearActive || isMidYearActive || isEndYearActive))
                    && (
                    <button type="button" className={styles.link}>
                      {isMidYearActive && !item.midyearReview
                        ? 'Mid-year review' : ''}
                      {isEndYearActive && !item.endYearReview
                        ? 'End-year review' : ''}
                      {isStartYearActive || (isMidYearActive && (item.midyearReview))
                      || (isEndYearActive && (item.endYearReview)) ? 'Edit' : ''}
                    </button>
                    )}
                  </span>
                </div>
                {!empId && (
                  <RemoveBusinessObjective
                    businessObjectiveId={item.id}
                    setWasSuccessAction={setWasSuccessAction}
                    setWasDeletedIds={setWasDeletedIds}
                    createdPeriod={item.createdPeriod}
                  />
                )}
              </div>
            )}
          </CollapsePanel>
        );
      })}
    </div>
  );
});

export default GoalList;
