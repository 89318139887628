/* eslint-disable react/no-danger */
import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon, notify } from 'storybook-directual';
import { useRouteMatch, useHistory } from 'react-router-dom';
import classnames from 'classnames';

import Button from 'components/Button';
import Loader from 'components/Loader';

import useDirectualApi from 'shared/hooks/useDirectualApi';
import { getFormat } from 'shared/utils/time';
import { IResponseGet, IResponseSend } from 'shared/types/directual';

import { sidebarStore, modalStore, userStore } from 'mobx/stores';

import { IMatchParams, IGuidebook } from './types';

import styles from './GuidebookViewPage.module.scss';

const GuidebookViewPage: React.FC = observer(() => {
  const match = useRouteMatch<IMatchParams>();
  const history = useHistory();

  const { setData, setSidebarMenu } = sidebarStore;
  const { showModal, modal } = modalStore;
  const { user } = userStore;

  const [guidebook, getGuidebook] = useDirectualApi<IResponseGet<IGuidebook>, null>({
    type: 'getData',
    configurator: { structure: 'guidebook', endpoint: 'getGuidebookById' },
  });

  const [download, saveGuidebook] = useDirectualApi({
    type: 'sendData',
    converter: useCallback((res: IResponseSend) => res.result[0], []),
    configurator: { structure: 'guidebook', endpoint: 'saveGuidebook' },
  });

  useEffect(() => {
    setSidebarMenu([]);
    setData({ backUrl: '/guidebook' });
  }, []);

  useEffect(() => {
    if (modal.type === 'noModal') {
      const { params: { id } } = match;
      getGuidebook({ id });
    }
  }, [modal.type]);

  useEffect(() => {
    const { response } = guidebook;
    if (response && response.status) {
      if (response.payload[0]) {
        setData({ title: response.payload[0].name, info: `created ${getFormat(response.payload[0].dateCreated)}` });
        saveGuidebook({ id: response.payload[0].id, action: 'download' });
      } else if (!response.payload[0]) {
        notify({ type: 'error', header: 'Error', message: 'Failed to get data' });
        history.push('/guidebook');
      }
    }
  }, [guidebook.isLoading]);

  return (
    <div className={classnames({ [styles.wrapper]: true, [styles.opacity]: guidebook.isLoading })}>
      {guidebook.isLoading && <Loader className={styles.spinner} />}
      <div className={styles.btnBlock}>
        {!user.isEmployee && (
          <Button
            label="Edit"
            type="action"
            icon={<Icon type="refresh" />}
            onClick={() => showModal('competencyGuideEdit', { guidebook: guidebook.response && guidebook.response.payload[0] })}
          />
        )}
        <Button
          label="Download"
          type="default"
          btnType="download"
          disabled={download.isLoading}
          downloadLink={download.response ? download.response.downloadFileLink : ''}
          icon={<Icon type="download" />}
          target="_blank"
        />
      </div>
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{
          __html: (guidebook.response && guidebook.response.payload[0])
            ? guidebook.response.payload[0].content : '',
        }}
      />
    </div>
  );
});

export default GuidebookViewPage;
