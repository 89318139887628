import React, { ReactElement } from 'react';
import { StorybookProvider } from 'storybook-directual';

import AppRouter from './router/AppRouter';

const App: React.FC = (): ReactElement => (
  <StorybookProvider localeName="en">
    <AppRouter />
  </StorybookProvider>
);

export default App;
