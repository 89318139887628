import { useState, useEffect, useCallback } from 'react';
import { api } from 'directual';

import { LStorage } from 'shared/utils/constants';
import { IResponseType } from 'shared/types/directual';

import { userStore } from 'mobx/stores';

interface IFullResponse<T, E> {
  isLoading: boolean;
  response: IResponseType<T>;
  error: E | null | boolean;
}

interface IProps<T> {
  type: 'getData' | 'sendData';
  converter?: (res: any) => T;
  configurator: {
    structure: string;
    endpoint: string;
  };
  getFullList?: boolean;
}

interface IResponse {
  status: string;
}

const useDirectualApi = <T, E>(props: IProps<T>): [
  IFullResponse<T, E>,
  (options?: object, dinymicEndpoint?: string) => void
] => {
  const {
    type,
    converter,
    configurator: { endpoint, structure },
    getFullList,
  } = props;
  const { logoutUser } = userStore;

  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<IResponseType<T>>(null);
  const [error, setError] = useState<E | null | boolean>(null);
  const [clearResponse, setClearResponse] = useState<IResponseType<T>>(null);

  const request = useCallback((options?: object, dinymicEndpoint?: string) => {
    setIsLoading(true);
    setResponse(null);
    setError(null);

    const convertResponse = (res: T) => {
      if (converter) {
        const convertedResponse = converter(res);

        if (convertedResponse) {
          return convertedResponse;
        }

        setError(true);
        return null;
      }

      return res;
    };

    const pageSize = getFullList ? '&pageSize=1000' : '';

    const fullEndpoint = `${dinymicEndpoint || endpoint}?sessionID=${localStorage.getItem(LStorage.token)}${pageSize}`;

    api
      .structure(structure)[type](fullEndpoint, options)
      .then((res: T & IResponse) => {
        const newRes = { ...res, status: res && res.status.toLowerCase() === 'ok' };
        setResponse(convertResponse(newRes));
        setClearResponse(newRes);
      })
      .catch((err: E) => setError(err))
      .finally(() => setIsLoading(false));
  }, [converter, endpoint, structure, type]);

  useEffect(() => {
    const res = clearResponse as any as { httpCode: number };

    if (res && res.httpCode && res.httpCode === 403) {
      logoutUser();
    }
  }, [clearResponse]);

  return [{ isLoading, response, error }, request];
};

export default useDirectualApi;
