import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon, Select } from 'storybook-directual';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import EmployeeCard from 'components/EmployeeCard';
import Loader from 'components/Loader';
import { IEmployee } from 'components/EmployeeCard/types';

import { useEmployee, useCommon } from 'shared/hooks/directual';
import { LStorage } from 'shared/utils/constants';

import { modalStore, userStore } from 'mobx/stores';
import { IPropsModal as IPropsEditProfile } from 'components/ModalRoot/Modals/EmployeeForm/types';

import { FILTER_LIST } from './assets/data';
import { FilterTypes, IOptions } from './types';

import styles from './EmployeesPage.module.scss';

const EmployeesPage: React.FC = observer(() => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const {
    searchEmployees,
    getSearchEmployees: searchEmployeeByName,
    supervisors,
    getSupervisors,
    filteredEmployees,
    getFilteredEmployees,
  } = useEmployee();
  const {
    positions, getPositions,
    companies, getCompanies,
    locations, getLocations,
    departments, getDepartments,
    workingStatus, getWorkingStatus,
  } = useCommon();

  const [activeFilter, setActiveFilter] = useState<FilterTypes>('');
  const [activeSelect, setActiveSelect] = useState<string | null>(null);
  const [placeholderSelect, setPlaceholderSelect] = useState('');

  const [wasCreate, setWasCreate] = useState(false);

  const [useSearchList, setUseSearchList] = useState(false);
  const [currentList, setCurrentList] = useState<IEmployee[]>([]);
  const [searchList, setSearchList] = useState<IEmployee[]>([]);

  const [options, setOptions] = useState<IOptions>({
    positions: [],
    departments: [],
    supervisors: [],
    companies: [],
    locations: [],
    workingStatus: [],
  });

  const { showModal } = modalStore;
  const { user, user: { profileData } } = userStore;

  useEffect(() => {
    getFilteredEmployees();
  }, []);

  useEffect(() => {
    if (wasCreate) {
      getFilteredEmployees();
      setWasCreate(false);
    }
  }, [wasCreate]);

  const getSearchText = () => {
    const params = new URLSearchParams(location.search);
    return params.get('empName');
  };

  useEffect(() => {
    const empName = getSearchText();
    if (typeof empName === 'string') {
      searchEmployeeByName({ search: empName, ignore_id: profileData.id });
    }
  }, [location]);

  useEffect(() => {
    const empName = getSearchText();

    if ((typeof empName === 'string') && searchEmployees.response && filteredEmployees.response && filteredEmployees.response.payload) {
      const { response: findEmp } = searchEmployees;
      const { response: { payload: currentEmp } } = filteredEmployees;

      const newEmp = currentEmp.filter(e => findEmp.find(f => f.id === e.id));
      setSearchList(newEmp);
      setUseSearchList(true);
    }
  }, [searchEmployees.response, filteredEmployees.response]);

  useEffect(() => {
    if (filteredEmployees.response && filteredEmployees.response.payload) {
      const { response: { payload: currentEmp } } = filteredEmployees;
      setCurrentList(currentEmp);
    }
  }, [filteredEmployees.response]);

  useEffect(() => {
    switch (activeFilter) {
      case 'locations': {
        if (locations.response) {
          setPlaceholderSelect('All locations');
          setOptions({ ...options, locations: locations.response });
        }
        break;
      }
      case 'positions': {
        if (positions.response) {
          setOptions({ ...options, positions: positions.response });
        }
        break;
      }
      case 'departments': {
        if (departments.response) {
          setOptions({ ...options, departments: departments.response });
        }
        break;
      }
      case 'companies': {
        if (companies.response) {
          setOptions({ ...options, companies: companies.response });
        }
        break;
      }
      case 'supervisors': {
        if (supervisors.response) {
          setOptions({ ...options, supervisors: supervisors.response });
        }
        break;
      }
      case 'workingStatus': {
        if (workingStatus.response) {
          setOptions({ ...options, workingStatus: workingStatus.response });
        }
        break;
      }
      default: break;
    }
  }, [locations.response, positions.response, companies.response, departments.response,
    supervisors.response, workingStatus.response]);

  useEffect(() => {
    setActiveSelect(null);
    setPlaceholderSelect(`All ${activeFilter}`);

    switch (activeFilter) {
      case 'locations': {
        if (!locations.response) {
          getLocations({ id: localStorage.getItem(LStorage.userId) });
        }
        break;
      }
      case 'positions': {
        if (!positions.response) {
          getPositions({ id: localStorage.getItem(LStorage.userId) });
        }
        break;
      }
      case 'departments': {
        if (!departments.response) {
          getDepartments({ id: localStorage.getItem(LStorage.userId) });
        }
        break;
      }
      case 'supervisors': {
        if (!supervisors.response) {
          getSupervisors({ id: localStorage.getItem(LStorage.userId) });
        }
        break;
      }
      case 'companies': {
        if (!companies.response) {
          getCompanies({ id: localStorage.getItem(LStorage.userId) });
        }
        break;
      }
      case 'workingStatus': {
        if (!workingStatus.response) {
          getWorkingStatus({ id: localStorage.getItem(LStorage.userId) });
        }
        break;
      }
      default: break;
    }
  }, [activeFilter]);

  const changeSelectFilter = (id: string) => {
    setActiveSelect(id);
    setSearchList([]);
    setUseSearchList(false);
    switch (activeFilter) {
      case 'locations': {
        getFilteredEmployees({ location_id: id });
        break;
      }
      case 'positions': {
        getFilteredEmployees({ position_id: id });
        break;
      }
      case 'departments': {
        getFilteredEmployees({ department_id: id });
        break;
      }
      case 'supervisors': {
        getFilteredEmployees({ supervisor_id: id });
        break;
      }
      case 'companies': {
        getFilteredEmployees({ company_id: id });
        break;
      }
      case 'workingStatus': {
        getFilteredEmployees({ workingStatus_id: id });
        break;
      }
      default:
        break;
    }
  };

  const handlerClearFilter = () => {
    setActiveFilter('');
    setCurrentList([]);
    setSearchList([]);
    setUseSearchList(false);

    const empName = getSearchText();
    if (empName) {
      history.push(match.path);
    }
    getFilteredEmployees();
  };

  const empList = useMemo(() => (
    useSearchList ? searchList : currentList), [useSearchList, searchList, currentList]);

  const optionFilterList = useMemo(
    () => FILTER_LIST.filter(item => (!user.isHr ? !item.isHr : item)), [FILTER_LIST, user],
  );

  return (
    <>
      <div className={styles.title}>Employees</div>
      <div className={styles.btnBlock}>
        <div className={styles.filterWrapper}>
          <Dropdown
            className={styles.filter}
            onChange={(value: FilterTypes) => setActiveFilter(value)}
            options={optionFilterList}
            activeItem={activeFilter}
          />
          {activeFilter && (
            <div className={styles.filterSettings}>
              <Select
                selectedOptionId={activeSelect}
                options={options[activeFilter]}
                placeholder={placeholderSelect}
                onChange={(id: string) => changeSelectFilter(id)}
              />
              <span
                role="presentation"
                className={styles.clear}
                onClick={() => handlerClearFilter()}
              >
                clear filter
              </span>
            </div>
          )}
        </div>
        {user.isHr && (
          <Button
            label="New Employee"
            onClick={() => showModal<IPropsEditProfile>('employeeForm', {
              typeAction: 'create',
              setWasSuccess: () => setWasCreate(true),
            })}
            type="action"
            icon={<Icon type="plus" />}
          />
        )}
      </div>
      <div className={styles.employeeList}>
        {(filteredEmployees.isLoading || searchEmployees.isLoading) && (
          <Loader className={styles.spinner} />
        )}
        {(!empList.length && (!filteredEmployees.isLoading && !searchEmployees.isLoading)) && (
          <p>No data</p>
        )}
        {(!filteredEmployees.isLoading && !searchEmployees.isLoading)
          && empList.map((emp: IEmployee) => {
            if (emp.workingStatus && emp.workingStatus.id === 'dismissal' && activeSelect !== 'dismissal') {
              return null;
            }
            return (
              <EmployeeCard key={emp.id} data={emp} />
            );
          })}
      </div>
    </>
  );
});

export default EmployeesPage;
