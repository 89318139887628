import React, { useEffect, useState } from 'react';
import { Checkbox } from 'storybook-directual';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import classnames from 'classnames';

import { IProps } from './types';
import styles from './IndicatorCompetency.module.scss';

const IndicatorCompetency: React.FC<IProps> = ({
  label, indicatorId, initValue, input, comment, noToolbar, disabled, ...rest
}) => {
  const [status, setStatus] = useState(false);
  const [text, setText] = useState(comment);

  const handleChangeText = (textValue: string) => {
    setText(textValue);
    input.onChange({
      indicatorId,
      name: label,
      status,
      text: textValue,
    });
  };

  const handleChangeStatus = (statusValue: boolean) => {
    setStatus(statusValue);
    input.onChange({
      indicatorId,
      name: label,
      status: statusValue,
      text,
    });
  };

  useEffect(() => {
    if (initValue) {
      handleChangeStatus(true);
    }
  }, [initValue]);

  return (
    <>
      <div className={styles.customControl}>
        <Checkbox
          disabled={disabled}
          checked={status}
          onChange={() => handleChangeStatus(!status)}
        >
          {label}
        </Checkbox>
        <div className={classnames({
          [styles.containerTextArea]: true,
          [styles.disabled]: disabled,
        })}
        >
          <CKEditor
            {...input}
            {...rest}
            disabled={disabled}
            editor={ClassicEditor}
            data={text}
            config={{
              placeholder: 'Define your action plan to develop this competency',
              toolbar: [],
              link: {
                decorators: {
                  isExternal: {
                    mode: 'manual',
                    label: 'Open in a new tab',
                    attributes: {
                      target: '_blank',
                    },
                  },
                  toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                      download: 'file',
                    },
                  },
                },
              },
            }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              handleChangeText(data);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default IndicatorCompetency;
