import React from 'react';
import { observer } from 'mobx-react-lite';

import CollapsePanel from 'components/CollapsePanel';
import Loader from 'components/Loader';

import { userStore, modalStore } from 'mobx/stores';
import { IPropsModal } from 'components/ModalRoot/Modals/AddDevelopmentsAreas/types';
import { useEmpId } from 'shared/hooks';
import { isJsonString } from 'shared/utils/string';
import { IRespComp, IProps } from './types';
import styles from './CompetencieList.module.scss';


const CompetencieList: React.FC<IProps> = observer(({
  setWasSuccessAction,
  devArea,
  isLoading,
}) => {
  const {
    user: { profileData: { profileStatus } },
    periods: {
      isStartYearActive, isMidYearActive, isEndYearActive, isNoEdit,
    },
  } = userStore;
  const { showModal } = modalStore;

  const empId = useEmpId();

  // TODO: рефакторить
  const getCompetencyComment = (item: IRespComp, competencyId: string) => {
    let comment = '';
    try {
      const parseComments = item.comments ? JSON.parse(item.comments) : {};
      Object.values(parseComments).forEach((c: any) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(c)) {
          if (competencyId === key) {
            comment = value as string;
          }
        }
        return c;
      });
    } catch (e) {
      window.console.log(e);
    }
    return comment;
  };

  return (
    <div className={styles.items}>
      {isLoading && <Loader />}
      {(!isLoading && devArea && (devArea.length <= 0)) && (
        <div>No data on development areas</div>
      )}
      {devArea && devArea.map((item, index) => (
        <CollapsePanel
          key={item.id}
          title={item.competency_id.name}
        >
          {item.competencyIndicator_ids.map(indicator => (
            <div className={styles.competencyInfo} key={indicator.id}>
              <div>{indicator.name}</div>
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: getCompetencyComment(item, indicator.id) }} />
              {item.midYearAssesment && isJsonString(item.midYearAssesment) && (
              <p className={styles.review}>
                {JSON.parse(item.midYearAssesment)[indicator.id]}
              </p>
              )}
              {item.endYearAssesment && isJsonString(item.endYearAssesment) && (
              <p className={styles.review}>
                {JSON.parse(item.endYearAssesment)[indicator.id]}
              </p>
              )}
            </div>
          ))}
          {((isEndYearActive || isMidYearActive || isNoEdit) && item.midYearAssesment) && (
          <div className={styles.review}>
            <h2 className={styles.reviewName}>
              Mid-year review objective
            </h2>
            <p className={styles.reviewDescr}>
              {isJsonString(item.midYearAssesment) && JSON.parse(item.midYearAssesment).review}
            </p>
          </div>
          )}
          {isEndYearActive && item.endYearAssesment && (
          <div className={styles.review}>
            <h2 className={styles.reviewName}>
              End-year review objective
            </h2>
            <p className={styles.reviewDescr}>
              {isJsonString(item.endYearAssesment) && JSON.parse(item.endYearAssesment).review}
            </p>
          </div>
          )}
          {((profileStatus === 'default' || profileStatus === 'rejected') && !isNoEdit) && (
            <div className={styles.link}>
              <span
                onKeyDown={() => showModal<IPropsModal>('addDevelopmentsAreas', {
                  typeAction: 'edit',
                  data: item,
                  setWasSuccessAction: () => setWasSuccessAction(),
                  empId,
                  devAreas: devArea,
                })}
                onClick={() => showModal<IPropsModal>('addDevelopmentsAreas', {
                  typeAction: 'edit',
                  data: item,
                  setWasSuccessAction: () => setWasSuccessAction(),
                  empId,
                  devAreas: devArea,
                })}
                role="link"
                tabIndex={index}
                className={styles.link}
              >
                {(!empId && (isStartYearActive || isMidYearActive || isEndYearActive))
                && (
                  <button type="button" className={styles.link}>
                    {isMidYearActive && !item.midYearAssesment
                      ? 'Mid-year review' : ''}
                    {isEndYearActive && !item.endYearAssesment
                      ? 'End-year review' : ''}
                    {isStartYearActive || (isMidYearActive && (item.midYearAssesment))
                    || (isEndYearActive && (item.endYearAssesment)) ? 'Edit' : ''}
                  </button>
                )}
              </span>
            </div>
          )}
        </CollapsePanel>
      ))}
    </div>
  );
});

export default CompetencieList;
