import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ReactModal from 'react-modal';

import { modalStore } from 'mobx/stores';

import { Types } from './types';

import modalTypes from './Modals';

import styles from './ModalRoot.module.scss';

const MODAL_TYPES: Types = {
  employeeForm: modalTypes.EmployeeForm,
  competencyGuideEdit: modalTypes.CompetencyGuideEdit,
  hrEditProfileStatus: modalTypes.HREditProfileStatus,
  feedbackRequest: modalTypes.FeedbackRequest,
  addBusinessObjective: modalTypes.AddBusinessObjective,
  addDevelopmentsAreas: modalTypes.AddDevelopmentsAreas,
  returnForRevision: modalTypes.ReturnForRevision,
  assessment360: modalTypes.Assessment360,
  performanceEvaluation: modalTypes.PerformanceEvaluation,
  noModal: modalTypes.noModal,
};

const ModalRoot: React.FC = observer(() => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { modal } = modalStore;

  useEffect(() => {
    setIsOpen(true);
  }, [modal.type]);

  const closeModal = () => { setIsOpen(false); };
  if (modal.type === 'noModal') {
    return null;
  }

  const SpecifiedModal = MODAL_TYPES[modal.type];

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick={false}
        className={styles.overrideModal}
        bodyOpenClassName={styles.bodyOpen}
      >
        <SpecifiedModal />
      </ReactModal>
    </>
  );
});

export default ModalRoot;
