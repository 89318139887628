import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { notify, Icon } from 'storybook-directual';
import classnames from 'classnames';

import Button from 'components/Button';
import Loader from 'components/Loader';

import { IResponseSend } from 'shared/types/directual';
import { useDirectualApi } from 'shared/hooks';

import { userStore } from 'mobx/stores';

import styles from './SendForReview.module.scss';

import { IProps } from './types';

const SendForReview: React.FC<IProps> = observer(() => {
  const [wasSendForReview, setWasSendForReview] = useState(false);

  const { user: { profileData }, setProfileStatus } = userStore;

  const [result, sendForReview] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'actionInput', endpoint: 'saveAction' },
  });

  useEffect(() => {
    const { response } = result;

    if (response && response.result[0].id) {
      notify({
        type: 'success',
        header: 'Info',
        message: 'Request sent successfully',
      });
      setProfileStatus('inProcess');
    } else if (response && (!response.result || !response.result[0].id)) {
      notify({
        type: 'error',
        header: 'Error',
        message: 'Unknown error',
      });
    }
  }, [result.isLoading]);

  const handleSend = () => {
    if (profileData.canSendForReviewEndYear) {
      sendForReview({
        employee_id: profileData.id,
        initiator_id: profileData.id,
        action: 'sendToApprove',
      });
    }
  };

  useEffect(() => {
    if (wasSendForReview) {
      handleSend();
      setWasSendForReview(false);
    }
  }, [wasSendForReview]);

  let btnText = 'Send for review';
  let icon = null;
  if (profileData.profileStatus === 'approved') {
    btnText = 'Approved';
    icon = <Icon type="done" />;
  } else if (profileData.profileStatus === 'inProcess') {
    btnText = 'Sent for review';
    icon = <Icon type="clocks" />;
  }

  return (
    <Button
      className={classnames({
        [styles.action]: true,
        [styles.opactity]: (profileData.profileStatus === 'approved' || profileData.profileStatus === 'inProcess'),
      })}
      disabled={!profileData.canSendForReviewEndYear || profileData.profileStatus === 'approved' || profileData.profileStatus === 'inProcess'}
      label={btnText}
      type="action"
      onClick={() => setWasSendForReview(true)}
      icon={result.isLoading ? <Loader /> : icon}
    />
  );
});

export default SendForReview;
