import React, { useState } from 'react';
import { SelectCheckable } from 'storybook-directual';

import { IProps } from './types';

import styles from './CustomSelectCheckable.module.scss';

const CustomSelectCheckable: React.FC<IProps> = ({
  label, input, description, options, ...rest
}) => {
  const { meta: { error, touched } } = rest;
  const [listEmpSelectIds, setlistEmpSelectIds] = useState<string[]>([]);

  const handleChange = (values: string[]) => {
    setlistEmpSelectIds(values);
    input.onChange(values);
  };

  return (
    <div className={styles.customControl}>
      <div className={styles.title}>
        {label && <div className={styles.label}>{label}</div>}
        {(error && touched) && <span className={styles.error}>{error}</span>}
      </div>
      <SelectCheckable
        {...input}
        {...rest}
        selectedOptionIds={listEmpSelectIds}
        options={options}
        onChange={(value: string[]) => handleChange(value)}
      />
    </div>
  );
};

export default CustomSelectCheckable;
