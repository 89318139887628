import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { notify } from 'storybook-directual';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';

import CustomInput from 'components/CustomInput';
import Button from 'components/Button';
import Loader from 'components/Loader';

import { useEmployee } from 'shared/hooks/directual';
import { LStorage } from 'shared/utils/constants';

import { userStore, sidebarStore } from 'mobx/stores';

import { IFields } from './types';

import styles from './LoginPage.module.scss';

const validate = (values: IFields) => {
  const errors: Partial<IFields> = {};
  if (!values.username) {
    errors.username = 'Enter the email';
  }
  if (!values.password) {
    errors.password = 'Enter the password';
  }

  return errors;
};

const LoginPage: React.FC = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    authenticateUser,
    setProfileData,
    user,
    logoutUser,
    setRole,
  } = userStore;
  const { initHeaderMenu } = sidebarStore;

  const {
    employeeAuth,
    getEmployeeAuth,
    employeeData,
    getEmployeeData,
  } = useEmployee();

  const onSubmit = async (data: IFields) => {
    setIsLoading(true);
    const userData = await authenticateUser(data);
    if (userData) {
      getEmployeeAuth({ web_id: data.username });
    } else {
      notify({ type: 'error', header: 'Error', message: user.error });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const { response } = employeeAuth;
    if (response && response.status) {
      if (response.payload[0]) {
        const userId = response.payload[0].id;
        const userRole = response.payload[0].role;

        localStorage.setItem(LStorage.userId, userId);
        localStorage.setItem(LStorage.userRole, userRole || 'employee');

        setRole(userRole || 'employee');
        getEmployeeData({ id: userId });
      } else {
        setIsLoading(false);
        notify({ type: 'error', header: 'Error', message: 'Failed to get employee ID' });
        logoutUser();
      }
    }
  }, [employeeAuth.isLoading]);

  useEffect(() => {
    const { response } = employeeData;
    if (response) {
      initHeaderMenu();
      setProfileData(response);
    }
  }, [employeeData.isLoading]);

  return (
    <div className={classnames({ [styles.container]: true, [styles.opacity]: isLoading })}>
      {isLoading && <Loader className={styles.spinner} />}
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <h2>Login</h2>
            <div className={styles.input}>
              <Field
                name="username"
                label="Email"
                component={CustomInput}
                disabled={isLoading}
              />
            </div>
            <div className={styles.input}>
              <Field
                name="password"
                label="Password"
                type="password"
                component={CustomInput}
                disabled={isLoading}
              />
            </div>
            <div className={styles.btnBlock}>
              <Button disabled={isLoading} label="Sign in" btnType="submit" type="action" />
              <Link to="/recovery">
                <Button disabled={isLoading} label="Forgot password" type="default" />
              </Link>
            </div>
          </form>
        )}
      />
    </div>
  );
});

export default LoginPage;
