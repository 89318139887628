import React, { useState } from 'react';
import classnames from 'classnames';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import styles from './CustomEditor.module.scss';

const TOOLBAR = ['heading', '|', 'bold', 'italic', '|', 'undo', 'redo', '|', 'link', '|', 'bulletedList', 'numberedList'];

const CustomEditor: React.FC<any> = ({
  label, input, description, noToolbar, placeholder, ...rest
}) => {
  const { meta: { error, touched } } = rest;

  const [value, setValue] = useState(input.value);

  const handleChange = (val: string) => {
    setValue(val);
    input.onChange(val);
  };

  return (
    <>
      <div className={classnames({ [styles.customControl]: true, [styles.noAutoSize]: noToolbar })}>
        <div className={styles.title}>
          {label && <div className={styles.label}>{label}</div>}
          {(error && touched) && <span className={styles.error}>{error}</span>}
        </div>
        <CKEditor
          {...input}
          {...rest}
          editor={ClassicEditor}
          data={value}
          config={{
            placeholder,
            toolbar: noToolbar ? [] : TOOLBAR,
            link: {
              decorators: {
                isExternal: {
                  mode: 'manual',
                  label: 'Open in a new tab',
                  attributes: {
                    target: '_blank',
                  },
                },
                toggleDownloadable: {
                  mode: 'manual',
                  label: 'Downloadable',
                  attributes: {
                    download: 'file',
                  },
                },
              },
            },
          }}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            handleChange(data);
          }}
        />
        {description && <div className={styles.description}>{description}</div>}
      </div>
    </>
  );
};

export default CustomEditor;
