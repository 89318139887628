import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { notify, Icon } from 'storybook-directual';

import { IResponseSend } from 'shared/types/directual';
import { useDirectualApi, useEmpId } from 'shared/hooks';

import { userStore } from 'mobx/stores';

import Button from '../Button';

import { IProps } from './types';

import styles from './ToApprove.module.scss';

const ToApprove: React.FC<IProps> = observer(({
  profileStatus, setWasSuccess, disabled,
}) => {
  const [approve, sendToApprove] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'actionInput', endpoint: 'saveAction' },
  });

  const { user: { profileData } } = userStore;
  const empId = useEmpId();

  useEffect(() => {
    const { response } = approve;
    if (response && response.result[0].id) {
      setWasSuccess(true);
      notify({ type: 'success', header: 'Info', message: 'Profile was approved' });
    } else if (response && !response.result[0].id) {
      notify({ type: 'error', header: 'Error', message: 'Unknown error' });
    }
  }, [approve.isLoading]);

  const handlerApprove = () => {
    sendToApprove({
      action: 'approve',
      employee_id: empId,
      initiator_id: profileData.id,
    });
  };

  return (
    <Button
      className={styles.action}
      disabled={disabled}
      label={profileStatus === 'approved' ? 'Approved' : 'Approve'}
      type="action"
      onClick={() => handlerApprove()}
      icon={<Icon type="done" />}
    />
  );
});

export default ToApprove;
