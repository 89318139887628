import React from 'react';

import defaultAvatarImg from 'assets/img/defaultAvatar.png';

import { IProps } from './types';

import styles from './FeedbackComment.module.scss';

const FeedbackComment: React.FC<IProps> = ({
  fullName,
  position,
  text,
  dateCreated = null,
  avatar = '',
}) => (
  <div className={styles.wrapper}>
    <div className={styles.avatar}>
      <img src={avatar || defaultAvatarImg} alt="avatar" />
    </div>
    <div className={styles.content}>
      <div className={styles.date}>{dateCreated}</div>
      <div className={styles.person}>
        <span className={styles.fullName}>{fullName}</span>
        <span className={styles.position}>{position}</span>
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  </div>
);

export default FeedbackComment;
