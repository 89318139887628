import React, { useState, useEffect } from 'react';
import { SelectCheckable, Icon, notify } from 'storybook-directual';

import defaultAvatar from 'assets/img/defaultAvatar.png';

import Button from 'components/Button';

import useDirectualApi from 'shared/hooks/useDirectualApi';
import { IResponseType, IOption, IResponseSend } from 'shared/types/directual';
import { removeItem, insertItem } from 'shared/utils/immutable';
import { LStorage } from 'shared/utils/constants';

import { IProps, IEmployee } from './types';

import styles from './FeedbackRequestCard.module.scss';

const MAX_CHILDS = 12;

const FeedbackRequestCard: React.FC<IProps> = ({
  employee,
  childs,
  employeeList,
  requestId,
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [listEmpSelectIds, setlistEmpSelectIds] = useState<string[]>([]);

  const [childList, setChildList] = useState<IEmployee[]>(childs);
  const [optionList, setOptionList] = useState<IResponseType<IOption[]>>(employeeList);

  const listEmpChild = [...childs].reverse();

  const [request, setRequest] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'feedbackRequest', endpoint: 'saveRequest' },
  });

  const isSuccessSetData = request.response
    && request.response.status && request.response.result[0];

  useEffect(() => {
    const { response } = request;
    if (isSuccessSetData) {
      notify({ type: 'success', header: 'Info', message: 'Request approved successfully' });
    } else if (response && response.msg) {
      notify({ type: 'error', header: 'Error', message: response.msg });
    }
  }, [request.isLoading]);

  const onSubmit = (withCorrections: boolean = true) => {
    const childsId = [...childList].map(item => item.id);
    const empIds = withCorrections ? childsId.concat(listEmpSelectIds) : childsId;
    setRequest({
      id: requestId,
      toEmployee_ids: empIds,
      lastEditor_id: localStorage.getItem(LStorage.userId),
      action: 'edit_approve',
    });
  };

  const initOptions = () => {
    if (employeeList) {
      const mountOptions = employeeList.filter(
        item => !childList.find(ch => ch.id === item.id),
      );
      setOptionList(mountOptions);
    }
  };

  useEffect(() => { initOptions(); }, [employeeList]);

  const onEdit = () => {
    const resetOptions = () => {
      setChildList(childs);
      setOptionList(employeeList);
      setlistEmpSelectIds([]);
    };

    if (isEdit) { resetOptions(); } else { initOptions(); }
    setIsEdit(!isEdit);
  };

  const onRemoveChild = (id: string) => {
    const removeChild = () => {
      const removeId = childList.findIndex(item => item.id === id);
      const newChildList = removeItem<IEmployee>(childList, removeId);
      setChildList(newChildList);
    };

    const insertOption = () => {
      const newEmployee = employeeList!.find(item => item.id === id);
      if (newEmployee && optionList) {
        const newOptionList = insertItem(optionList, 0, newEmployee);
        setOptionList(newOptionList);
      }
    };

    removeChild();
    insertOption();
  };

  if (isSuccessSetData) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainBlock}>
        <div className={styles.info}>
          <div className={styles.avatar}>
            <img src={employee.photo_id ? employee.photo_id.urlLink : defaultAvatar} alt="avatar" />
          </div>
          <div className={styles.desc}>
            <span className={styles.name}>{employee.fullName}</span>
            <span className={styles.position}>
              {employee.position && employee.position.name}
            </span>
          </div>
        </div>
        <div className={styles.childs}>
          {listEmpChild.map((ch: IEmployee, index: number) => {
            if ((childs.length > MAX_CHILDS) && index === 0) {
              return (
                <div key={ch.id} className={styles.infoImg}>
                  +
                  {childs.length - MAX_CHILDS}
                </div>
              );
            }
            if (index < MAX_CHILDS) {
              return (
                <img
                  key={ch.id}
                  src={ch.photo_id ? ch.photo_id.urlLink : defaultAvatar}
                  alt={ch.fullName}
                />
              );
            }
            return false;
          })}
        </div>
        <div className={styles.buttons}>
          {!isEdit && <Button className={styles.approve} label="Approve" onClick={() => onSubmit(false)} type="action" />}
          <Button label={isEdit ? 'Cancel' : 'Edit'} onClick={onEdit} />
        </div>
      </div>
      {isEdit && (
        <div className={styles.bottomBlock}>
          <div className={styles.divider} />
          <div className={styles.childWrapper}>
            {childList.map((ch: IEmployee) => (
              <div role="presentation" onClick={() => onRemoveChild(ch.id)} key={ch.id} className={styles.child}>
                {ch.fullName}
                <Icon type="close" />
              </div>
            ))}
          </div>
          <div className={styles.selectWrapper}>
            <div className={styles.select}>
              <SelectCheckable
                placeholder="Employees"
                selectedOptionIds={listEmpSelectIds}
                options={optionList}
                onChange={setlistEmpSelectIds}
              />
            </div>
            <Button label="Approve with corrections" onClick={() => onSubmit(true)} type="action" />
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackRequestCard;
