import React, { useEffect } from 'react';

import Button from 'components/Button';

import { useDirectualApi } from 'shared/hooks';
import { IResponseSend } from 'shared/types/directual';

import { userStore } from 'mobx/stores';

import { IPeriods } from './types';

const isNoEditMode = (periods: IPeriods) => {
  let result = false;

  if (!periods.isStartYearActive && !periods.isMidYearActive && !periods.isEndYearActive) {
    result = true;
  }

  return result;
};

const ChangePeriod: React.FC = () => {
  const { setPeriod: setPeriodMobx } = userStore;

  const [result, setPeriod] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'reviewDates', endpoint: 'setPeriod' },
  });

  const handlerNewPeriod = () => {
    setPeriod({
      id: '1',
      isStartYearActive: true,
      isMidYearActive: false,
      isEndYearActive: false,
    });
  };

  const handlerMidPeriod = () => {
    setPeriod({
      id: '1',
      isStartYearActive: false,
      isMidYearActive: true,
      isEndYearActive: false,
    });
  };

  const handlerEndPeriod = () => {
    setPeriod({
      id: '1',
      isStartYearActive: false,
      isMidYearActive: false,
      isEndYearActive: true,
    });
  };

  const handlerNoPeriod = () => {
    setPeriod({
      id: '1',
      isStartYearActive: false,
      isMidYearActive: false,
      isEndYearActive: false,
    });
  };

  useEffect(() => {
    if (result.response) {
      const periods = {
        isStartYearActive: !!result.response.result[0].isStartYearActive,
        isMidYearActive: !!result.response.result[0].isMidYearActive,
        isEndYearActive: !!result.response.result[0].isEndYearActive,
      };

      setPeriodMobx({ ...periods, isNoEdit: isNoEditMode(periods) });
    }
  }, [result.isLoading]);

  return (
    <>
      <Button label="New" onClick={handlerNewPeriod} />
      <Button label="Mid" onClick={handlerMidPeriod} />
      <Button label="End" onClick={handlerEndPeriod} />
      <Button label="No edit" onClick={handlerNoPeriod} />
    </>
  );
};

export default ChangePeriod;
