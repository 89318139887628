import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon } from 'storybook-directual';
import classnames from 'classnames';

import Button from 'components/Button';
import Loader from 'components/Loader';

import { userStore, modalStore, sidebarStore } from 'mobx/stores';
import { IPropsModal as IPropsEditStatus } from 'components/ModalRoot/Modals/HREditProfileStatus/types';
import { IPropsModal as IPropsEditProfile } from 'components/ModalRoot/Modals/EmployeeForm/types';

import { useDirectualApi, useEmpId } from 'shared/hooks';
import { useEmployee, useAssessment } from 'shared/hooks/directual';

import defaultAvatarImg from 'assets/img/defaultAvatar.png';
import { IPropsModal as IAssement360Props } from '../../components/ModalRoot/Modals/Assessment360/types';

import styles from './ProfilePage.module.scss';

const ProfilePage: React.FC = observer(() => {
  const [avatarUrl, setAvatarUrl] = useState(defaultAvatarImg);
  const [wasEdit, setWasEdit] = useState(false);

  const empId = useEmpId();

  const { employeeData, getEmployeeData } = useEmployee();
  const { toFill360, getToFill360 } = useAssessment();

  const { setData, setSidebarMenu } = sidebarStore;
  const {
    user: {
      profileData,
      isHr,
      isEmployee,
    },
    setProfileData,
    periods,
  } = userStore;
  const { showModal, modal } = modalStore;

  const [plan, getPlan] = useDirectualApi<{ urlLink: string }, null>({
    type: 'sendData',
    converter: useCallback(res => res.result[0].planPdf_id, []),
    configurator: { structure: 'employeeInput', endpoint: 'saveEmployeeInput' },
  });

  const [pdf360, getPdf360] = useDirectualApi<{ urlLink: string }, null>({
    type: 'sendData',
    converter: useCallback(res => res.result[0].planPdf_id, []),
    configurator: { structure: 'employeeInput', endpoint: 'saveEmployeeInput' },
  });

  useEffect(() => {
    const id = empId || profileData.id;
    getEmployeeData({ id });
    getToFill360({
      aboutEmployee_id: empId || profileData.id,
      authEmployee_id: profileData.id,
    });

    if (!empId) {
      setData({
        title: profileData.fullName,
        info: `Start date ${profileData.startWorkDateText}`,
        backUrl: '/',
        backTitle: 'Main page',
      });
    } else {
      setData({
        backTitle: isEmployee ? 'My profile' : 'Main page',
        backUrl: isEmployee ? '/' : `/employee/${empId}`,
      });
    }
    getPlan({
      employee_id: id,
      action: 'downloadPlan',
    });
    getPdf360({
      employee_id: id,
      action: 'download360',
    });
    setSidebarMenu([{ label: 'Profile', link: '/profile' }]);
  }, [empId]);

  useEffect(() => {
    if (modal.type === 'noModal') {
      getToFill360({
        aboutEmployee_id: empId || profileData.id,
        authEmployee_id: profileData.id,
      });
    }
  }, [empId, modal]);

  useEffect(() => {
    if (employeeData.response) {
      const user = employeeData.response;
      if (!empId) {
        setProfileData(user);
      }
      setData({
        title: user!.fullName,
        info: `Start date ${user!.startWorkDateText}`,
        backUrl: empId ? `/employee/${empId}` : '/',
      });
    }
  }, [employeeData.response]);

  useEffect(() => {
    if (wasEdit) {
      getEmployeeData({ id: empId || profileData.id });
      setWasEdit(false);
    }
  }, [wasEdit]);

  const userData = empId && employeeData.response ? employeeData.response : profileData;

  const canChangeStatus = (empId
    && (isHr
      || (userData && userData.supervisor_id.id === profileData.id)))
    || (isHr && !empId);

  const canEdit = (empId
    && (isHr
      || (userData && userData.supervisor_id.id === profileData.id)))
    || (!empId);

  const can360 = (toFill360.response && !toFill360.response.submitted && !isHr && empId && userData
    && userData.supervisor_id.id !== profileData.id);

  useEffect(() => {
    const data = empId && employeeData.response ? employeeData.response : profileData;
    if (data && data.photo_id && data.photo_id.urlLink) {
      setAvatarUrl(data.photo_id.urlLink);
    } else {
      setAvatarUrl('');
    }
  }, [employeeData.response, profileData]);

  useEffect(() => {
    if (!avatarUrl) {
      setAvatarUrl(defaultAvatarImg);
    }
  }, [avatarUrl]);

  return (
    <>
      <div className={classnames({
        [styles.wrapper]: true,
        [styles.opacity]: employeeData.isLoading,
        [styles.withBtn]: (!empId && !periods.isEndYearActive),
        [styles.withoutBtn]: !(!empId && !periods.isEndYearActive),
      })}
      >
        {employeeData.isLoading && <Loader className={styles.spinner} />}
        <div className={styles.toolbar}>
          {canChangeStatus && (
            <Button
              className={styles.rightIntend}
              label="Change status"
              type="action"
              onClick={() => showModal<IPropsEditStatus>('hrEditProfileStatus', { employeeId: empId, setWasSuccess: () => setWasEdit(true) })}
            />
          )}
          {canEdit && (
            <Button
              className={styles.rightIntend}
              label="Edit profile"
              type="default"
              onClick={() => showModal<IPropsEditProfile>('employeeForm', {
                typeAction: 'edit',
                empId: empId || '',
                setWasSuccess: () => setWasEdit(true),
                empData: empId ? employeeData.response : null,
              })}
            />
          )}
          {can360 && (
            <Button
              className={styles.rightIntend}
              label="+ 360 assessment"
              type="action"
              onClick={() => showModal<IAssement360Props>('assessment360', { empId, ...toFill360.response })}
            />
          )}
          {canEdit && (
            <>
              <Button
                label="Download PDP plan"
                className={styles.rightIntend}
                type="default"
                btnType="download"
                downloadLink={plan.response ? plan.response.urlLink : ''}
                disabled={!plan.response}
                icon={<Icon type="download" />}
              />
              <Button
                label="Download 360"
                type="default"
                btnType="download"
                downloadLink={pdf360.response ? pdf360.response.urlLink : ''}
                disabled={!pdf360.response}
                icon={<Icon type="download" />}
              />
            </>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.information}>
            <div className={styles.informationRow}>
              <div className={styles.property}>Status</div>
              <div className={styles.propertyValue}>{userData && userData.workingStatus_id ? userData.workingStatus_id.name : '-'}</div>
            </div>
            <div className={styles.informationRow}>
              <div className={styles.property}>Position</div>
              <div className={styles.propertyValue}>{(userData && userData.position) ? userData.position.name : '-'}</div>
            </div>
            <div className={styles.informationRow}>
              <div className={styles.property}>Department</div>
              <div className={styles.propertyValue}>{(userData && userData.department_id) ? userData.department_id.name : '-'}</div>
            </div>
            <div className={styles.informationRow}>
              <div className={styles.property}>Supervisor</div>
              <div className={styles.propertyValue}>{(userData && userData.supervisor_id) ? userData.supervisor_id.fullName : '-'}</div>
            </div>
            <div className={styles.separator} />
            <div className={styles.informationRow}>
              <div className={styles.property}>Company</div>
              <div className={styles.propertyValue}>{(userData && userData.company_id) ? userData.company_id.name : '-'}</div>
            </div>
            <div className={styles.informationRow}>
              <div className={styles.property}>Location</div>
              <div className={styles.propertyValue}>{(userData && userData.location_id) ? userData.location_id.name : '-'}</div>
            </div>
            <div className={styles.separator} />
            <div className={styles.informationRow}>
              <div className={styles.property}>Phone</div>
              <div className={styles.propertyValue}>{userData ? userData.phone : '-'}</div>
            </div>
            <div className={styles.informationRow}>
              <div className={styles.property}>E-mail</div>
              <div className={styles.propertyValue}>{userData ? userData.email : '-'}</div>
            </div>
            <div className={styles.separator} />
            <div className={styles.informationRow}>
              <div className={styles.property}>Hiring board</div>
              <div className={styles.propertyValue}>{userData ? userData.hiringBoard : '-'}</div>
            </div>
            <div className={styles.informationRow}>
              <div className={styles.property}>Buddy</div>
              <div className={styles.propertyValue}>{userData ? userData.buddy : '-'}</div>
            </div>
            <div className={styles.separator} />
            <div className={styles.informationRow}>
              <div className={styles.property}>Qualifications / Professional Certificates</div>
              <div className={styles.propertyValue}>{userData ? userData.hobby : '-'}</div>
            </div>
            <div className={styles.informationRow}>
              <div className={styles.property}>Languages / additional information</div>
              <div className={styles.propertyValue}>{userData ? userData.languages : '-'}</div>
            </div>
          </div>
          <div className={styles.avatar}>
            <img src={avatarUrl} alt="avatar" onError={() => { setAvatarUrl(''); }} />
          </div>
        </div>
      </div>
    </>
  );
});

export default ProfilePage;
