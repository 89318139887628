import {
  decorate,
  observable,
  action,
  runInAction,
} from 'mobx';

import { authenticateUser } from 'client/http';
import { LStorage } from 'shared/utils/constants';

import {
  IAuthUser,
  IProfileData,
  IPeriod,
  ReviewTypes,
  Status,
} from './types';

import { initUserData, initProfileData } from './initData';

const initialPeriod: IPeriod = {
  isStartYearActive: false,
  isMidYearActive: false,
  isEndYearActive: false,
  isNoEdit: false,
};

export class UserStore {
  public user = initUserData;

  public periods = initialPeriod;

  constructor() {
    const profileDataFromLStorage = localStorage.getItem(LStorage.profileData);
    const userRoleFromLStorage = localStorage.getItem(LStorage.userRole) || 'employee';
    const periodFromLStorage = localStorage.getItem(LStorage.currentPeriod);

    this.user = {
      ...initUserData,
      token: localStorage.getItem(LStorage.token) || initUserData.token,
      isAuth: !!localStorage.getItem(LStorage.token),
      profileData: profileDataFromLStorage ? JSON.parse(profileDataFromLStorage) : initProfileData,
      role: userRoleFromLStorage,
      isEmployee: userRoleFromLStorage === 'employee' || !userRoleFromLStorage,
      isHr: userRoleFromLStorage === 'hr',
    };

    this.periods = periodFromLStorage ? JSON.parse(periodFromLStorage) : initialPeriod;
  }

  public setRole = (role: string) => {
    this.user = {
      ...this.user,
      role,
      isEmployee: role === 'employee' || !role,
      isHr: role === 'hr',
    };
  };

  public setProfileData = (data: IProfileData) => {
    const profileData = {
      ...this.user.profileData,
      ...data,
      profileStatus: data.profileStatus || 'default',
    };

    this.user = {
      ...this.user,
      isAuth: true,
      profileData,
    };
    localStorage.setItem(LStorage.profileData, JSON.stringify(profileData));
  };

  public authenticateUser = async (data: IAuthUser) => {
    const resp = await authenticateUser(data);
    const status = resp.status.toLowerCase() === 'ok';

    if (status) {
      localStorage.setItem(LStorage.token, resp.result.token);
      runInAction(() => {
        this.user = {
          ...this.user,
          token: resp.result.token,
          username: resp.result.username,
        };
      });
    } else {
      runInAction(() => { this.user.error = resp.msg; });
    }

    return status ? this.user : null;
  };

  public setPeriod = (period: Partial<IPeriod>) => {
    this.periods = { ...this.periods, ...period };
    localStorage.setItem(LStorage.currentPeriod, JSON.stringify(period));
  };

  public setProfileStatus = (status: Status) => {
    this.user = {
      ...this.user,
      profileData: {
        ...this.user.profileData,
        profileStatus: status,
      },
    };
  };

  public setReview = (type: ReviewTypes, text: string) => {
    this.user = {
      ...this.user,
      profileData: {
        ...this.user.profileData,
        [type]: text,
      },
    };
    localStorage.setItem(LStorage.profileData, JSON.stringify(this.user.profileData));
  };

  public logoutUser = () => {
    localStorage.removeItem(LStorage.token);
    localStorage.removeItem(LStorage.userId);
    localStorage.removeItem(LStorage.profileData);
    localStorage.removeItem(LStorage.currentPeriod);
    runInAction(() => { this.user = initUserData; });
  };
}

decorate(UserStore, {
  user: observable,
  periods: observable,
  setProfileData: action,
  setRole: action,
  setProfileStatus: action,
  setReview: action,
  authenticateUser: action.bound,
});

export default new UserStore();
