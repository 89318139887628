import { useCallback } from 'react';

import { IResponseGet, IResponseSend } from 'shared/types/directual';

import useDirectualApi from '../../useDirectualApi';

import { converterCommentList } from './converters';
import { IRespToFill360, IRespAvg, IRespReviewDates } from './types';

const useAssessment = () => {
  const [saveAssessmentRes, saveAssessmentReq] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: '360assesment', endpoint: 'saveComment' },
  });

  const [commentList, getCommentList] = useDirectualApi({
    type: 'getData',
    converter: useCallback(res => converterCommentList(res.payload), []),
    configurator: { structure: '360assesment', endpoint: 'getComments' },
  });

  const [toFill360, getToFill360] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespToFill360>) => res.payload[0], []),
    configurator: { structure: '360assesment', endpoint: 'get360ToFill' },
  });

  const [save360answerRes, save360answerReq] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: '360answer', endpoint: 'save360answer' },
  });

  const [fullAvg, getFullAverage] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespAvg>) => res.payload, []),
    configurator: { structure: 'report_127434369_for_struct_360answer', endpoint: 'getFullAverage' },
  });

  const [employeeAvg, getEmployeeAverage] = useDirectualApi({
    type: 'getData',
    converter: useCallback((res: IResponseGet<IRespAvg>) => res.payload, []),
    configurator: { structure: 'report_2051977826_for_struct_360answer', endpoint: 'getEmployeeAverage' },
  });

  const [reviewDates, getReviewDates] = useDirectualApi({
    type: 'getData',
    converter: useCallback(
      (res: IResponseGet<IRespReviewDates>) => res.payload[0], [],
    ),
    configurator: { structure: 'reviewDates', endpoint: 'getReviewDates' },
  });

  /* eslint-disable object-property-newline */
  return {
    saveAssessmentRes, saveAssessmentReq,
    commentList, getCommentList,
    toFill360, getToFill360,
    save360answerRes, save360answerReq,
    fullAvg, getFullAverage,
    employeeAvg, getEmployeeAverage,
    reviewDates, getReviewDates,
  };
  /* eslint-enable object-property-newline */
};

export default useAssessment;
