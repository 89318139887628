import React from 'react';
import { Link } from 'react-router-dom';

import { userStore } from 'mobx/stores';

import Search from './Search';

import { ReactComponent as LogoutIcon } from './assets/logout.svg';
import { ReactComponent as LogoIcon } from './assets/logo.svg';

import styles from './Header.module.scss';

const Header: React.FC = () => {
  const { logoutUser } = userStore;
  const onLogout = () => logoutUser();
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Link className={styles.logo} to="/">
          <LogoIcon />
        </Link>
        <div className={styles.search}><Search /></div>
        <div
          className={styles.logout}
          role="presentation"
          onClick={onLogout}
          onKeyDown={onLogout}
        >
          <LogoutIcon />
        </div>
      </div>
    </div>
  );
};

export default Header;
