export const randomKey = () => (
  Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 10)
);

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
