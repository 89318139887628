import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { sidebarStore } from 'mobx/stores';

interface IPublicRoute {
  component: any;
  path: string;
  isAuthenticated: boolean;
  [key: string]: any;
}

interface IPrivateRoute extends IPublicRoute {
  layout: React.FC;
}

export const PrivateRoute: React.FC<IPrivateRoute> = observer(({
  component: Component, layout: Layout, isAuthenticated, computedMatch, ...rest
}) => {
  const history = useHistory();

  const { setHeaderMenu } = sidebarStore;
  setHeaderMenu(computedMatch.params.employeeId);

  return (
    <Route
      {...rest}
      render={props => (
        isAuthenticated
          ? (<Layout><Component {...props} /></Layout>)
          : (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location,
                  nextPathname: history.location.pathname,
                },
              }}
            />
          )
      )}
    />
  );
});

export const PublicRoute: React.FC<IPublicRoute> = ({
  component: Component, isAuthenticated, ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      !isAuthenticated
        ? (<Component {...props} />)
        : (<Redirect to="/" />)
    )}
  />
);
