import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { ReactComponent as SearchIcon } from './assets/search.svg';

import styles from './Search.module.scss';

const Search: React.FC = () => {
  const history = useHistory();

  const [textSearch, setTextSearch] = useState('');

  const onSearch = () => {
    history.push(`/employees?empName=${textSearch}`);
  };

  const onKeyPress = (keyCode: number) => {
    if (keyCode === 13) {
      onSearch();
    }
  };

  return (
    <div className={styles.wrapper}>
      <Link
        to={`/employees?empName=${textSearch}`}
        role="presentation"
        className={styles.icon}
      >
        <SearchIcon />
      </Link>
      <input
        className={styles.input}
        type="text"
        value={textSearch}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyPress(e.keyCode)}
        placeholder="Search"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextSearch(e.target.value)}
      />
    </div>
  );
};

export default Search;
