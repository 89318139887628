import React, { useRef, useEffect } from 'react';
import { Icon } from 'storybook-directual';

import Button from 'components/Button';

import { IProps } from './types';
import styles from './Notify.module.scss';

const Notify: React.FC<IProps> = ({
  headerText,
  messageText = '',
  onSubmit,
  onCancel,
  onSubmitText = 'Ok',
  onCancelText = 'Cancel',
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (contentRef.current && e.target instanceof Element) {
      if (!contentRef.current.contains(e.target)) {
        onCancel();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div ref={contentRef} className={styles.content}>
        <div className={styles.close} role="presentation" onClick={onCancel}><Icon type="close" /></div>
        <div>
          <div className={styles.title}>{headerText}</div>
          {messageText && <div className={styles.text}>{messageText}</div>}
        </div>
        <div className={styles.buttons}>
          {onSubmit && <Button type="action" label={onSubmitText} onClick={onSubmit} />}
          {onCancel && <Button label={onCancelText} onClick={onCancel} />}
        </div>
      </div>
    </div>
  );
};

export default Notify;
