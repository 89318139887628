import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Field, Form } from 'react-final-form';
import { notify } from 'storybook-directual';

import ModalLayout from 'layouts/ModalLayout';

import CustomSelect from 'components/CustomSelect';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput';
import CustomTextArea from 'components/CustomTextArea';
import CustomFileUpload from 'components/CustomFileUpload';

import useDirectualApi from 'shared/hooks/useDirectualApi';
import { useEmployee, useCommon } from 'shared/hooks/directual';
import { IResponseSend } from 'shared/types/directual';

import { modalStore, userStore } from 'mobx/stores';
import periods from './utils';

import { IFields, IPropsModal, ISavedData } from './types';

import styles from './EmployeeForm.module.scss';

const validate = (values: Partial<IFields>, needVal: boolean, isEmployee: boolean) => {
  const errors: Partial<IFields> = {};
  if (!values.status && needVal && !isEmployee) {
    errors.status = 'Enter the status';
  }
  if (!values.firstName && !isEmployee) {
    errors.firstName = 'Enter the firstName';
  }
  if (!values.lastName && !isEmployee) {
    errors.lastName = 'Enter the lastName';
  }
  if (!values.phone && !isEmployee) {
    errors.phone = 'Enter the phone number';
  }
  if (!values.email && !isEmployee) {
    errors.email = 'Enter the email';
  } else if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.location && !isEmployee) {
    errors.location = 'Enter the location';
  }
  if (!values.position && !isEmployee) {
    errors.position = 'Enter the position';
  }
  if (!values.company_id && !isEmployee) {
    errors.company_id = 'Enter the company';
  }
  if (!values.department && !isEmployee) {
    errors.department = 'Enter the department';
  }
  if (!values.supervisor && !isEmployee) {
    errors.supervisor = 'Enter the supervisor';
  }
  if (!values.date_start && needVal && !isEmployee) {
    errors.date_start = 'Enter the date start';
  }
  if (values.status === 'probation' && !values.date_end && needVal && !isEmployee) {
    errors.date_end = 'Enter the date end';
  }
  return errors;
};

const EmployeeForm: React.FC = observer(() => {
  const { user: { profileData }, user } = userStore;
  const { modal, closeModal } = modalStore;

  const modalProps: IPropsModal = modal.props;
  const { empData } = modalProps;

  const userData = empData || profileData;

  const { supervisors, getSupervisors } = useEmployee();
  const {
    workingStatus,
    getWorkingStatus,
    departments,
    getDepartments,
    companies,
    getCompanies,
    locations,
    getLocations,
    positions,
    getPositions,
  } = useCommon();

  const [titleLabel, setTitleLabel] = useState('Add new employee');
  const [result, setEmployee] = useDirectualApi<IResponseSend, null>({
    type: 'sendData',
    configurator: { structure: 'employeeInput', endpoint: 'saveEmployeeInput' },
  });

  const needAnyFields = (modalProps.typeAction === 'create' || (!user.isEmployee && !modalProps.empId));

  const onSubmit = (values: Partial<IFields>) => {
    const getStringDate = (date?: number | string) => {
      if (typeof date === 'string') {
        return date;
      }
      if (typeof date === 'number') {
        return date.toString();
      }
      return date;
    };

    const employeeId = empData ? empData.id : profileData.id;

    let saveData: ISavedData = {
      action: modalProps.typeAction,
      buddy: values.buddy || '',
      department_id: values.department || '',
      email: values.email || '',
      employee_id: (modalProps.typeAction === 'edit') ? employeeId : null,
      firstName: values.firstName || '',
      lastName: values.lastName || '',
      hiringBoard: values.hiringBoard || '',
      hobby: values.hobby,
      languages: values.languages,
      location_id: values.location || '',
      phone: values.phone || '',
      photo_id: (values.picture && values.picture[0]) ? values.picture[0].id : null,
      position: values.position || '',
      supervisor_id: values.supervisor || '',
      workingStatus_id: (!needAnyFields && empData && empData.workingStatus_id)
        ? empData.workingStatus_id.id : values.status,
      startWorkDate: (!user.isHr && empData && empData.startWorkDate)
        ? empData.startWorkDate.toString() : getStringDate(values.date_start),
      company_id: values.company_id || '',
      customPeriod: values.period || '',
    };

    const endWorkDate = (!user.isHr && empData && empData.endWorkDate)
      ? empData.endWorkDate.toString() : getStringDate(values.date_end);

    const endWorkDateNumber = parseInt(endWorkDate as string, 10);
    if (endWorkDateNumber) {
      saveData = { ...saveData, endWorkDate };
    }

    setEmployee(saveData);
  };

  useEffect(() => {
    if (modalProps.typeAction === 'edit' && !modalProps.empId) {
      setTitleLabel('Edit my profile');
    } else if (modalProps.typeAction === 'edit' && modalProps.empId) {
      setTitleLabel(`Edit profile ${empData!.fullName}`);
    }
  }, [modalProps]);

  useEffect(() => {
    if (profileData.id) {
      getLocations({ id: profileData.id });
      getWorkingStatus({ id: profileData.id });
      getPositions({ id: profileData.id });
      getDepartments({ id: profileData.id });
      getSupervisors({ id: profileData.id });
      getCompanies({ id: profileData.id });
    } else {
      userStore.logoutUser();
    }
  }, []);

  useEffect(() => {
    const { response } = result;
    if (response && response.result[0].id) {
      if (modalProps.setWasSuccess) {
        modalProps.setWasSuccess();
      }
      notify({
        type: 'success',
        header: 'Info',
        message: modalProps.typeAction === 'create' ? 'New employee added' : 'The employee has been changed',
      });
      closeModal();
    } else if (response && response.msg) {
      notify({ type: 'error', header: 'Error', message: response.msg });
    }
  }, [result.isLoading]);

  let initialValues: Partial<IFields> = {};
  if (modalProps.typeAction === 'edit') {
    initialValues = {
      status: (userData && userData.workingStatus_id) ? userData.workingStatus_id.id : '',
      date_start: userData ? userData.startWorkDate : '',
      date_end: userData ? userData.endWorkDate : '',
      firstName: userData ? userData.firstName : '',
      lastName: userData ? userData.lastName : '',
      phone: userData ? userData.phone : '',
      email: userData ? userData.email : '',
      location: (userData && userData.location_id) ? userData.location_id.id : '',
      position: (userData && userData.position) ? userData.position.id : '',
      department: (userData && userData.department_id) ? userData.department_id.id : '',
      supervisor: (userData && userData.supervisor_id) ? userData.supervisor_id.id : '',
      period: userData ? userData.customPeriod : '',
      hiringBoard: userData ? userData.hiringBoard : '',
      company_id: (userData && userData.company_id) ? userData.company_id.id : '',
      buddy: userData ? userData.buddy : '',
      hobby: userData ? userData.hobby : '',
      languages: userData ? userData.languages : '',
      picture: (userData && userData.photo_id && userData.photo_id.uuid)
        ? [{ id: userData.photo_id.uuid, name: userData.fullName }] : undefined,
    };
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values: Partial<IFields>) => validate(values, needAnyFields, (modalProps.typeAction === 'edit' && user.isEmployee))}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <ModalLayout
          title={titleLabel}
          acceptAction={handleSubmit}
        >
          <form onSubmit={handleSubmit}>
            {needAnyFields && (
              <Field
                name="status"
                label="Status"
                component={CustomSelect}
                placeholder="Please choose from the list"
                options={workingStatus.response || []}
                isLoading={workingStatus.isLoading}
                disabled={user.isEmployee}
              />
            )}
            {user.isHr && (
              <div className={styles.doubleDatepicker}>
                <div className={styles.doubleDatepicker__start}>
                  <Field
                    label="Employment date"
                    name="date_start"
                    component={CustomDatePicker}
                    placeholder="Select date"
                    disabled={user.isEmployee}
                  />
                </div>
                <div className={styles.doubleDatepicker__end}>
                  <Field
                    name="date_end"
                    component={CustomDatePicker}
                    placeholder="Select date"
                    disabled={user.isEmployee}
                  />
                </div>
              </div>
            )}
            {modalProps.typeAction === 'create' && (
              <span className={styles.desc}>
                If probation is not applicable for the employee please enter the first date only.
                If there is a trial period please enter both beginning and ending dates
              </span>
            )}
            <Field
              name="firstName"
              label="First name"
              placeholder="First name"
              component={CustomInput}
              disabled={user.isEmployee}
            />
            <Field
              name="lastName"
              label="Last name"
              placeholder="Last name"
              component={CustomInput}
              disabled={user.isEmployee}
            />
            <Field
              name="phone"
              label="Phone number"
              placeholder="Phone number"
              component={CustomInput}
              disabled={user.isEmployee}
              onlyNumbers
            />
            <Field
              name="email"
              label="E-mail address"
              placeholder="example@ufg.com"
              component={CustomInput}
              disabled={user.isEmployee}
            />
            <div className={styles.select}>
              <Field
                name="company_id"
                label="Company"
                component={CustomSelect}
                placeholder="Company"
                options={companies.response || []}
                isLoading={companies.isLoading}
                disabled={user.isEmployee}
              />
            </div>
            <div className={styles.select}>
              <Field
                name="location"
                label="Location"
                component={CustomSelect}
                placeholder="Please choose from the list"
                options={locations.response || []}
                isLoading={locations.isLoading}
                disabled={user.isEmployee}
              />
            </div>
            <div className={styles.select}>
              <Field
                name="position"
                label="Position"
                component={CustomSelect}
                placeholder="Position"
                options={positions.response || []}
                isLoading={positions.isLoading}
                disabled={user.isEmployee}
              />
            </div>
            <div className={styles.select}>
              <Field
                name="department"
                label="Department"
                component={CustomSelect}
                placeholder="Please choose from the list"
                options={departments.response || []}
                isLoading={departments.isLoading}
                disabled={user.isEmployee}
              />
            </div>
            <div className={styles.select}>
              <Field
                name="supervisor"
                label="Supervisor"
                component={CustomSelect}
                placeholder="Please choose from the list"
                options={supervisors.response || []}
                isLoading={supervisors.isLoading}
                disabled={user.isEmployee}
              />
            </div>
            <div className={styles.select}>
              <Field
                name="period"
                label="Period"
                component={CustomSelect}
                placeholder="Please choose from the list"
                options={periods}
                disabled={user.isEmployee}
              />
            </div>
            <Field
              name="hiringBoard"
              label="Hiring board"
              placeholder="Text"
              rows={3}
              component={CustomTextArea}
              disabled={user.isEmployee}
            />
            <Field
              name="buddy"
              label="Buddy"
              placeholder="Position"
              component={CustomInput}
              disabled={user.isEmployee}
            />
            <Field
              name="hobby"
              label="Qualifications / Professional Certificates"
              placeholder="Text"
              rows={3}
              component={CustomTextArea}
            />
            <Field
              name="languages"
              label="Languages / additional information"
              placeholder="Text"
              rows={3}
              component={CustomTextArea}
            />
            <Field
              name="picture"
              label="Picture"
              placeholder="Text"
              component={CustomFileUpload}
              disabled={user.isEmployee}
            />
          </form>
        </ModalLayout>
      )}
    />
  );
});

export default EmployeeForm;
