import React, { useState, useEffect } from 'react';
import { KeyValue, Icon } from 'storybook-directual';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Badge from 'components/Badge';
import Loader from 'components/Loader';

import { useEmployee } from 'shared/hooks/directual';

import { userStore } from 'mobx/stores';

import defaultAvatar from 'assets/img/defaultAvatar.png';

import { IProps } from './types';

import styles from './EmployeeCard.module.scss';

const KEY_VALUE_SIZE = { width: 100, height: 216 };

const EmployeeCard: React.FC<IProps> = observer(({ data }) => {
  const history = useHistory();
  const { user, user: { profileData } } = userStore;

  const [isCollapse, setIsCollapse] = useState<boolean>(true);

  const { employeeData, getEmployeeData } = useEmployee();

  const collapseClasses = classnames({
    [styles.bottomBlock]: true,
    [styles.hide]: isCollapse,
  });

  const goToEmployee = (e: React.SyntheticEvent) => {
    e.preventDefault();
    getEmployeeData({ id: data.id });
  };

  useEffect(() => {
    const { response } = employeeData;
    if (response && response.id) {
      if (user.isEmployee && response.supervisor_id.id !== profileData.id) {
        history.push(`employee/profile/${data.id}`);
      } else {
        history.push(`employee/${data.id}`);
      }
    }
  }, [employeeData.isLoading]);

  return (
    <div className={classnames({ [styles.wrapper]: true, [styles.opacity]: employeeData.isLoading })} role="presentation" onClick={goToEmployee}>
      {employeeData.isLoading && <Loader className={styles.loader} />}
      <div className={styles.avatar}>
        <img src={data.photoLink || defaultAvatar} alt="avatar" />
      </div>
      <div className={styles.mainBlock}>
        <div className={styles.title}>{data.fullName}</div>
        <div className={styles.content}>
          <KeyValue
            data={[
              { key: 'Position', value: data.position },
              { key: 'Start date', value: data.workSince },
            ]}
            keyColStyle={{ width: KEY_VALUE_SIZE.width }}
            valueColStyle={{ width: KEY_VALUE_SIZE.height }}
            className={styles.keyValue}
          />
          <KeyValue
            data={[
              { key: 'Phone', value: data.phone },
              { key: 'E-mail', value: data.email },
            ]}
            keyColStyle={{ width: KEY_VALUE_SIZE.width }}
            valueColStyle={{ width: KEY_VALUE_SIZE.height }}
            className={styles.keyValue}
          />
        </div>
        <div className={collapseClasses}>
          <div className={styles.divider} />
          <div className={styles.content}>
            <KeyValue
              data={[
                { key: 'Department', value: data.department },
                { key: 'Supervisor', value: data.supervisor },
              ]}
              keyColStyle={{ width: KEY_VALUE_SIZE.width }}
              valueColStyle={{ width: KEY_VALUE_SIZE.height }}
              className={styles.keyValue}
            />
            <KeyValue
              data={[
                { key: 'Company', value: data.company },
                { key: 'Location', value: data.location },
              ]}
              keyColStyle={{ width: KEY_VALUE_SIZE.width }}
              valueColStyle={{ width: KEY_VALUE_SIZE.height }}
              className={styles.keyValue}
            />
          </div>
        </div>
      </div>
      <div className={styles.infoAdv}>
        {data.isProbation && <Badge className={styles.badge} text="probation period" />}
        <Icon
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setIsCollapse(!isCollapse);
          }}
          className={classnames({ [styles.arrow]: true, [styles.arrowUp]: !isCollapse })}
          type={isCollapse ? 'down' : 'up'}
        />
      </div>
    </div>
  );
});

export default EmployeeCard;
