import React, { useEffect, useCallback } from 'react';

import GuideCard from 'components/GuideCard';
import Loader from 'components/Loader';

import useDirectualApi from 'shared/hooks/useDirectualApi';
import { getFormat } from 'shared/utils/time';

import { IGuideBook } from './types';

import styles from './GuidebookPage.module.scss';

const GuidebookPage: React.FC = () => {
  const converter = (data: IGuideBook[]) => {
    const convertData = [...data].map((item: IGuideBook) => ({
      ...item,
      dateCreated: getFormat(item.dateCreated),
    }));
    return convertData;
  };

  const [guidebooks, getGuidebooks] = useDirectualApi<IGuideBook[], null>({
    type: 'getData',
    converter: useCallback(res => converter(res.payload), []),
    configurator: {
      structure: 'guidebook',
      endpoint: 'getGuidebooks',
    },
  });

  useEffect(() => { getGuidebooks(); }, []);

  return (
    <>
      <div className={styles.title}>Guidebook</div>
      <div className={styles.guideList}>
        {guidebooks.isLoading && <Loader />}
        {guidebooks.response && guidebooks.response.map((guide: IGuideBook) => (
          <GuideCard
            key={guide.id}
            title={guide.name}
            created={`created ${guide.dateCreated}`}
            to={`/guidebook/${guide.id}`}
          />
        ))}
        {guidebooks.error && <div>No data</div>}
      </div>
    </>
  );
};

export default GuidebookPage;
