import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from 'storybook-directual';

import styles from './YearCard.module.scss';

import { IProps } from './types';

const YearCard: React.FC<IProps> = ({
  children,
  title,
  onLinkAdvText = '',
  advText = '',
  withArrow = false,
  isBig = false,
  headerClassName = '',
}) => {
  const LinkOrDiv = onLinkAdvText ? Link : 'div';

  return (
    <div className={classnames({ [styles.wrapper]: true, [styles.wrapperBig]: isBig })}>
      <div className={classnames(styles.header, headerClassName)}>
        <div className={styles.info}>
          <div className={styles.title}>{title}</div>
          <LinkOrDiv to={onLinkAdvText} className={styles.adv}>
            {withArrow ? <Icon type="forward" /> : advText}
          </LinkOrDiv>
        </div>
        <div className={styles.divider} />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default YearCard;
