import { getFormat } from 'shared/utils/time';
import { IProfileData } from 'mobx/stores/user/types';
import { LStorage } from 'shared/utils/constants';
import defaultAvatar from 'assets/img/defaultAvatar.png';

import { IRespEmployee } from './types';

export const converterEmpData = (employeeData: IProfileData) => {
  const convertData = { ...employeeData };
  const dateStart = getFormat(convertData.startWorkDate);
  const dateEnd = getFormat(convertData.endWorkDate);
  convertData.startWorkDateText = dateStart;
  convertData.endWorkDateText = dateEnd;
  return convertData;
};

export const converterEmpList = (data: any[]) => data.map((item: any) => ({
  ...item,
  value: item.fullName,
  key: item.id,
}));

export const converterFilterEmpList = (data: IRespEmployee[]) => {
  const dataWithoutCurrentUser = data.filter((item: IRespEmployee) => {
    if ((item.id === localStorage.getItem(LStorage.userId)
      || (item.fullName.includes('values.name')))) {
      return false;
    }
    return true;
  });

  return dataWithoutCurrentUser.map((item: IRespEmployee) => {
    const { isProbation, workingStatus_id: status } = item;

    let isParseProbation = false;

    if (typeof isProbation === 'string') {
      isParseProbation = isProbation.toLowerCase() === 'true';
    } else if (typeof isProbation === 'boolean') {
      isParseProbation = isProbation;
    }

    if (status && status.id && status.id.toLowerCase() === 'probation') {
      isParseProbation = true;
    }

    return {
      id: item.id,
      fullName: item ? item.fullName : '',
      avatar: item.photo_id ? item.photo_id.urlLink : defaultAvatar,
      position: item.position ? item.position.name : '',
      workSince: getFormat(item.startWorkDate),
      phone: item.phone,
      email: item.email,
      department: item.department_id ? item.department_id.name : '',
      supervisor: item.supervisor_id ? item.supervisor_id.fullName : '',
      company: item.company_id && item.company_id.name,
      location: item.location_id ? item.location_id.name : '',
      isProbation: isParseProbation,
      photoLink: item.photo_id && item.photo_id.urlLink,
      workingStatus: item.workingStatus_id && item.workingStatus_id,
    };
  });
};
